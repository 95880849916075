import React, { useEffect, useState, useContext } from "react"
import axios from "axios"
import { hotjar } from "react-hotjar"
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  IdToken,
} from "@auth0/auth0-spa-js"

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

const USER_METADATA_URI = `${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`
const APP_METADATA_URI = `${process.env.REACT_APP_USER_METADATA_URI}/app_metadata`

interface ProviderProps extends Auth0ClientOptions {
  children
  onRedirectCallback?
}

interface AppMetadata {
  "https://appdev.paro.io/app_metadata": {
    applicationStep: number
    progress: number
    userTypeId: number
  }
  "https://app.paro.io/app_metadata": {
    applicationStep: number
    progress: number
    userTypeId: number
  }
}

interface UserMetadata {
  "https://appdev.paro.io/user_metadata": {
    betaUser: boolean
    firstName: string
    lastName: string
    phone: string
    picture: string
    userId: number
    userTypeId: number
  }
  "https://app.paro.io/user_metadata": {
    betaUser: boolean
    firstName: string
    lastName: string
    phone: string
    picture: string
    userId: number
    userTypeId: number
  }
}

export type Auth0User = {
  email_verified?: boolean
  email?: string
  family_name?: string
  given_name?: string
  name?: string
  nickname?: string
  picture?: string
  sub?: string
  updated_at?: string
  userId?: number
  legacyFreelancerId?: number
  betaUser?: boolean
  isExpertOps?: boolean
  auth0UserId?: number
  token?: string
} & Partial<AppMetadata> &
  Partial<UserMetadata>

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Auth0DefaultContext {
  getIdTokenClaims: (...p: any[]) => Promise<IdToken>
  getTokenSilently: (...p: any) => Promise<any>
  getTokenWithPopup: (...p: any) => Promise<string>
  handleRedirectCallback: (url?: string) => Promise<void>
  isAuthenticated?: boolean
  loading: boolean
  loginWithPopup: (params?: Record<string, unknown>) => Promise<void>
  loginWithRedirect: (...p: any[]) => Promise<void>
  logout: (...p: any[]) => void
  popupOpen: boolean
  user: Auth0User
}

/* eslint-disable @typescript-eslint/no-explicit-any */

export const Auth0Context = React.createContext({} as Auth0DefaultContext)
export const useAuth0 = (): Auth0DefaultContext => {
  return useContext(Auth0Context)
}
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: ProviderProps): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
  const [user, setUser] = useState<Auth0User>()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({ ...initOptions })
      setAuth0(auth0FromHook)
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }
      const isAuthenticated = await auth0FromHook.isAuthenticated()
      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        const token = await auth0FromHook
          .getIdTokenClaims()
          .then((claims) => claims.__raw)
        const auth0UserId = user[APP_METADATA_URI].userId
        // TODO for axios, move into ApolloClientProvider for graphQL
        axios.defaults.headers.common["auth"] = token ? `Bearer ${token}` : ""

        setUser({
          ...user,
          auth0UserId,
          token,
          ...user[USER_METADATA_URI],
          ...user[APP_METADATA_URI],
        })

        const { firstName, lastName } = user[USER_METADATA_URI]
        const name = firstName + " " + lastName
        const HJID = Number(2855544)
        const HJSV = Number(6)
        hotjar.initialize(HJID, HJSV)
        const userId = user?.email || null
          ; (window as any).hj("identify", userId, {
            name: name,
            salesRepId: auth0UserId,
          })
      }
      setIsAuthenticated(isAuthenticated)
      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])
  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser({
      ...user,
      ...user[USER_METADATA_URI],
      ...user[APP_METADATA_URI],
    })
    setIsAuthenticated(true)
  }
  const handleRedirectCallback = async (url) => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    const token = await auth0Client
      .getIdTokenClaims()
      .then((claims) => claims.__raw)
    const auth0UserId = user[USER_METADATA_URI].userId
    setLoading(false)
    setUser({
      ...user,
      auth0UserId,
      token,
      ...user[USER_METADATA_URI],
      ...user[APP_METADATA_URI],
    })
    setIsAuthenticated(true)
  }
  return React.createElement(
    Auth0Context.Provider,
    {
      value: {
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) =>
          auth0Client === null || auth0Client === void 0
            ? void 0
            : auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) =>
          auth0Client === null || auth0Client === void 0
            ? void 0
            : auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) =>
          auth0Client === null || auth0Client === void 0
            ? void 0
            : auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) =>
          auth0Client === null || auth0Client === void 0
            ? void 0
            : auth0Client.getTokenWithPopup(...p),
        logout: (...p) =>
          auth0Client === null || auth0Client === void 0
            ? void 0
            : auth0Client.logout({ ...p, returnTo: window.location.origin }),
      },
    },
    children
  )
}
