import React, { useContext, useState } from "react";

import { Box, Grid } from "@material-ui/core";
import { Rating } from "@material-ui/lab";

import { makeStyles } from "@material-ui/core/styles";
import {
  submitRecommendations,
  // toggleJobBoard,
  updateCartCheckout,
} from "../services/apiService";
import { CartContext } from "./CartContext";
import CustomSpinner from "./CustomSpinner";
import FeedbackFormComponent from "./FeedbackFormComponent";
import { Auth0Context } from "../contexts/Auth0Context";
import { CHECKOUT_REASONS, IS_PROD_ENVIRONMENT } from "../data/appConstants";
import { useSnackbar } from "notistack";
import { addToCommunicationHubFn } from "../utils/platformService";

const useStyles = makeStyles((theme) => ({
  textArea: {
    minWidth: "100%",
    padding: theme.spacing(1),
  },
  "icon-1": { color: "#f82c35" },
  "icon-2": { color: "#f4a82e" },
  "icon-3": { color: "#ffd83d" },
  "icon-4": { color: "#8ec63f" },
  "icon-5": { color: "#36a602" },
  selected: {
    backgroundColor: "#000 !important",
    color: "#fff",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function FeedbackForm({
  proposalId,
  closeFeedbackForm,
  cartSubmitted,
  // onJobBoard,
  captureErrors,
}) {
  const defaultRating = 0;
  const { enqueueSnackbar } = useSnackbar();

  const { cartContext, freelancerContext, globalMatchCartContext } =
    useContext(CartContext);
  const [cart, setCart] = cartContext;
  const [, setFreelancers] = freelancerContext;
  const [matchIds, setMatchIds] = globalMatchCartContext;

  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);

  const { user } = useContext(Auth0Context);

  const [rating, setRating] = useState(defaultRating);
  const [hover, setHover] = useState(-1);

  const cartEmpty = cart.length === 0;

  const onSubmitFn = async (data, other = false) => {
    for (let mId of matchIds.filter((m) => !!m)) {
      const flIDList = cart.filter((c) => c.matchId === mId).map((f) => f.flID);
      const requestData = {
        matchId: parseInt(mId),
        proposalId: parseInt(proposalId),
        freelancerId: flIDList,
        reason:
          "" +
          (other
            ? [...data.predefinedReasons, data.otherReason]
            : data.predefinedReasons),
        experienceFitRating: parseInt(rating),
        comments: data.comments,
        ...(!!data.addMore && { notesToMMDesk: data.addMore }),
      };

      console.log("submitRecommendations", "requestData:", requestData);
      await submitRecommendations(requestData);

      if (IS_PROD_ENVIRONMENT) {
        console.log(
          "sending proposal mails",
          "proposalId:",
          proposalId,
          "flIDList:",
          flIDList
        );
        const res = await addToCommunicationHubFn(
          proposalId,
          flIDList,
          false,
          "Matching Application"
        );
        captureErrors(res);
        // await toggleJobBoard(proposalId, onJobBoard);
      } else {
        console.log("Skipping sending Emails to Freelancers - QA env");
        enqueueSnackbar(`Skipping sending Emails to Freelancers - QA env`, {
          variant: "warning",
        });
      }
    }

    await updateCartCheckout(parseInt(proposalId), user.auth0UserId, true);

    setSuccessfullySubmitted(true);
    cartSubmitted(true);
    setCart([]);
    setMatchIds([]);
    setFreelancers([]);
    closeFeedbackForm(true);
  };

  const classes = useStyles();

  return (
    <Box p={2} m={2}>
      <h6 className="m-1 mb-4">
        {cartEmpty
          ? "You have not selected any Freelancer"
          : `You are submitting ${cart.length} freelancers to review proposal`}
      </h6>
      <h4 className="m-1 mb-3">How satisfied were you with our matches?</h4>
      <Grid container>
        <Grid item xs={12} container>
          <Rating
            id="mm-cart-rating"
            style={{ fontSize: "2em" }}
            classes={{
              iconHover: classes[`icon-${hover}`],
            }}
            name="rating"
            value={rating}
            max={5}
            onChange={(_, newValue) => {
              setRating(newValue || 0);
            }}
            onChangeActive={(_, newHover) => {
              setHover(newHover);
            }}
          />
        </Grid>
      </Grid>

      {rating > 0 && (
        <FeedbackFormComponent
          onSubmitFn={onSubmitFn}
          question={"Please select a reason for your low score"}
          options={CHECKOUT_REASONS}
          newReasonPlaceholder="i.e Lack of experience, etc."
          submitButtonText={"Submit"}
          moreButtonText={"Add More"}
          commentsHeading="Briefly explain your experience"
          commentsPlaceholder={
            cartEmpty
              ? "Tell us why no matches were relevant to your proposal?"
              : "Provide match freedback..."
          }
          successfullySubmitted={successfullySubmitted}
          mmFeedbackForm
          submittingText="Adding Freelancers to Communication Hub"
          isFinalSubmitForm={true}
          dependsOnRating={rating >= 3}
        />
      )}

      {successfullySubmitted && (
        <CustomSpinner>
          {cartEmpty ? "Submitting" : "Sending Emails to Freelancers"}
        </CustomSpinner>
      )}
    </Box>
  );
}
