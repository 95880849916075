/* eslint-disable jsx-a11y/anchor-has-content */
import { usePagination } from "react-instantsearch";
import {
  IconChevronLeft,
  IconChevronDoubleLeft,
  IconChevronRight,
  IconChevronDoubleRight,
} from "@paro.io/base-icons";

function PaginationItem({
  page,
  isDisabled,
  href,
  onClick,
  currentRefinement,
  ...props
}) {
  if (isDisabled) {
    return (
      <div className="border py-2 px-4 ">
        <span {...props} />
      </div>
    );
  }

  function isModifierClick(event) {
    const isMiddleClick = event.button === 1;

    return Boolean(
      isMiddleClick ||
        event.altKey ||
        event.ctrlKey ||
        event.metaKey ||
        event.shiftKey
    );
  }

  return (
    <div
      className={`border px-4 py-2  cursor-pointer ${
        currentRefinement === page && "font-bold  bg-gray"
      }`}
      onClick={(event) => {
        if (isModifierClick(event)) {
          return;
        }
        event.preventDefault();
        onClick(event);
      }}
    >
      <a href={href} {...props} className="text-black no-underline" />
    </div>
  );
}

function FreelancerPagination(props) {
  const {
    pages,
    currentRefinement,
    nbPages,
    isFirstPage,
    isLastPage,
    refine,
    createURL,
  } = usePagination(props);
  const firstPageIndex = 0;
  const previousPageIndex = currentRefinement - 1;
  const nextPageIndex = currentRefinement + 1;
  const lastPageIndex = nbPages - 1;

  return (
    <div className="flex items-center my-2">
      {!isFirstPage && (
        <PaginationItem
          isDisabled={isFirstPage}
          href={createURL(firstPageIndex)}
          onClick={() => refine(firstPageIndex)}
        >
          <div className="flex">
            <IconChevronDoubleLeft />
          </div>
        </PaginationItem>
      )}
      {!isFirstPage && (
        <PaginationItem
          isDisabled={isFirstPage}
          href={createURL(previousPageIndex)}
          onClick={() => refine(previousPageIndex)}
        >
          <IconChevronLeft />
        </PaginationItem>
      )}
      {pages.map((page) => {
        const label = page + 1;

        return (
          <PaginationItem
            key={page}
            page={page}
            isDisabled={false}
            aria-label={`Page ${label}`}
            href={createURL(page)}
            onClick={() => refine(page)}
            currentRefinement={currentRefinement}
          >
            {label}
          </PaginationItem>
        );
      })}
      {!isLastPage && (
        <PaginationItem
          isDisabled={isLastPage}
          href={createURL(nextPageIndex)}
          onClick={() => refine(nextPageIndex)}
        >
          <IconChevronRight />
        </PaginationItem>
      )}
      {!isLastPage && (
        <PaginationItem
          isDisabled={isLastPage}
          href={createURL(lastPageIndex)}
          onClick={() => refine(lastPageIndex)}
        >
          <IconChevronDoubleRight />
        </PaginationItem>
      )}
      <div className="ml-2">
        Page {currentRefinement + 1} of {nbPages}
      </div>
    </div>
  );
}

export default FreelancerPagination;
