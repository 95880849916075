import React from 'react'
import { makeStyles, TableContainer, TablePagination } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import currency from 'currency.js'
import dayjs from 'dayjs'
import Brightness1 from '@material-ui/icons/Brightness1';
import Box from '@material-ui/core/Box'
import { Grid } from '@material-ui/core'
import { ProfileInformation } from '../../../styles/ProfileInformation'
import { StyledIcon } from '../../../styles/SpanIcon'
import proposedProjectStages from '../../../shared/constants/proposedProjectStages'


type Props = {
  legacyFreelancerId: number | undefined
  availabilityProjects?: any
  winRate: number
  proposals: any
  proposalEstimatesMap: any
  nextMonthProposalEstimatesMap: any
  isTotalHoursOn: boolean
}
const useStyles = makeStyles({
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '275px',
    border: '1px solid #E2E8F0'
  },
  tableHead: {
    maxWidth: 125,
    minWidth: 125,
    padding: 0,
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    textAlign: 'center'
  },
  tableRow: {
    height: 40
  },
  container: {
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 50,
    padding: 10,
    margin: 10
  },
  tableCellBorder: {
    whiteSpace: 'nowrap',
    border: '1px solid #E2E8F0'
  },
  shade: {
    backgroundColor: '#F1F5F9'
  },
  pagination: {
    display:'flex',
    justifyContent: 'left',
    width: "100%",
    alignItems: 'left',
    padding:'0px',
    },
})

export const ProposalsTable = (props: Props) => {
  const { winRate, proposals, proposalEstimatesMap, nextMonthProposalEstimatesMap, isTotalHoursOn } = props
  const classes = useStyles()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }
  if (proposals.length <= 0) {
    return <div>No proposals available</div>
  }

    const pendingProposalData: any[] = proposals.map((proposal) => {
      return {
        proposalId: proposal.proposal?.id,
        proposalName: proposal?.proposal?.name,
        clientName: proposal?.proposal?.client?.name,
        billType: proposal.proposal?.billRateType,
        payRate: proposal.freelancerRate,
        duration: (proposalEstimatesMap[proposal.proposal.id] || proposalEstimatesMap[proposal.proposal.id] === 0) ? (isTotalHoursOn ? (proposalEstimatesMap[proposal.proposal.id]).toFixed(0) : proposalEstimatesMap[proposal.proposal.id]) : '-',
        nextMonthDuration: (nextMonthProposalEstimatesMap[proposal.proposal.id] || nextMonthProposalEstimatesMap[proposal.proposal.id] === 0) ? nextMonthProposalEstimatesMap[proposal.proposal.id] : '-',
        startDate: proposal.proposal?.estimatedStartDate,
        endDate: proposal.proposal?.estimatedEndDate,
        ae: proposal?.proposal?.salesRep?.name,
        am: proposal?.proposal?.accountManager?.name,
        status: proposal.proposedProjectStage
      }
    })
    const preStageCount = pendingProposalData.reduce((acc, proposal) => proposedProjectStages[proposal.status] < 4 ? acc + 1 : acc, 0)
    const postStageCount = pendingProposalData.reduce((acc, proposal) => proposedProjectStages[proposal.status] >= 4 ? acc + 1 : acc, 0)

    return (
      <>
      <Paper className={classes.container}>
        <TableContainer>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.tableRow}>
                <TableCell className={[classes.tableCellBorder, classes.shade].join(" ")} colSpan={12}>
                  <b>Proposals</b>
                </TableCell>
              </TableRow>
              <TableRow className={[classes.tableCellBorder, classes.shade].join(" ")}>
              <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}>
                  <b>Project Name</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <b>Client Name</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <b>Bill Type</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <b>Rate</b>
                </TableCell >
                <TableCell className={classes.tableCell} align="right">
                  <b>{isTotalHoursOn ? 'Total Monthly Estimated Hours' : 'Monthly Estimated Hours Remaining'}</b>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <b>Next Month Hours</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <b>Start Date</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <b>End Date</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <b>Pitch Status</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <b>AE</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <b>AM</b>
                </TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingProposalData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                const {
                  proposalName,
                  clientName,
                  billType,
                  payRate,
                  duration,
                  nextMonthDuration,
                  startDate,
                  endDate,
                  ae,
                  am,
                  status
                } = row
                const billTypeLabel = billType.charAt(0).toUpperCase() + billType.slice(1).toLowerCase();
                return (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>{page*rowsPerPage + index+1}</TableCell>
                    <TableCell className={classes.tableCell}>{proposalName}</TableCell>
                    <TableCell className={classes.tableCell}>{clientName}</TableCell>
                    <TableCell className={classes.tableCell}>{billTypeLabel}</TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {payRate ? currency(payRate).format() : '-'}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {duration}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {nextMonthDuration}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{startDate ? dayjs(startDate).format('MM-DD-YYYY') : '-'}</TableCell>
                    <TableCell className={classes.tableCell}>{endDate ? dayjs(endDate).format('MM-DD-YYYY') : '-'}</TableCell>
                    <TableCell className={classes.tableCell} style={{backgroundColor: ((proposedProjectStages[status] < 4) ? "#FB9A1D" : "#66d4ba")}}>{(proposedProjectStages[status] < 4) ? "Pre-SOW" : "SOW In-Process"}</TableCell>
                    <TableCell className={classes.tableCell}>{ae || '-'}</TableCell>
                    <TableCell className={classes.tableCell}>{am || '-'}</TableCell>
                    {/* <TableCell className={classes.tableCell}>
                      <ProjectMenu projectId={proposalId} />
                    </TableCell> */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box pt={4}>
        <Grid container md={12} direction="row"  alignItems='stretch' justify="space-between">
          <Grid md={6} xs={12}>
            <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={pendingProposalData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
          <Box pr={2}>
            <ProfileInformation>
              <Grid item style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                <StyledIcon >
                  <Brightness1 fontSize={'small'} htmlColor = {"#FB9A1D"} />
                </StyledIcon>
                <Grid item style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                  <b style={{fontSize: 14, fontWeight: "bold",  color: "#BABAC2"}}>Pre-SOW:</b>
                  <b style={{fontSize: 14, fontWeight: "bold",  color: "#BABAC2"}}>{preStageCount} of {pendingProposalData.length}</b>
                </Grid>
              </Grid>
              <span>&nbsp; &nbsp;</span>
              <Grid item style={{ display: "flex", justifyContent: "space-around", flexDirection: "row" }}>
                <StyledIcon>
                  <Brightness1 fontSize={'small'} htmlColor = {"#66d4ba"} />
                </StyledIcon>
                <Grid item style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
                  <b style={{fontSize: 14, fontWeight: "bold",  color: "#BABAC2"}}>SOW In-Process:</b>
                  <b style={{fontSize: 14, fontWeight: "bold",  color: "#BABAC2"}}>{postStageCount} of {pendingProposalData.length}</b>
                </Grid>
              </Grid>
              <span>&nbsp; &nbsp;</span>
              <Grid item style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                <b style={{fontSize: 14, fontWeight: "bold"}}>win-rate:<span>&nbsp; &nbsp;</span>{+(winRate*100).toFixed(2)}%</b>
                <b style={{fontSize: 14, fontWeight: "bold"}}>{+(winRate*100).toFixed(2)}/100</b>
              </Grid>
            </ProfileInformation>
          </Box>
        </Grid>
        </Box>
      </Paper>
      </>
    )
}
