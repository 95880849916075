import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Button, Input, Text } from "@paro.io/base-ui";
import {
  Configure,
  useClearRefinements,
  useHits,
  useInstantSearch,
  usePagination,
  useRange,
  useRefinementList,
} from "react-instantsearch";
import Select, { components } from "react-select";
import { getMinMaxProjectSize } from "../../data/appConstants";
import { MatchResultsContext } from "../../contexts/MatchResultsContext";
import { searchInitialData } from "../../utils/freelancerSearchService";
import { useDebounce } from "../hooks/useDebounce";
import AWS from "aws-sdk";
import { milesToMetres } from "../../shared/utils";
import { blacklistFls } from "./constants";
import { StarBorder } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";

AWS.config.update({
  region: "us-east-1",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:37d4aba5-ba64-47b2-93f4-19fc78305319",
  }),
});

const location = new AWS.Location();

const keys = [
  "serviceLine",
  "minHours",
  "maxHours",
  "minRate",
  "maxRate",
  "industries",
  "softwares",
  "skills",
  "certificate",
  "language",
  "teams",
  "serviceLinesPlusTags",
  "activeStatus",
];
const replacements = {
  // serviceLine: "serviceLine",
  softwares: "softwareTags",
  industries: "industryTags",
  skills: "financialTags",
  certificate: "certificates",
  language: "languages",
  serviceLinesPlusTags: "servicelinePlus",
  //teams: "haveATeam",
  activeStatus: "applicationStatus",
};

const distanceOptions = [
  {
    label: "within 10 miles",
    value: 10,
  },
  {
    label: "within 50 miles",
    value: 50,
  },
  {
    label: "within 100 miles",
    value: 100,
  },
  {
    label: "within 200 miles",
    value: 200,
  },
  {
    label: "within 300 miles",
    value: 300,
  },
  {
    label: "within 500 miles",
    value: 500,
  },
  {
    label: "within 800 miles",
    value: 800,
  },
  {
    label: "within 1000 miles",
    value: 1000,
  },
];
// for react select
const colourStyles = (errors) => ({
  control: (styles) => ({
    ...styles,
    backgroundColor: "#f1f5f9",
    border: errors ? "1px solid #f17175 !important" : "1px solid #ddd",
    ...(errors && { boxShadow: "none" }),
  }),
  multiValue: (styles) => {
    const color = "#fff";
    return {
      ...styles,
      backgroundColor: color,
      color: "black",
      border: "1px solid #ddd",
      borderRadius: "14px",
      textWrap: "wrap",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "black",
    borderRadius: "16px",
    textWrap: "wrap",
  }),
});

const initialState = ["applicationStatus:Active"];
const isTeamsEnabled = process.env.REACT_APP_MATCH_TEAMS_ENABLED === "true";
const isRatingsEnabled = process.env.REACT_APP_RATINGS_ENABLED === "true";

const Sidebar = ({ onMatch, isMatchLoading, proposalDetails }) => {
  const { hits } = useHits();
  const { currentRefinement, refine: refinePage } = usePagination();
  const { status } = useInstantSearch();
  const [currentPage, setCurrentPage] = useState(
    proposalDetails?.currentPage ?? 0
  );
  const [algoliaSearchStatus, setAlgoliaSearchStatus] = useState(null);
  const [proposal, setProposal] = useState({
    ...proposalDetails,
    minHours: proposalDetails.minHours
      ? proposalDetails.minHours
      : getMinMaxProjectSize(proposalDetails.projectSize)?.minHours,
    maxHours: proposalDetails.maxHours ? proposalDetails.maxHours : "",
  });
  const [searchQuery, setSearchQuery] = useState(
    proposalDetails?.searchQuery ?? ""
  );
  const [query, setQuery] = useState(proposalDetails?.searchQuery ?? "");
  const [errors, setErrors] = useState({});
  const [algoliaFilterQuery, setAlgoliaFilterQuery] = useState(initialState);
  const [, setMatchQuery] = useContext(MatchResultsContext)?.matchQueryContext;
  const [filters, setFilters] = useState("");
  const [locationRange, setLocationRange] = useState(
    proposalDetails?.locationRangeOption
      ? milesToMetres(proposalDetails?.locationRangeOption?.value)
      : milesToMetres(distanceOptions[1].value)
  );
  const [placeLookup, setPlaceLookup] = useState(
    proposalDetails?.placeLookup ?? ""
  );
  const [geocoded, setGeocoded] = useState("");
  const [selectedPlaceSuggestion, setSelectedPlaceSuggestion] = useState(
    proposalDetails?.selectedPlaceSuggestion ?? ""
  );
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const [serviceLineChange, setServiceLineChange] = useState(false);
  const [minOnboardState, setMinOnboardState] = useState(null);
  const [maxOnboardState, setMaxOnboardState] = useState(null);
  const [teamsChange, setTeamsChange] = useState(false);

  const debounceSearch = useDebounce();
  const debounceLocation = useDebounce();
  const debounceProposals = useDebounce();

  const locationInputRef = useRef(
    proposalDetails?.locationRangeOption ?? distanceOptions[1]
  );

  const ratingsRef = useRef(proposalDetails?.overallRatingAvg ?? 0);

  const { range: hourlyRateRange } = useRange({
    attribute: "hourlyRate",
  });
  const { range: availableHoursRange } = useRange({
    attribute: "availableHours",
  });

  const {
    items: serviceLineItems,
    refine: refineServiceLine,
    searchForItems: searchServiceLine,
  } = useRefinementList({
    attribute: "serviceLine",
  });
  const { refine: clearRefinements } = useClearRefinements();
  const { items: servicelinePlusItems, searchForItems: servicelinePlusSearch } =
    useRefinementList({
      attribute: "servicelinePlus",
      limit: 50,
    });
  const { items: industryTagsItems, searchForItems: industryTagsSearch } =
    useRefinementList({
      attribute: "industryTags",
      limit: 500,
    });
  const { items: softwareTagsItems, searchForItems: softwareTagsSearch } =
    useRefinementList({
      attribute: "softwareTags",
      limit: 500,
    });
  const { items: financialTagsItems, searchForItems: searchForFinTags } =
    useRefinementList({
      attribute: "financialTags",
      limit: 500,
    });
  const { items: certificates, searchForItems: searchForCertificates } =
    useRefinementList({
      attribute: "certificates",
      limit: 500,
    });
  const { items: languages, searchForItems: searchForLanguages } =
    useRefinementList({
      attribute: "languages",
      limit: 500,
    });
  const { items: applicationStatus, searchForItems: searchForStatus } =
    useRefinementList({
      attribute: "applicationStatus",
    });
  const {
    items: teams,
    refine: refineTeams,
    searchForItems: searchForTeams,
  } = useRefinementList({
    attribute: "haveATeam",
  });
  const {
    range: { min: minOnboardedDate, max: maxOnboardedDate },
    refine,
  } = useRange({ attribute: "currentProfileEnteredStageTimestamp" });

  const proposalFreelancers = proposalDetails?.proposalFreelancers
    ?.filter((fl) => fl)
    .map((fl) => fl.freelancerId);

  const { min: minHour, max: maxHour } = hourlyRateRange;
  const { min: minAvailHour, max: maxAvailHour } = availableHoursRange;

  function createAlgoliaFacetFilters(data) {
    let mustFilters = [];
    let shouldFilters = [];
    data.forEach((item) => {
      let { field, value, inputValue, required } = item;
      field = replacements[field];

      const filterStr = field ? `${field}:${value ?? inputValue}` : "";

      if (required) {
        mustFilters.push(filterStr); // and
      } else {
        shouldFilters.push(filterStr); // or
      }
    });

    let all = [];
    if (mustFilters.length > 0) {
      all = [...all, ...mustFilters];
    }
    if (shouldFilters.length > 0) {
      all.push(shouldFilters);
    }

    return all.sort((a, b) => a.length - b.length);
  }

  useEffect(() => {
    if (!filters) {
      setProposal({
        ...proposal,
        maxHours: maxAvailHour,
        maxRate: maxHour,
        minHours: minAvailHour,
        minRate: minHour,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourlyRateRange, availableHoursRange]);

  useEffect(() => {
    const getGeocode = async () => {
      if (selectedPlaceSuggestion) {
        const geo = await geocodeAddress(selectedPlaceSuggestion.value);
        if (geo) {
          refinePage(0);
          setGeocoded(`${geo[1]},${geo[0]}`);
        }
      }
    };

    getGeocode();

    // eslint-disable-next-line
  }, [selectedPlaceSuggestion]);

  useEffect(() => {
    // Get recommendation on page change
    if (
      currentRefinement !== currentPage &&
      hits.length &&
      (algoliaSearchStatus === "loaded" || algoliaSearchStatus === "idle")
    ) {
      setCurrentPage(currentRefinement);
      setAlgoliaSearchStatus("");
      onGetRecommendations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefinement, algoliaSearchStatus]);

  useEffect(() => {
    setAlgoliaSearchStatus((curr) =>
      curr === "stalled" && status === "idle" ? "loaded" : status
    );
  }, [status]);

  useEffect(() => {
    debounceSearch(() => {
      if (
        algoliaSearchStatus === "loaded" ||
        (serviceLineChange && proposal.serviceLine?.length) ||
        (teamsChange && proposal.teams?.length)
      ) {
        setAlgoliaSearchStatus("");
        onGetRecommendations();
        setServiceLineChange(false);
        setTeamsChange(false);
      }
      setMatchQuery({
        ...proposal,
        serviceLine: Array.isArray(proposal.serviceLine)
          ? proposal.serviceLine?.map((i) => i.value).join(",")
          : proposal.serviceLine,
        currentHitsLength: hits.length,
        projectSize: `${proposal?.minHours}-${proposal?.maxHours}hrs`,
        searchQuery: searchQuery,
        currentPage: currentPage,
        placeLookup,
        locationRangeOption: locationInputRef.current.state.value,
        selectedPlaceSuggestion,
      });
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algoliaSearchStatus, serviceLineChange, teamsChange]);

  const filterProposals = (proposalData) => {
    const defaultValues = {};
    if (proposalData) {
      Object.entries(proposalData).forEach(([key, value]) => {
        if (keys.includes(key)) {
          defaultValues[key] = value;
        }
      });
    }
    const { minHours, maxHours, minRate, maxRate } = defaultValues;

    if (defaultValues) {
      const dValues = Object.values(defaultValues);
      const matchingOptions = structuredClone(proposalData?.matchmakingOptions);
      matchingOptions.tags = dValues
        .flat()
        .filter(
          (i) =>
            typeof i !== "string" && typeof i !== "number" && i !== undefined
        );
      matchingOptions.rateRange = {
        min: Number(minRate),
        max: Number(maxRate),
      };
      matchingOptions.availabilityRange = {
        min: Number(minHours),
        max: Number(maxHours),
      };
      matchingOptions.tags = matchingOptions.tags?.filter(
        (item) => item?.value !== "All" && item?.value !== "None"
      );
      setProposal({ ...proposalData, matchmakingOptions: matchingOptions });

      const query = createAlgoliaFacetFilters(matchingOptions.tags);

      let str = [];
      // Add range filters
      str.push(`hourlyRate:${Number(minRate)} TO ${Number(maxRate)}`);
      if (minHours) {
        str.push(`availableHours >= ${Number(minHours)}`);
      }
      if (maxHours) {
        str.push(`availableHours <= ${Number(maxHours)}`);
      }

      if (isRatingsEnabled) {
        str.push(`overallRatingAvg >= ${ratingsRef.current}`);
      }

      setFilters(str?.length ? str.join(" AND ") : "");
      setAlgoliaFilterQuery(query);
    }
  };
  useEffect(() => {
    if (Object.keys(proposal).length) {
      filterProposals({
        ...proposal,
        activeStatus: [
          {
            isRefined: false,
            value: "Active",
            label: "Active",
            highlighted: "Active",
            field: "activeStatus",
            required: true,
          },
        ],
        serviceLine: proposal?.serviceLineArray
          ? proposal?.serviceLineArray
          : proposal?.serviceLine?.length
          ? proposal?.serviceLine?.split(",")?.map((i) => {
              return {
                label: i,
                value: i,
                required: true,
                field: "serviceLine",
              };
            })
          : [],
        overallRatingAvg: ratingsRef.current,
      });
      if (proposal?.serviceLineArray?.length > 1) {
        proposal?.serviceLineArray?.forEach((i) => {
          refineServiceLine(i.value);
        });
      } else {
        // filter serviceLine first time
        if (proposal.serviceLine) {
          refineServiceLine(proposal.serviceLine);
        }

        // check current page
        if (
          proposal?.currentPage !== undefined &&
          proposal?.currentPage !== currentRefinement
        ) {
          refinePage(proposal?.currentPage);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value, name) => {
    let updatedProposal = { ...proposal, [name]: value };
    if (name === "serviceLine") {
      setServiceLineChange(true);
      updatedProposal = {
        ...updatedProposal,
        serviceLineArray: updatedProposal.serviceLine,
      };
    }
    if (name === "teams") {
      setTeamsChange(true);
    }
    setProposal({
      ...updatedProposal,
    });
    setMatchQuery({
      ...updatedProposal,
      serviceLine: Array.isArray(updatedProposal.serviceLine)
        ? updatedProposal.serviceLine?.map((i) => i.value).join(",")
        : updatedProposal.serviceLine,
      currentHitsLength: hits.length,
      projectSize: `${updatedProposal?.minHours}-${updatedProposal?.maxHours}hrs`,
      searchQuery: searchQuery,
      currentPage: currentPage,
      placeLookup,
      locationRangeOption: locationInputRef.current.state.value,
      selectedPlaceSuggestion: selectedPlaceSuggestion,
    });
    debounceProposals(() => {
      filterProposals(updatedProposal);
    }, 500);
    if (currentRefinement !== 0) {
      refinePage(0);
    }
  };

  const geocodeAddress = async (address) => {
    if (!address) {
      return;
    }
    try {
      const response = await location
        .searchPlaceIndexForText({
          IndexName: "geocoding-service",
          Text: address,
        })
        .promise();

      if (response.Results.length > 0) {
        const coordinates = response.Results[0].Place.Geometry.Point;
        return coordinates; // [longitude, latitude]
      }
    } catch (error) {
      console.log(error);
    }
  };

  const suggestLocations = async (address) => {
    const params = {
      IndexName: "geocoding-service",
      Text: address,
      MaxResults: 5,
      FilterCountries: ["USA"],
      FilterCategories: ["RegionType", "MunicipalityType", "PostalCodeType"],
    };

    const response = await location
      .searchPlaceIndexForSuggestions(params)
      .promise();

    if (response.Results.length > 0) {
      return response.Results.map((r) => ({
        label: r.Text,
        value: r.Text,
      }));
    }

    return [];
  };

  const handleChangePlace = (selected, options) => {
    if (options?.action === "clear") {
      setSelectedPlaceSuggestion(null);
      setPlaceLookup("");
      setGeocoded("");
    } else {
      setPlaceLookup(selected ? selected.label : placeLookup);
      setSelectedPlaceSuggestion(selected);
    }
  };

  const handleInputChangeValue = (newValue, { action }) => {
    if (action === "input-change") {
      setPlaceLookup(newValue);
      loadLocations(newValue);
    }
  };
  const loadLocations = async (place) => {
    if (!place || place.length < 2) {
      return [];
    }
    setSuggestionsLoading(true);
    debounceLocation(async () => {
      const locations = await suggestLocations(place);
      if (locations) {
        setPlaceSuggestions(locations);
      }
      setSuggestionsLoading(false);
    }, 300);
  };

  const MultiValueLabel = (props) => {
    const [tag, setTag] = useState(props);
    const onClickTag = () => {
      setTag({
        ...tag,
        data: {
          ...tag.data,
          required: !tag?.data?.required,
        },
      });
      const updatedFiled =
        Array.isArray(proposal[tag?.data?.field]) &&
        proposal[tag?.data?.field]?.map((i) => {
          if (i.value === tag?.data?.value) {
            i.required = !i.required;
          }
          return i;
        });
      if (tag?.data?.field) {
        onChange(updatedFiled, tag?.data?.field);
      }
    };
    return (
      <div
        onClick={onClickTag}
        className={`${tag?.data?.required ? "font-bold" : "opacity-50"}`}
      >
        <components.MultiValueLabel {...tag} />
      </div>
    );
  };

  const onResetSearch = () => {
    const resetState = {
      ...proposal,
      ...searchInitialData,
      activeStatus: [
        {
          isRefined: false,
          value: "Active",
          label: "Active",
          highlighted: "Active",
          field: "activeStatus",
          required: true,
        },
      ],
    };
    ratingsRef.current = 0;
    setAlgoliaFilterQuery(initialState);
    clearRefinements();
    setProposal(resetState);
    setFilters("");
    setPlaceLookup("");
    setGeocoded("");
    setSearchQuery("");
    setQuery("");
    setSelectedPlaceSuggestion(null);
    locationInputRef.current.state.value = distanceOptions[1];
    setLocationRange(milesToMetres(distanceOptions[1].value));
    setMinOnboardState(null);
    setMaxOnboardState(null);
  };

  const validateFields = (inputs) => {
    const errs = {};

    if (
      inputs.minHours &&
      inputs.maxHours &&
      Number(inputs.minHours) > Number(inputs.maxHours)
    ) {
      errs.minHours = "Min hours must be less than max hours";
    }

    if (!String(inputs.minRate)) {
      errs.minRate = "Please enter valid min rate.";
    } else if (Number(inputs.minRate) > Number(inputs.maxRate)) {
      errs.minRate = "Min rate must be lesser than max rate";
    }
    if (!String(inputs.maxRate)) {
      errs.maxRate = "Please enter valid max rate.";
    }
    return errs;
  };

  const onGetRecommendations = () => {
    const validations = validateFields(proposal);
    if (Object.values(validations).length) {
      setErrors(validations);
      return;
    } else {
      setErrors({});
    }

    const filteredHits = hits.filter(
      (fl) =>
        !proposalFreelancers?.includes(fl.freelancerId) &&
        fl.freelancerStatusId === 1 &&
        !fl.suspensionStatus &&
        !fl.needsParoFoundationTraining &&
        !blacklistFls.includes(fl.freelancerId)
    );

    if (!filteredHits?.length) {
      return;
    }

    onMatch({
      ...proposal,
      softwares: proposal?.softwares?.filter(
        (item) => item?.value !== "All" && item?.value !== "None"
      ),
      industries: proposal?.industries?.filter(
        (item) => item?.value !== "All" && item?.value !== "None"
      ),
      serviceLine: Array.isArray(proposal.serviceLine)
        ? proposal.serviceLine?.map((i) => i.value).join(",")
        : proposal.serviceLine,
      projectSize: `>${Number(proposal?.minHours)}hrs`,
      algoliaFreelancers: filteredHits?.map((fl) => ({
        freelancerId: fl.freelancerId,
      })),
    });
  };

  // Update select input values count
  const updateLabels = (options, values, field) => {
    return values?.map((value) => {
      const isExist = options?.find((option) => option.value === value.value);
      if (isExist) {
        value.count = isExist.count;
        value.label = `${isExist?.label} (${isExist?.count})`;
      }
      value.isFixed = true;
      value.required = value?.required ?? true;
      value.field = field;
      value.label = value?.label ?? value?.value;
      return value;
    });
  };

  return (
    <Grid item xs={12} md={3}>
      <Configure
        filters={filters}
        facetFilters={algoliaFilterQuery}
        query={searchQuery}
        optionalWords={searchQuery}
        aroundLatLng={geocoded}
        aroundRadius={locationRange}
        getRankingInfo={true}
      />
      <div
        className={`border rounded-md bg-white p-3 `}
        style={{
          maxHeight: "79vh",
          overflowX: "hidden",
        }}
      >
        <div className="flex justify-between items-center">
          <h4 className="font-bold text-lg">Search</h4>
        </div>
        <div className="mt-3">
          <Text>Status</Text>
          <Select
            options={applicationStatus.map((item) => ({
              ...item,
              label: `${item.label} (${item.count})`,
              field: "activeStatus",
              required: true,
            }))}
            isClearable={false}
            placeholder="Status"
            isMulti
            onInputChange={(e) => searchForStatus(e)}
            value={updateLabels(
              Array.isArray(applicationStatus) ? applicationStatus : [],
              Array.isArray(proposal?.activeStatus)
                ? proposal?.activeStatus
                : [],
              "activeStatus"
            )}
            closeMenuOnSelect={false}
            styles={colourStyles()}
            onChange={(e, type) => {
              onChange(e, "activeStatus");
            }}
          />
        </div>
        <Text className="mt-4">Client Rate $</Text>
        <div className="flex gap-4 w-full">
          <div className="w-full">
            <Input
              label="Min $"
              type="number"
              value={proposal.minRate}
              maxLength={50}
              placeholder={minHour}
              onChange={(e) => {
                onChange(e.target.value ?? "", "minRate");
                if (String(e.target.value)) {
                  setErrors({ ...errors, minRate: "" });
                } else {
                  setErrors({ ...errors, minRate: "Min rate is required" });
                }
              }}
              disabled={false}
              max={maxHour}
              min={minHour}
              isInvalidText={errors?.minRate}
              isInvalid={errors?.minRate}
            />
          </div>
          <div className="w-full">
            <Input
              label="Max $"
              type="number"
              value={proposal.maxRate}
              maxLength={50}
              onChange={(e) => {
                onChange(e.target.value ?? "", "maxRate");
                if (String(e.target.value)) {
                  setErrors({ ...errors, maxRate: "" });
                } else {
                  setErrors({ ...errors, maxRate: "Max rate is required" });
                }
              }}
              disabled={false}
              placeholder={maxHour}
              max={maxHour}
              min={minHour}
              isInvalidText={errors?.maxRate}
              isInvalid={errors?.maxRate}
              isRequired={true}
            />
          </div>
        </div>
        <Text className="mt-3">Effort</Text>
        <div className="flex gap-4 w-full">
          <div className="w-full">
            <Input
              label="Min hrs."
              type="number"
              value={proposal.minHours}
              maxLength={50}
              onChange={(e) => {
                onChange(e.target.value ?? "", "minHours");
                if (String(e.target.value)) {
                  setErrors({ ...errors, minHours: "" });
                } else {
                  setErrors({ ...errors, minHours: "Min hours is required" });
                }
              }}
              disabled={false}
              placeholder={minAvailHour}
              max={maxAvailHour}
              min={minAvailHour}
              isInvalidText={errors?.minHours}
              isInvalid={errors?.minHours}
              isRequired={true}
            />
          </div>
          <div className="w-full">
            <Input
              label="Max hrs."
              type="number"
              value={proposal.maxHours}
              maxLength={50}
              onChange={(e) => {
                onChange(e.target.value ?? "", "maxHours");
                if (String(e.target.value)) {
                  setErrors({ ...errors, maxHours: "" });
                }
              }}
              disabled={false}
              placeholder={maxAvailHour}
              max={maxAvailHour}
              min={minAvailHour}
              isInvalidText={errors?.maxHours}
              isInvalid={errors?.maxHours}
            />
          </div>
        </div>
        <div className="w-full mt-3">
          <Text>Keyword Search</Text>
          <Input
            label=""
            type="text"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              debounceSearch(() => {
                setSearchQuery(e.target.value);
                refinePage(0); // Go to page first page on search
              }, 3000);
            }}
            disabled={false}
            placeholder={"Search"}
          />
        </div>
        <div className="mt-3">
          <Text>Service</Text>
          <Select
            options={serviceLineItems.map((item) => ({
              ...item,
              label: `${item.label} (${item.count})`,
              required: true,
              field: "serviceLine",
            }))}
            isMulti
            placeholder="Search"
            value={updateLabels(
              Array.isArray(serviceLineItems) ? serviceLineItems : [],
              Array.isArray(proposal?.serviceLine) ? proposal?.serviceLine : [],
              "serviceLine"
            )}
            closeMenuOnSelect={false}
            styles={colourStyles(!!errors?.serviceLine)}
            onInputChange={(e) => searchServiceLine(e)}
            isClearable={false}
            onChange={(e, type) => {
              onChange(e, "serviceLine");
              if (
                type.action === "remove-value" ||
                type.action === "pop-value"
              ) {
                refineServiceLine(type?.removedValue?.value ?? "");
              } else {
                refineServiceLine(type?.option?.value ?? "");
              }
            }}
          />
          {errors?.serviceLine && (
            <span className="text-xs text-danger-dark">
              {errors?.serviceLine}
            </span>
          )}
        </div>
        <div className="mt-3">
          <Text>SL+/Solution</Text>
          <Select
            options={servicelinePlusItems.map((item) => ({
              ...item,
              label: `${item.label} (${item.count})`,
              required: true,
              field: "serviceLinesPlusTags",
            }))}
            isMulti
            placeholder="Search"
            closeMenuOnSelect={false}
            styles={colourStyles()}
            onInputChange={(e) => servicelinePlusSearch(e)}
            isClearable={false}
            value={updateLabels(
              servicelinePlusItems,
              proposal?.serviceLinesPlusTags,
              "serviceLinesPlusTags"
            )}
            components={{ MultiValueLabel }}
            onChange={(e, type) => {
              onChange(e, "serviceLinesPlusTags");
            }}
          />
        </div>
        <div className="mt-3">
          <Text>Industry</Text>
          <Select
            options={industryTagsItems.map((item) => ({
              ...item,
              label: `${item.label} (${item.count})`,
              required: true,
              field: "industries",
            }))}
            isMulti
            closeMenuOnSelect={false}
            styles={colourStyles(!!errors?.industries)}
            isClearable={false}
            value={updateLabels(
              industryTagsItems,
              proposal?.industries,
              "industries"
            ).filter((i) => i?.value !== "All" && i?.value !== "None")}
            components={{ MultiValueLabel }}
            placeholder="Search"
            onInputChange={(e) => industryTagsSearch(e)}
            onChange={(e, type) => {
              onChange(e, "industries");
            }}
          />
        </div>
        <div className="mt-3">
          <Text>Software</Text>
          <Select
            options={softwareTagsItems.map((item) => ({
              ...item,
              label: `${item.label} (${item.count})`,
              field: "softwares",
              required: true,
            }))}
            isMulti
            value={updateLabels(
              softwareTagsItems,
              proposal?.softwares,
              "softwares"
            ).filter((i) => i?.value !== "All" && i?.value !== "None")}
            components={{ MultiValueLabel }}
            isClearable={false}
            closeMenuOnSelect={false}
            onInputChange={(e) => softwareTagsSearch(e)}
            styles={colourStyles(!!errors?.softwares)}
            placeholder="Search"
            onChange={(e, type) => {
              onChange(e, "softwares");
            }}
          />
        </div>
        <div className="mt-3">
          <Text>Skills</Text>
          <Select
            options={financialTagsItems.map((item) => ({
              ...item,
              label: `${item.label} (${item.count})`,
              field: "skills",
              required: true,
            }))}
            isMulti
            closeMenuOnSelect={false}
            styles={colourStyles()}
            placeholder="Search"
            value={updateLabels(financialTagsItems, proposal?.skills, "skills")}
            components={{ MultiValueLabel }}
            onInputChange={(e) => searchForFinTags(e)}
            onChange={(e, type) => {
              onChange(e, "skills");
            }}
          />
        </div>
        <div className="mt-3">
          <Text>Languages</Text>
          <Select
            options={languages.map((item) => ({
              ...item,
              label: `${item.label} (${item.count})`,
              field: "language",
              required: true,
            }))}
            isMulti
            isClearable={false}
            placeholder="Search"
            value={updateLabels(languages, proposal?.language, "language")}
            components={{ MultiValueLabel }}
            closeMenuOnSelect={false}
            styles={colourStyles()}
            onInputChange={(e) => searchForLanguages(e)}
            onChange={(e, type) => {
              onChange(e, "language");
            }}
          />
        </div>
        <div className="mt-3">
          <Text>Certificates</Text>
          <Select
            options={certificates.map((item) => ({
              ...item,
              label: `${item.label} (${item.count})`,
              field: "certificate",
              required: true,
            }))}
            isClearable={false}
            placeholder="Search"
            isMulti
            value={updateLabels(
              certificates,
              proposal?.certificate,
              "certificate"
            )}
            components={{ MultiValueLabel }}
            closeMenuOnSelect={false}
            styles={colourStyles()}
            onInputChange={(e) => searchForCertificates(e)}
            onChange={(e, type) => {
              onChange(e, "certificate");
            }}
          />
        </div>
        {isTeamsEnabled && (
          <div className="mt-3">
            <Text>Teams</Text>
            <Select
              options={teams.map((item) => ({
                ...item,
                label: `${item.label} (${item.count})`,
                field: "teams",
                required: true,
              }))}
              isClearable={false}
              placeholder="Search"
              isMulti
              value={updateLabels(teams, proposal?.teams, "teams")}
              closeMenuOnSelect={false}
              styles={colourStyles()}
              onInputChange={(e) => searchForTeams(e)}
              onChange={(e, type) => {
                onChange(e, "teams");
                if (
                  type.action === "remove-value" ||
                  type.action === "pop-value"
                ) {
                  refineTeams(type?.removedValue?.value ?? "");
                } else {
                  refineTeams(type?.option?.value ?? "");
                }
              }}
            />
          </div>
        )}
        {isRatingsEnabled && (
          <div className="mt-3">
            <Text>Ratings</Text>
            <div className="flex flex-col gap-2 ">
              <div className="flex gap-2 items-center">
                <Rating
                  name="expert-rating"
                  value={ratingsRef.current}
                  precision={0.5}
                  size="medium"
                  onChange={(e) => {
                    ratingsRef.current = Number(e.target.value);
                    onChange(ratingsRef.current, "overallRatingAvg");
                  }}
                  emptyIcon={<StarBorder fontSize="inherit" />}
                />
                <Text className="font-bold">
                  {ratingsRef.current} {ratingsRef.current < 5 && " & above"}
                </Text>
              </div>

              {ratingsRef.current > 0 && (
                <div
                  onClick={() => {
                    ratingsRef.current = 0;
                    onChange(0, "overallRatingAvg");
                  }}
                  className="underline cursor-pointer text-sm"
                >
                  clear
                </div>
              )}
            </div>
          </div>
        )}

        <div className="mt-3">
          <Text>Onboarded Date</Text>
          <TextField
            type="date"
            size="small"
            variant="outlined"
            fullWidth
            value={
              new Date(
                (minOnboardState ? minOnboardState : minOnboardedDate) * 1000
              )
                .toISOString()
                .split("T")[0]
            }
            inputProps={{ max: new Date().toISOString().split("T")[0] }}
            onChange={(e) => {
              setMinOnboardState(
                Math.round(new Date(e.target.value).getTime() / 1000)
              );

              refine([
                Math.round(new Date(e.target.value).getTime() / 1000),
                maxOnboardState ? maxOnboardState : maxOnboardedDate,
              ]);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          ></TextField>
          <div className="mt-2">
            <TextField
              type="date"
              size="small"
              variant="outlined"
              fullWidth
              value={
                new Date(
                  (maxOnboardState ? maxOnboardState : maxOnboardedDate) * 1000
                )
                  .toISOString()
                  .split("T")[0]
              }
              inputProps={{ max: new Date().toISOString().split("T")[0] }}
              onChange={(e) => {
                setMaxOnboardState(
                  Math.round(new Date(e.target.value).getTime() / 1000)
                );

                refine([
                  minOnboardState ? minOnboardState : minOnboardedDate,
                  Math.round(new Date(e.target.value).getTime() / 1000),
                ]);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </div>
        </div>

        <div className="mt-3">
          <Text>Location</Text>
          <Select
            placeholder="Location"
            ref={locationInputRef}
            value={
              locationInputRef.current?.state?.value
                ? locationInputRef.current?.state?.value
                : locationInputRef.current
            }
            options={distanceOptions}
            isClearable
            onChange={(e, options) => {
              if (e) {
                setLocationRange(milesToMetres(e.value));
                locationInputRef.current.state.value = e;
              } else {
                setLocationRange("all");
                locationInputRef.current.state.value = null;
              }
            }}
          />
        </div>
        <div className="mt-3">
          <Select
            placeholder="City, Zip"
            value={selectedPlaceSuggestion}
            options={placeSuggestions}
            isClearable
            inputValue={placeLookup}
            onInputChange={handleInputChangeValue}
            onChange={handleChangePlace}
            closeMenuOnSelect={false}
            styles={colourStyles()}
            isLoading={suggestionsLoading}
          />
        </div>

        <div className="flex items-center gap-2">
          <Button
            label="Clear Filters"
            className="w-full my-2"
            color="secondary"
            disabled={isMatchLoading}
            onClick={onResetSearch}
          />
        </div>
      </div>
    </Grid>
  );
};

export default Sidebar;
