import React from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "@apollo/client/link/error";

import { useAuth0 } from "../contexts/Auth0Context";
import { handleErrors } from "../shared/utils";

type SectionContents = {
  children: React.ReactNode;
};
const AuthorizedApolloClient = ({ children }: SectionContents): JSX.Element => {
  const { getIdTokenClaims, user } = useAuth0();

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_V3_ENDPOINT}/graphql`,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getIdTokenClaims();
    return {
      headers: {
        ...headers,
        auth: token ? `Bearer ${token.__raw}` : "",
      },
    };
  });

  const onErrorLink = onError((errors: ErrorResponse) => {
    //Don't throw exceptions on query errors. We will display a proper error in line with the error property
    handleErrors(errors, user);
  });

  const link = from([authLink, onErrorLink, httpLink]);

  const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getTags: {
              merge(existing, incoming) {
                return { ...existing, ...incoming };
              },
            },
          },
        },
      },
    }),
    link: link,
  });
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloClient;
