import React from "react";
import { usePagination } from "@material-ui/lab/Pagination";
import {
  IconChevronDoubleLeft,
  IconChevronDoubleRight,
  IconChevronLeft,
  IconChevronRight,
} from "@paro.io/base-icons";
import { Text } from "@paro.io/base-ui";
import { useHits } from "react-instantsearch";

export default function TablePagination({
  totalPages,
  total,
  currentPage,
  setCurrentPage,
  setHitsPerPage,
  hitsPerPage,
}) {
  const { hits } = useHits();
  const { items } = usePagination({
    count: totalPages,
    showFirstButton: true,
    page: currentPage + 1,
    onChange: (_, page) => {
      // Scroll to top on page change
      document.getElementById("instant-search-container")?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setCurrentPage(page - 1); // 0 based index
    },
    showLastButton: true,
  });

  const getTypeIcon = (type) => {
    if (type === "next") {
      return <IconChevronRight />;
    } else if (type === "previous") {
      return <IconChevronLeft />;
    } else if (type === "first") {
      return <IconChevronDoubleLeft />;
    } else if (type === "last") {
      return <IconChevronDoubleRight />;
    }
  };

  const defaultStyle = "w-10 h-10 border flex items-center justify-center";

  return (
    <nav className="flex gap-2 my-4">
      <div className="flex items-center gap-2">
        <Text className="font-normal">Results Per Page </Text>
        <div className="text-sm">
          <select
            className="form-select p-2  border-2 rounded-md bg-white"
            onChange={(e) => setHitsPerPage(e.target.value)}
            value={hitsPerPage}
          >
            {/* <option value={20}>20</option>
            <option value={50}>50</option> */}
            <option value={100}>100</option>
            <option value={150}>150</option>
            <option value={200}>200</option>
          </select>
        </div>
      </div>
      <Text className="font-normal">
        {` ${currentPage * hitsPerPage + 1} - ${
          currentPage * hitsPerPage + hits.length
        } of ${total}`}
      </Text>
      <div
        className="border h-10 bg-white rounded-md flex items-center"
        style={{ width: "fit-content" }}
      >
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = (
              <button type="button" {...item} className={defaultStyle}>
                …
              </button>
            );
          } else if (type === "page") {
            children = (
              <button
                type="button"
                id={page}
                className={`${defaultStyle} ${
                  selected ? "bg-primary text-white" : "bg-transparent"
                }`}
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button
                id={type}
                type="button"
                {...item}
                className={defaultStyle}
              >
                {getTypeIcon(type)}
              </button>
            );
          }

          return <div key={index}>{children}</div>;
        })}
      </div>
    </nav>
  );
}
