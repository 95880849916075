import React from 'react'
import { makeStyles, TablePagination } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import currency from 'currency.js'
import dayjs from 'dayjs'
import { ProjectMenu } from '../../ProjectMenu'

const useStyles = makeStyles({
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    border: '1px solid #E2E8F0',
    padding: '10px'
  },
  tableHead: {
    maxWidth: 125,
    minWidth: 125,
    padding: 0,
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    textAlign: 'center'
  },
  tableRow: {
    height: 40
  },
  container: {
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 50,
    padding: 10,
    margin: 10,
  },
  tableCellBorder: {
    whiteSpace: 'nowrap',
    border: '1px solid #E2E8F0'
  },
  shade: {
    backgroundColor: '#F1F5F9'
  },
  pagination: {
    display:'flex',
    justifyContent: 'left',
    width: "100%",
    alignItems: 'left',
    padding:'0px',
    },
})

export const ActiveProjectsTable = (props) => {
  const { projects, projectEstimatesMap,nextMonthprojectEstimatesMap, isTotalHoursOn, totalHoursWorkedOutOfExpectedMap } = props
  const classes = useStyles()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }
  
  if (projects.length <= 0) {
    return <div>No projects available</div>
  }
    const activeProjectData: any[] = projects.map((project) => {
        return {
        projectId: project.id,
        projectName: project.name,
        clientName: project?.client?.name,
        billType: project.billRateType,
        payRate: project.payRate,
        duration: (projectEstimatesMap[project.id] || projectEstimatesMap[project.id] === 0) ? (projectEstimatesMap[project.id]).toFixed(0) : '-',
        completedHoursFraction: (totalHoursWorkedOutOfExpectedMap[project.id]) ? totalHoursWorkedOutOfExpectedMap[project.id] : '-',
        nextMonthDuration: (nextMonthprojectEstimatesMap[project.id] || nextMonthprojectEstimatesMap[project.id] === 0) ? nextMonthprojectEstimatesMap[project.id] : '-',
        startDate: project.startDate,
        endDate: project.endDate,
        ae: project?.salesRep?.name,
        am: project?.accountManager?.name,
        projectStatus: project.status
        }
    })
    
    const tableData: any[] = activeProjectData
    return (
        <>
        <Paper className={classes.container}>
        <Table>
            <TableHead className={classes.tableHead}>
            <TableRow className={classes.tableRow}>
                <TableCell className={[classes.tableCellBorder, classes.shade].join(" ")} colSpan={13}>
                <b>Active Projects</b>
                </TableCell>
            </TableRow>
            <TableRow className={[classes.tableCellBorder, classes.shade].join(" ")}>
            <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}>
                <b>Project Name</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                <b>Client Name</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                <b>Bill Type</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                <b>Rate</b>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                <b>{isTotalHoursOn ? 'Total Monthly Hours Expected' : 'Total Hours Worked / Expected'}</b>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                <b>Next Month Hours</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                <b>Start Date</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                <b>End Date</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                <b>AE</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                <b>AM</b>
                </TableCell>
                <TableCell className={classes.tableCell}>
                <b>Project Status</b>
                </TableCell>
                <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                const {
                projectId,
                projectName,
                clientName,
                billType,
                payRate,
                duration,
                completedHoursFraction,
                nextMonthDuration,
                startDate,
                endDate,
                ae,
                am,
                projectStatus
                } = row
                const billTypeLabel = billType
                return (
                <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>{page*rowsPerPage + index+1}</TableCell>
                    <TableCell className={classes.tableCell}>{projectName}</TableCell>
                    <TableCell className={classes.tableCell}>{clientName}</TableCell>
                    <TableCell className={classes.tableCell}>{billTypeLabel}</TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                    {payRate ? currency(payRate).format() : '-'}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                    {isTotalHoursOn ? duration : completedHoursFraction}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                    {nextMonthDuration}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{startDate ? dayjs(startDate).format('MM-DD-YYYY') : '-'}</TableCell>
                    <TableCell className={classes.tableCell}>{endDate ? dayjs(endDate).format('MM-DD-YYYY') : '-'}</TableCell>
                    <TableCell className={classes.tableCell}>{ae || '-'}</TableCell>
                    <TableCell className={classes.tableCell}>{am || '-'}</TableCell>
                    <TableCell className={classes.tableCell}>{projectStatus}</TableCell>
                    <TableCell className={classes.tableCell}>
                    <ProjectMenu projectId={projectId} />
                    </TableCell>
                </TableRow>
                )
            })}
            </TableBody>
        </Table>
        <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        </>
    )
}
