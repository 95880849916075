import { useEffect } from "react";
import { useMenu } from "react-instantsearch";

function FreelancerMenu() {
  const { items, refine } = useMenu({ attribute: "applicationStatus" });
  const { value: selectedValue } = items.find((item) => item.isRefined) || {
    value: "",
  };

  useEffect(() => {
    refine("Active");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <select
      value={selectedValue}
      onChange={(event) => {
        refine(event.target.value);
      }}
      className="border p-4 rounded-md"
    >
      <option value={""}>Select</option>
      {items.map((item) => (
        <option value={item.value}>
          {item.label} ({item.count})
        </option>
      ))}
    </select>
  );
}

export default FreelancerMenu;
