import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import TruncateText from "./TruncateText";
import UpdateProposalForm from "./UpdateProposalForm";

import InfoButton from "./InfoButton";
import { makeStyles } from "@material-ui/core/styles";
import { Heading, Text, Tag, Button as BaseUiButton } from "@paro.io/base-ui";
import {
  IconPencil,
  IconDocumentText,
  IconDocumentClock,
  IconMail,
} from "@paro.io/base-icons";
import ReactivateFeedbackForm from "./ReactivateFeedbackForm";
import { Grid } from "@material-ui/core";

/* import { Dialog } from "@headlessui/react"; */
import { Modal } from "react-bootstrap";
import {
  isMM,
  isPilotVersion,
  OPPORTUNITY_TYPES,
  ORIGINAL_OPPORTUNITY_TYPES,
  StageOptionsMap,
  StageTextsMap,
} from "../data/appConstants";
import { useSnackbar } from "notistack";
// import ProposalJobBoard from "./ProposalJobBoard";
import ConfirmationModal from "./ConfirmationModal";
import CustomSpinner from "./CustomSpinner";
import { MatchResultsContext } from "../contexts/MatchResultsContext";

const information =
  'Proposal Cards: Shows active and inactive (greyed-out) proposal cards. If a proposal card is inactive, click on "Re-activate" to match proposal to experts. Red pen indicates that the proposal form is incomplete.';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "absolute",
    backgroundColor: "#ffffffbd",
  },
  modal: {
    "& .modal-dialog": {
      minWidth: "80vw",
    },
  },
}));
export default function ProposalCards({
  data,
  freelancersData,
  refreshProposals,
  isDealDeskUser,
}) {
  const params = new URLSearchParams(window.location.search);
  const edit = params.get("edit");

  return (
    <div className="py-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {data.map((p) => (
          <ProposalCardItem
            key={p.id}
            data={p}
            freelancersData={freelancersData[p.id]}
            refreshProposals={refreshProposals}
            openEdit={"" + p.id === "" + edit}
            isDealDeskUser={isDealDeskUser}
          />
        ))}
      </div>
    </div>
  );
}

function ProposalCardItem({
  data,
  freelancersData,
  refreshProposals,
  openEdit,
  isDealDeskUser,
}) {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(openEdit);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [proposal, setProposal] = useState(data);
  const [loading, setLoading] = useState(true);
  const [proposalDisabled, setProposalDisabled] = useState(true);
  // const findFreelancer = process.env.REACT_APP_FREELANCER_ALGOLIA_SEARCH_ENABLED === "true"
  const [, setMatchQuery] = useContext(MatchResultsContext)?.matchQueryContext;
  const [, setMatchResults] =
    useContext(MatchResultsContext)?.matchResultsContext;

  /*   const [isOpen, setIsOpen] = useState(true);

  function openModal(e) {
    setIsOpen(true);
    e.stopPropagation();
    setShowDetails(true);
  }
 */
  useEffect(() => {
    async function getProposalData() {
      const curPf = freelancersData || [];
      const maxFlStage = Math.max(
        ...curPf.map(
          (pf) => StageOptionsMap.get(pf?.proposedProjectStage) || "0"
        ),
        "0"
      );
      const proposalStage = [...StageOptionsMap.entries()].find(
        (v) => v[1] === "" + maxFlStage
      )[0];
      const proposalData = {
        ...data,
        proposalDisabled:
          (curPf.some(
            (pf) =>
              StageOptionsMap.get(pf.proposedProjectStage) >
              StageOptionsMap.get("Proposed")
          ) ||
            isDealDeskUser) &&
          proposal.matchesExhausted,
        proposalStage: StageTextsMap.get(proposalStage),
        proposalFreelancers: curPf.map((f) => ({
          freelancerId: f.freelancerId,
          stageUpdatedAt: f.proposedProjectStageUpdatedAt,
          stage: parseInt(StageOptionsMap.get(f.proposedProjectStage)),
        })),
      };
      setProposal(proposalData);
      setLoading(false);
      setProposalDisabled(proposalData.proposalDisabled);
    }
    getProposalData();
  }, [data, freelancersData, isDealDeskUser, proposal.matchesExhausted]);

  const currentProposal = {
    ...proposal,
    opportunityTypeMM: !!proposal.opportunityTypeMM
      ? proposal.opportunityTypeMM
      : Object.values(ORIGINAL_OPPORTUNITY_TYPES).includes(
          proposal.opportunityType
        )
      ? proposal.opportunityType
      : OPPORTUNITY_TYPES.NewBusiness,
    clientRate: proposal.matchmakingOptions.rateRange.max,
    industries: proposal.matchmakingOptions.tags.filter(
      (tag) => tag.field === "industries"
    ),
    softwares: proposal.matchmakingOptions.tags.filter(
      (tag) => tag.field === "softwares"
    ),
    skills: proposal.matchmakingOptions.tags.filter(
      (tag) => tag.field === "skills"
    ),
    certificate: proposal.matchmakingOptions.tags.filter(
      (tag) => tag.field === "certificate"
    ),
    language: proposal.matchmakingOptions.tags.filter(
      (tag) => tag.field === "language"
    ),
    teams: proposal.matchmakingOptions.tags.filter(
      (tag) => tag.field === "teams"
    ),
    serviceLinesPlusTags: [
      ...(proposal.serviceLinesPlus || "")
        ?.split(",")
        .filter((v) => v !== "")
        .map((i) => ({
          field: "serviceLinesPlusTags",
          value: i,
          required: false,
        })),
      ...(proposal.requiredServiceLinesPlus || "")
        ?.split(",")
        .filter((v) => v !== "")
        .map((i) => ({
          field: "serviceLinesPlusTags",
          value: i,
          required: true,
        })),
    ],
    estimatedStartDate: proposal.estimatedStartDate?.split("T")[0],
    estimatedEndDate: proposal.estimatedEndDate?.split("T")[0],
  };

  const [proposalReady, setProposalReady] = useState(
    !!currentProposal.name &&
      !!currentProposal.description &&
      !!currentProposal.notes &&
      !!currentProposal.projectSize &&
      !!currentProposal.serviceLine &&
      !!currentProposal.softwares.length > 0 &&
      !!currentProposal.industries.length > 0 &&
      !!currentProposal.billRateType &&
      !!currentProposal.frequency &&
      !!currentProposal.estimatedStartDate &&
      !!currentProposal.estimatedEndDate &&
      ((isPilotVersion(currentProposal.opportunityTypeMM) &&
        !!currentProposal.serviceLinesPlusTags.length > 0) ||
        !isPilotVersion(currentProposal.opportunityTypeMM))
  );

  const updateProposalFn = async () => {
    history.push("/");
    setProposalReady(true);
    await refreshProposals();
    setShowDetails(false);

    enqueueSnackbar(`Updated Proposal!`, {
      variant: "success",
    });

  };

  return (
    <>
      <div className="relative flex flex-row">
        {(proposalDisabled || !proposalReady || loading) && (
          <div
            className={`${classes.backdrop} flex items-center	justify-center w-full h-full`}
          >
            {loading && <CustomSpinner />}

            {!loading && proposalDisabled && (
              <Grid item xs={12} align="center">
                <div className={`${classes.buttonSize}`}>
                  <ReactivateFeedbackForm
                    proposalId={proposal.id}
                    setProposalDisabled={setProposalDisabled}
                  />
                </div>
              </Grid>
            )}
          </div>
        )}

        <div className="w-full h-full rounded bg-white flex flex-col">
          <div className="flex justify-between">
            <Tag
              color={`${((!proposalReady ||
                  ["Client Rejected", "Freelancer Rejects"].includes(
                    proposal.proposalStage
                  )) &&
                  `danger`) ||
                (["Project Won"].includes(proposal.proposalStage) &&
                  `success`) ||
                `info`
              }`}
              label={
                proposalReady ? proposal.proposalStage : "Proposal Incomplete"
              }
              borderRadius="r"
            />
            <div className="font-semibold text-sm text-gray-600 z-20 mt-2 flex">
              {!!currentProposal.opportunityTypeMM
                ? currentProposal.opportunityTypeMM
                : Object.values(ORIGINAL_OPPORTUNITY_TYPES).includes(
                    currentProposal.opportunityType
                  )
                ? currentProposal.opportunityType
                : OPPORTUNITY_TYPES.NewBusiness}
              <InfoButton information={information} />
            </div>
          </div>

          <div className="px-8 flex flex-col lg:block lg:text-left">
            <div className="flex justify-between">
              <div className="flex items-center mt-2 font-medium text-secondary">
                <IconDocumentText />
                <div className="ml-1">Proposal: {currentProposal.id}</div>
              </div>
              {/* {proposal.proposalFreelancers.length > 0 &&
                proposal.proposalFreelancers?.filter((pf) => pf.stage === -1)
                  .length === 0 && (
                  <ProposalJobBoard
                    proposalId={currentProposal.id}
                    onJobBoard={currentProposal.onJobBoard}
                  />
                )} */}
            </div>
            <div className="flex items-center mt-2 font-medium text-secondary">
              <IconDocumentClock />
              <div className="ml-1">Proposal: {currentProposal.name}</div>
            </div>
            <Heading size="h4">{proposal.client.name}</Heading>
            <Text size="sm">
              <TruncateText text={proposal.description} limit={75} />
            </Text>
            {proposal.notes && (
              <>
                <Heading size="h6" className="uppercase tracking-wide mt-2">
                  Additional Notes:
                </Heading>
                <Text size="sm">
                  {proposal.notes && (
                    <TruncateText text={proposal.notes} limit={200} />
                  )}
                </Text>
              </>
            )}
          </div>
          <div className="p-8 flex mt-auto">
            <div className="flex gap-2">
              <div className="w-1/2">
                <span id={`mm-proposal-select-${proposal.id}`}>
                  <BaseUiButton
                    disabled={!proposalReady || proposalDisabled}
                    onClick={() => {
                      if (!proposalReady || proposalDisabled) {
                        return;
                      }
                      if (
                        !proposal.opportunityTypeMM ||
                        isMM(proposal.opportunityTypeMM)
                      ) {
                        setMatchQuery({})
                        setMatchResults([])
                        history.push(`/proposal/${proposal.id}`);
                      } else {
                        setShowConfirmationModal(true);
                      }
                    }}
                    type="button"
                    color="primary"
                    label="Select"
                  />
                </span>
              </div>
              <div>
                {/* {
                  (process.env.REACT_APP_UNIFIED_MATCHING_ENABLED === "true" && (
                    <div className="w-1/4 ml-2">
                      <span id={`mm-proposal-select-${proposal.id}`}>
                        <BaseUiButton
                          disabled={!proposalReady || proposalDisabled}
                          onClick={() => {
                            if (!proposalReady || proposalDisabled) {
                              return;
                            }
                            setMatchQuery({})
                            setMatchResults([])
                            history.push(`/proposal-um/${proposal.id}`);
                          }}
                          type="button"
                          color="primary"
                          label="UM"
                        />
                      </span>
                    </div>
                  ))
                } */}
              </div>
            </div>
            <div className="flex w-full items-center justify-end gap-4">
              {/* // TODO COMM HUB CHANGES */}
              <div className="flex justify-end" id="mm-proposal-comm-hub">
                <BaseUiButton
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_INTERNAL_PORTAL}/communication-hub/${proposal.id}`,
                      "_blank"
                    )
                  }
                  type="button"
                  className={`z-20 cursor-pointer`}
                  color={`${proposalReady ? "light" : "danger"}`}
                  icon={<IconMail />}
                />
              </div>
              {/* // TODO COMM HUB CHANGES */}
              <div className="flex justify-end" id="mm-proposal-edit">
                <BaseUiButton
                  onClick={() => setShowDetails(true)}
                  /*  onClick={openModal} */
                  type="button"
                  className={`${!proposalReady ? "z-20" : ""} cursor-pointer`}
                  color={`${proposalReady ? "light" : "danger"}`}
                  icon={<IconPencil />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          title="Please confirm opportunity type"
          body={
            <>
              <p>
                This proposal was marked as <b>{proposal.opportunityTypeMM}</b>.
              </p>
              <p>
                About to submit an exception case, You will be directed to the
                Find Freelancer function. This action will be tracked &
                validated to ensure proper application.
              </p>
              {/* {findFreelancer ? (
                <BaseUiButton
                  label={"Legacy FaF"}
                  onClick={() =>
                    history.push(`/find-a-freelancer-legacy/${proposal.id}`)
                  }
                  color="primary"
                />
              ) : null} */}
            </>
          }
          okText="Confirm and go to Find Freelancer"
          okButtonId="mm-faf-confirm"
          onOk={() => history.push(`/find-a-freelancer/${proposal.id}`)}
        />
      )}
      {!!showDetails && (
        <Modal
          show={!!showDetails}
          onHide={() => {
            setShowDetails(false);
            history.push("/");
          }}
          className={classes.modal}
          centered
        >
          <UpdateProposalForm
            defaultValues={currentProposal}
            updateProposalFn={updateProposalFn}
          />
        </Modal>
      )}
    </>
  );
}
