import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Chip } from "@material-ui/core";
import { IconRepeat } from "@paro.io/base-icons";

export default function MultiSelectField({
  onChange,
  value = [],
  label,
  options = [],
  defaultOptions = [],
  creatable,
  restorable,
  ...field
}) {
  const filter = createFilterOptions(
    options.map((o) => ({ inputValue: o, title: o, required: true }))
  );
  const [selectedValues, setSelectedValues] = useState(
    Array.isArray(value) ? value.map((v) => ({
          inputValue: v.value,
          title: v.value,
          required: v.required,
    })) : []
  );

  return (
    <div>
      <Autocomplete
        {...field}
        value={selectedValues}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <div className="mx-1 mb-1" key={index}>
              <Chip
                variant="outlined"
                label={
                  <div
                    style={{
                      whiteSpace: "break-spaces",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  >
                    {option.title}
                  </div>
                }
                {...getTagProps({ index })}
                className={
                  option.required
                    ? `h-auto w-full font-bold`
                    : `h-auto w-full font-light`
                }
                onClick={() => {
                  const temp = [...selectedValues];
                  const index = temp.findIndex(
                    (obj) => obj.inputValue === option.inputValue
                  );
                  temp[index].required = !option.required;
                  setSelectedValues(temp);
                  onChange(temp);
                }}
              />
            </div>
          ))
        }
        multiple
        options={options
          .map((o) => ({
            inputValue: o,
            title: o,
            required: true,
          }))
          .filter(
            (s) =>
              !selectedValues.map((sv) => sv.inputValue).includes(s.inputValue)
          )}
        onChange={(event, newValue) => {
          let enteredValues = newValue;
          if (event.key === 'Enter') {
            // Prevent's default 'Enter' behavior.
            event.defaultMuiPrevented = true;
            // your handler code
            enteredValues = newValue.filter(n=>!!n.inputValue || !!n.title)
          }
          setSelectedValues(
            enteredValues
          );
          onChange(
            enteredValues.map((v) => ({ value: v.inputValue, required: v.required }))
          );
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "" && creatable) {
            filtered.push({
              inputValue: params.inputValue,
              title: `${params.inputValue} (new)`,
              required: true,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={label}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(option) => option.title}
        freeSolo
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} {...field} label={label} variant="outlined">
            {selectedValues.map((option, index) => (
              <div className="mx-1 mb-1" key={index}>
                <Chip
                  variant="outlined"
                  label={
                    <div
                      style={{
                        whiteSpace: "break-spaces",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                    >
                      {option.title}
                    </div>
                  }
                  className={
                    option.required
                      ? `h-auto w-full font-bold`
                      : `h-auto w-full font-light`
                  }
                />
              </div>
            ))}
          </TextField>
        )}
      />
      {process.env.REACT_APP_NAICS_INDUSTRIES_ENABLED === 'true' && defaultOptions?.length > 0 && 
        restorable && <button type="button" className="flex my-2" onClick={() => {
        setSelectedValues(defaultOptions.map(d => ({ inputValue: d.value, title: d.value, required: d.required })));
              onChange(defaultOptions);
      }}>
            <IconRepeat className="mr-2 mt-1" /> Restore Default Industry
      </button>}
    </div>
  );
}
