import { useCallback, useEffect, useState } from "react";
import { getRenewalProposals } from "../services/apiService";
import { Heading } from "@paro.io/base-ui";
import CustomSpinner from "./CustomSpinner";
import RenewalProposalItem from "./RenewalProposalItem";

export const RenewalsTabContent = ({
  currentSalesRep,
  setLoadingError,
  projectId,
  renewalData,
  setRenewalData,
}) => {
  const [loading, setLoading] = useState(false);

  const refreshRenewalProposals = useCallback(async () => {
    setLoading(true);

    try {
      const renewalProposalData = await getRenewalProposals(
        currentSalesRep.value
      );
      const proposals = renewalProposalData.data.data.getRenewalProposals;
      const groupedProposals = proposals.reduce((acc, proposal) => {
        const { proposalId } = proposal;
        if (!acc[proposalId]) {
          acc[proposalId] = [];
        }
        acc[proposalId].push(proposal);
        return acc;
      }, {});
      setRenewalData(groupedProposals);
      setLoading(false);
    } catch (err) {
      setLoadingError(true);
    }
  }, [currentSalesRep.value, setLoadingError, setRenewalData]);

  useEffect(() => {
    if (!renewalData) {
      refreshRenewalProposals();
    }
  }, [currentSalesRep, refreshRenewalProposals, renewalData]);

  if (loading || !renewalData) {
    return <CustomSpinner>Fetching Renewal Proposals</CustomSpinner>;
  }

  return (
    <div className="px-8 md:px-8 lg:px-16">
      {renewalData && Object.keys(renewalData).length === 0 ? (
        <div className="flex justify-center p-8 mt-3 font-bold text-secondary">
          No Active Renewals
        </div>
      ) : (
        <>
          <Heading size="h3">
            Renewal Proposals which are up for renewal
          </Heading>
          <div className="py-4">
            {Object.keys(renewalData).map((id) => {
              const clientProjects = renewalData[id];
              const proposalId = clientProjects[0].proposalId;
              const clientName = clientProjects[0].client.name;
              return (
                <RenewalProposalItem
                  groupedProposals={clientProjects}
                  proposalId={proposalId}
                  clientName={clientName}
                  refetchProposals={refreshRenewalProposals}
                  projectId={projectId}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
