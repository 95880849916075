import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'

// import FieldAndValue from '../../../_global/FieldAndValue'
// import { ProfileInformation } from '../../../styles/ProfileInformation'
import { SectionBody } from './SectionBody'

type SectionContents = {
  fieldsAndValues: { 
    field: string; 
    value: number | string; 
    nextMonthValue: number | string; 
    currentMonthLabel: string;
    nextMonthLabel: string;
    unit: string 
  },
  state?: string
}

// const CalculationField = styled(ProfileInformation)`
//   width: fit-content;
// `

// const CalculationTitleValue: typeof Grid = styled(Grid)`
//   margin-right: -10px;
// `
// const CalculationTitle: typeof Typography = styled(Typography)`
//   padding-bottom: 5px;
// `
// ---------- Styles ------------------------------------
const SectionValue: typeof Typography = styled(Typography)`
  padding-bottom: 5px;
`
const SectionUnit: typeof Typography = styled(Typography)`
  padding-bottom: 1px;
`
export const convertAnyToInt = (value: string | number | undefined | null): number => {
  const nonNumericCharacterRegex = /[^0-9.]/g
  return parseInt((value || '').toString().replace(nonNumericCharacterRegex, '') || '0')
}
// const convertAnyToPercent = (value: string | number | undefined | null): string => {
//   value = parseFloat(value ? value + '' : '0')
//   return (value * 100).toFixed(0) + '%'
// }
const TimeManagementSection = ({ fieldsAndValues, state }: SectionContents): JSX.Element => {
  return (
    <SectionBody state={state}>
      <Grid container item xs={12} >
        <Box pt={1} pb={1}>
          {fieldsAndValues.field && (
            <Grid item>
              <b style={{fontSize: 16, fontWeight: "bold"}}>
                {fieldsAndValues.field}
              </b>
              {/* <SectionTitle variant="subtitle2">{fieldsAndValues.field}</SectionTitle> */}
            </Grid>
          )}
          <Grid container item xs={12}>
            {fieldsAndValues.unit && (
              <Grid item>
                <SectionUnit variant="body2">{fieldsAndValues.unit}</SectionUnit>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid container item xs={12}>
        {fieldsAndValues.value && (
          <Grid container spacing={1} style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <Grid item >
              <b style={{fontSize: 14, fontWeight: "bold"}}>{fieldsAndValues.currentMonthLabel}</b>
            </Grid>
            <Grid item >
              <SectionValue variant="body1">{fieldsAndValues.value}</SectionValue>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12}>
        {fieldsAndValues.nextMonthValue && (
          <Grid container spacing={1} style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <Grid item >
              <b style={{fontSize: 14, fontWeight: "bold"}}>{fieldsAndValues.nextMonthLabel}</b>
            </Grid>
            <Grid item >
              <SectionValue variant="body1">{fieldsAndValues.nextMonthValue}</SectionValue>
            </Grid>
          </Grid>
        )}
      </Grid>
    </SectionBody>
  )
}

export default TimeManagementSection