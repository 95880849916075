import React, { useContext, Fragment, useState, useEffect } from "react";

import {
  IconCheck,
  IconChevronUp,
  IconCog,
  IconEye,
  IconEyeOff,
  IconMinus,
  IconQuestionMarkCircle,
  IconX,
} from "@paro.io/base-icons";
import { Avatar, Button as BaseUiButton, Heading } from "@paro.io/base-ui";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import { CartContext } from "../components/CartContext";
import { Disclosure } from "@headlessui/react";
import CompareCardMenu from "../components/CompareCardMenu";
import { Controller, useForm } from "react-hook-form";
import SelectField from "../components/SelectField";
import { isServiceLinePlusEnabled } from "../data/appConstants";
import { useTags } from "../contexts/useTags";

export default function FreelancerComparePage({
  freelancers,
  matchFormData,
  compareAll,
  um,
  setShowCompareAll,
}) {
  // const history = useHistory();
  const MANAGE_FIELDS = [
    {
      name: "Client Rate",
      matchKey: "clientRate",
      shown: true,
    },
    {
      name: "Service Line",
      matchKey: "serviceLine",
      shown: true,
    },
    {
      name: "Effort",
      matchKey: "projectSize",
      shown: true,
    },
    {
      name: "Industry",
      shown: true,
      matchFormField: "industries",
    },
    {
      name: "Software",
      shown: true,
      matchFormField: "softwares",
    },
    {
      name: "Skills",
      shown: true,
      matchFormField: "skills",
    },
    {
      name: "Languages",
      shown: true,
      matchFormField: "language",
    },
    {
      name: "Certificates",
      shown: true,
      matchFormField: "certificate",
    },
    // {
    //   name: "Terms Search",
    //   shown: true,
    // },
    ...(isServiceLinePlusEnabled(matchFormData.opportunityTypeMM)
      ? [
          {
            name: "Service Lines Plus",
            matchKey: "SL_Plus",
            shown: true,
            matchFormField: "serviceLinesPlusTags",
          },
        ]
      : []),
    // {
    //   name: "Years of Experience",
    //   shown: true,
    // },
    // {
    //   name: "Education",
    //   shown: true,
    // },
    // {
    //   name: "Certification",
    //   shown: true,
    // },
    // {
    //   name: "Client Services",
    //   shown: true,
    // },
    // {
    //   name: "Full Profile",
    //   shown: true,
    // },
    // {
    //   name: "Time Zone",
    //   shown: true,
    // },
  ];
  const itemsPerPage = 5;
  const [compareFlsFromContext, setCompareFlsFromContext] =
    useContext(CartContext).compareFlsContext;

  const [compareAllFls, setCompareAllFls] = useState(
    freelancers ? freelancers : compareFlsFromContext
  );
  const [compareFls, setCompareFls] = useState(
    compareAllFls.slice(0, itemsPerPage)
  );
  const [totalPages, setTotalPages] = useState(
    Math.ceil(compareAllFls.length / itemsPerPage)
  );
  const [page, setPage] = useState(0);

  const [searchList, setSearchList] = useState([]);
  const [searchTagList, setSearchTagList] = useState([]);
  const [fields, setFields] = useState(MANAGE_FIELDS);
  const [isOpenManageFieldsDropdown, setIsOpenManageFieldsDropdown] =
    useState(false);

  const { handleSubmit: handleSubmit2, reset: reset2, control } = useForm();
  const {
    softwareOptions,
    industryOptions,
    skillOptions,
    languageOptions,
    certificationsOptions,
    serviceLinesPlusOptions,
  } = useTags();

  useEffect(() => {
    const flsToShow = [...compareAllFls];
    setCompareFls(flsToShow.slice(0, itemsPerPage));
    setTotalPages(Math.ceil(flsToShow.length / itemsPerPage));
    setPage(0);
  }, [compareAllFls]);

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const startIndex = page * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const flsToShow = [...compareAllFls];
    const pageItems = flsToShow.slice(startIndex, endIndex);
    setCompareFls(pageItems);
  }, [compareAllFls, page]);

  const renderValue = (val) => {
    if (val === 1) {
      return (
        <>
          <Tooltip title="Perfect Match" arrow>
            <div className="w-5 m-auto">
              <IconCheck className="text-green-info" />
            </div>
          </Tooltip>
        </>
      );
    }
    if (val === 0) {
      return (
        <>
          <Tooltip title="Partial Match" arrow>
            <div className="w-5 m-auto">
              <IconMinus className="text-yellow-info" />
            </div>
          </Tooltip>
        </>
      );
    }
    if (val === -1) {
      return (
        <>
          <Tooltip title="No Match" arrow>
            <div className="w-5 m-auto">
              <IconX className="text-red-info" />
            </div>
          </Tooltip>
        </>
      );
    }
    return (
      <div className="w-5 m-auto">
        <IconQuestionMarkCircle />
      </div>
    );
  };

  const renderCompareValue = (freelancer, compareField) => {
    const val = freelancer?.relevantFields?.find(
      (d) => d.type === compareField
    )?.value;
    return renderValue(val);
  };

  const RenderClickableValue = ({ flID, word, type, children }) => (
    <span
      className="pointer"
      onClick={() => {
        window.open(
          encodeURI(
            window.location.origin +
              "/freelancer-view/" +
              flID +
              "?search=" +
              word +
              "&search-type=" +
              type
          ),
          "_blank"
        );
      }}
    >
      {children}
    </span>
  );

  const renderCompareArray = (freelancer, compareField, compareData) => {
    if (compareField === "SL_Plus") {
      compareData =
        serviceLinesPlusOptions.find(({ value }) => value === compareData)?.id +
        "";
    }

    const val = freelancer?.relevantFields?.find(
      (d) => d.type === compareField && d.data === compareData
    )?.value;
    if (val === 1 && compareField !== "Category") {
      return (
        <RenderClickableValue
          flID={freelancer.flID}
          word={
            isServiceLinePlusEnabled(matchFormData.opportunityTypeMM) &&
            compareField === "SL_Plus"
              ? serviceLinesPlusOptions.find(
                  ({ id, value }) =>
                    +id === +compareData || value === compareData
                )?.value
              : compareData
          }
          type="Proposal Tag"
        >
          {renderValue(val)}
        </RenderClickableValue>
      );
    }
    return renderValue(val);
  };

  const getSearchResult = (freelancer, searchTerm) => {
    const freelancerSearchBody = { ...freelancer };
    delete freelancerSearchBody.relevantFields;
    const val =
      JSON.stringify(freelancerSearchBody)
        .toLowerCase()
        .indexOf(searchTerm.toLowerCase()) > -1
        ? 1
        : -1;
    if (val === 1) {
      return (
        <RenderClickableValue
          flID={freelancer.flID}
          word={searchTerm}
          type="Global"
        >
          {renderValue(val)}
        </RenderClickableValue>
      );
    }
    return renderValue(val);
  };

  const getSearchTagResult = (freelancer, searchTerm) => {
    // const val = [
    //   ...freelancer?.softwareTags,
    //   ...freelancer?.industryTags,
    //   ...freelancer?.financialTags,
    //   ...flatten(
    //     freelancer?.workExperiences?.map((we) => [
    //       ...we?.softwareTags,
    //       ...we?.industryTags,
    //       ...we?.financialTags,
    //     ])
    //   ),
    //   ...flatten(
    //     [
    //       ...freelancer.externalProjects,
    //       ...paroProjectsStandardization(freelancer.paroProjects),
    //     ]?.map((project) => [
    //       ...project?.softwareTags,
    //       ...project?.industryTags,
    //       ...project?.financialTags,
    //     ])
    //   ),
    //   ...freelancer?.languages,
    //   ...freelancer?.certificates,
    // ].some((el) => el.toLowerCase() === searchTerm.toLowerCase())
    //   ? 1
    //   : -1;

    const freelancerSearchBody = { ...freelancer };
    delete freelancerSearchBody.relevantFields;
    const val =
      JSON.stringify(freelancerSearchBody)
        .toLowerCase()
        .indexOf(searchTerm.toLowerCase()) > -1
        ? 1
        : -1;
    if (val === 1) {
      return (
        <RenderClickableValue
          flID={freelancer.flID}
          word={searchTerm}
          type="Tag"
        >
          {renderValue(val)}
        </RenderClickableValue>
      );
    }
    return renderValue(val);
  };

  const toggleField = (name, shown) => {
    setFields((fields) =>
      fields.map((f) => (f.name === name ? { ...f, shown: !shown } : f))
    );
  };

  const onSubmitSearchTag = ({ searchTag }) => {
    setSearchTagList((list) => [...list, searchTag]);
    reset2();
  };

  const handleBlur = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    setIsOpenManageFieldsDropdown(false);
  };

  return (
    <>
      <div className="px-8 md:px-8 lg:px-14 pb-250">
        <div className="flex items-center gap-4">
          <Heading size="h3">Freelancer Comparison</Heading>
          {!!um && (
            <BaseUiButton
              label="Back"
              onClick={() => setShowCompareAll("")}
              color="primary"
            />
          )}
        </div>
        {compareFls.length > 0 ? (
          <div className="compare-table" id="compare-container">
            <table className="bg-white table-auto w-full">
              <tbody id="summary">
                <tr>
                  <td className="p-2 h-20">
                    <div>&nbsp;</div>
                  </td>
                  <td className="p-2 h-20">&nbsp;</td>
                  {compareFls.map((f) => (
                    <td className="p-2 h-20">
                      <div className="flex">
                        <span className="flex justify-end w-1/2">
                          <Avatar
                            name={f.FL_Name}
                            size="md"
                            className="mx-auto"
                            // width="100%"
                            src={f.imageUrl ? f.imageUrl : ""}
                          />
                        </span>
                        <span className="text-center w-1/2">
                          <CompareCardMenu
                            freelancer={f}
                            proposalId={matchFormData.id}
                            setCompareFls={setCompareAllFls}
                            compareAll={compareAll}
                          />
                        </span>
                      </div>
                    </td>
                  ))}
                </tr>

                <tr>
                  <td colSpan="2" className="border-b p-2 h-20 font-bold">
                    <div>&nbsp;</div>
                    Requirements
                  </td>
                  <td className="border-b h-20">
                    <div className="flex">
                      <button
                        type="button"
                        className="flex justify-center items-center h-full cursor-pointer group focus:outline-none"
                        onClick={handlePreviousPage}
                      >
                        <span
                          className={`inline-flex justify-center items-center w-6 h-6 rounded-full sm:w-8 sm:h-8 ${
                            page === 0 ? "bg-grey-light" : "bg-black"
                          } `}
                        >
                          <svg
                            className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15 19l-7-7 7-7"
                            />
                          </svg>
                        </span>
                      </button>
                      <div className="px-2 py-1 text-center">{`Total ${compareAllFls.length} experts`}</div>

                      <button
                        type="button"
                        className="flex justify-center items-center h-full cursor-pointer group focus:outline-none"
                        onClick={handleNextPage}
                      >
                        <span
                          className={`inline-flex justify-center items-center w-6 h-6 rounded-full sm:w-8 sm:h-8 ${
                            page === totalPages - 1
                              ? "bg-grey-light"
                              : "bg-black"
                          }`}
                        >
                          <svg
                            className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                    &nbsp;
                  </td>
                  {compareFls.map((f) => (
                    <td className="border-b h-20">
                      <div
                        className="font-bold cursor-pointer underline"
                        onClick={() => {
                          window.open(
                            window.location.origin +
                              "/freelancer-view/" +
                              f.flID +
                              "?words=" +
                              JSON.stringify(searchList) +
                              "&tags=" +
                              JSON.stringify(searchTagList) +
                              "&industry-tags=" +
                              JSON.stringify(
                                f.relevantFields
                                  .filter((d) => "Industry" === d.type)
                                  ?.map(({ data }) => data)
                              ) +
                              "&software-tags=" +
                              JSON.stringify(
                                f.relevantFields
                                  .filter((d) => "Software" === d.type)
                                  ?.map(({ data }) => data)
                              ) +
                              "&skill-tags=" +
                              JSON.stringify(
                                f.relevantFields
                                  .filter((d) => "Skills" === d.type)
                                  ?.map(({ data }) => data)
                              ) +
                              "&language-tags=" +
                              JSON.stringify(
                                f.relevantFields
                                  .filter((d) => "Languages" === d.type)
                                  ?.map(({ data }) => data)
                              ) +
                              "&certification-tags=" +
                              JSON.stringify(
                                f.relevantFields
                                  .filter((d) => "Certificates" === d.type)
                                  ?.map(({ data }) => data)
                              ) +
                              "&search-type=" +
                              "All",
                            "_blank"
                          );
                          // history.push("/freelancer-view/" + f.flID);
                        }}
                      >
                        {f.FL_Name}
                      </div>
                      {f.address?.city + ", " + f.address?.region}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="p-2 border-b">
                    <span className="text-dark font-weight-semibold jus">
                      % Match
                    </span>
                  </td>
                  <td className="p-2 border-b">
                    <span>-</span>
                  </td>
                  {compareFls.map((f) => (
                    <td className="p-2 border-b">
                      {f.matchPercent ? (
                        <span className="text-green-info font-bold">
                          {`${Math.round(f.matchPercent)}%`}
                        </span>
                      ) : (
                        <span>NA</span>
                      )}
                    </td>
                  ))}
                </tr>

                {fields
                  .filter(
                    (field) => field.name === "Client Rate" && field.shown
                  )
                  .map((field) => (
                    <>
                      <tr>
                        <td className="p-2 border-b">
                          <span className="text-dark font-weight-semibold">
                            {field.name}
                          </span>
                        </td>
                        <td className="p-2 border-b">
                          <span className="text-dark font-weight-semibold">
                            {`$${matchFormData[field.matchKey]}`}
                          </span>
                        </td>
                        {compareFls.map((f) => (
                          <td className="p-2 border-b">
                            <span className="text-dark font-weight-semibold">
                              {`$${f.legacyMetadata?.hourlyRate}`}
                            </span>
                          </td>
                        ))}
                      </tr>
                    </>
                  ))}

                {fields
                  .filter(
                    (field) => field.name === "Service Line" && field.shown
                  )
                  .map((field) => (
                    <>
                      <tr>
                        <td className="p-2 border-b">
                          <span className="text-dark font-weight-semibold">
                            {field.name}
                          </span>
                        </td>
                        <td className="p-2 border-b" />
                        {compareFls.map((f) => (
                          <td className="p-2 border-b">
                            <span className="text-dark font-weight-semibold">
                              {f?.legacyMetadata?.primaryServiceLine}
                            </span>
                          </td>
                        ))}
                      </tr>
                    </>
                  ))}

                {fields
                  .filter(
                    (field) => ["Effort"].includes(field.name) && field.shown
                  )
                  .map((field) => (
                    <>
                      <tr>
                        <td className="p-2 border-b">
                          <span className="text-dark font-weight-semibold">
                            {field.name}
                          </span>
                        </td>
                        <td className="p-2 border-b">
                          <span className="text-dark font-weight-semibold compare-text-wrap">
                            {`${matchFormData[field.matchKey]}`}
                          </span>
                        </td>
                        {compareFls.map((f) => (
                          <td className="p-2 border-b">
                            <span className="text-dark font-weight-semibold">
                              {renderCompareValue(f, field.name)}
                            </span>
                          </td>
                        ))}
                      </tr>
                    </>
                  ))}

                {fields
                  .filter(
                    (field) =>
                      [
                        "Industry",
                        "Software",
                        "Skills",
                        "Languages",
                        "Certificates",
                        ...(isServiceLinePlusEnabled(
                          matchFormData.opportunityTypeMM
                        )
                          ? ["Service Lines Plus"]
                          : []),
                      ].includes(field.name) && field.shown
                  )
                  .map((field) => (
                    <>
                      <Disclosure defaultOpen>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full py-2 text-left bg-white-100 rounded-lg">
                              <tr>
                                <td className={`p-2 ${open ? `` : `border-b`}`}>
                                  <span className="text-dark font-weight-semibold">
                                    {field.name}
                                  </span>
                                </td>
                                <td className={`p-2 ${open ? `` : `border-b`}`}>
                                  <span className="text-dark font-weight-semibold">
                                    <IconChevronUp
                                      className={`${
                                        open ? "transform rotate-180" : ""
                                      } w-5 h-5`}
                                    />
                                  </span>
                                </td>
                                {compareFls.map((f) => (
                                  <td
                                    className={`p-2 ${open ? `` : `border-b`}`}
                                  >
                                    <span className="text-dark font-weight-semibold">
                                      {renderCompareArray(
                                        f,
                                        "Category",
                                        field.matchKey === "SL_Plus"
                                          ? field.matchKey
                                          : field.name
                                      )}
                                    </span>
                                  </td>
                                ))}
                              </tr>
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-sm text-gray-500">
                              {matchFormData[field.matchFormField].map(
                                (item) => (
                                  <tr>
                                    <td className="px-2 py-0 border-b">
                                      &nbsp;
                                    </td>

                                    <td className="px-2 py-0 align-middle border-b">
                                      <Chip
                                        className="compare-chip"
                                        classes={{ label: "compare-text-wrap" }}
                                        size="small"
                                        label={
                                          <span className="text-dark font-weight-semibold">
                                            {isServiceLinePlusEnabled(
                                              matchFormData.opportunityTypeMM
                                            ) && field.matchKey === "SL_Plus"
                                              ? serviceLinesPlusOptions.find(
                                                  ({ id, value }) =>
                                                    +id === +item.value ||
                                                    value === item.value
                                                )?.value
                                              : item.value}
                                          </span>
                                        }
                                      />
                                    </td>
                                    {compareFls.map((f) => (
                                      <td className="px-2 py-0 border-b align-middle">
                                        <span className="text-dark font-weight-semibold">
                                          {renderCompareArray(
                                            f,
                                            field.matchKey === "SL_Plus"
                                              ? field.matchKey
                                              : field.name,
                                            item.value
                                          )}
                                        </span>
                                      </td>
                                    ))}
                                  </tr>
                                )
                              )}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </>
                  ))}
                {searchList.map((item) => (
                  <tr>
                    <td className="p-2 border-b">Global Term</td>

                    <td className="p-2 border-b">
                      <Chip
                        className="compare-search-term-chip"
                        label={
                          <span className="flex justify-center items-center text-dark font-weight-semibold">
                            {item}
                            <div
                              onClick={() => {
                                setSearchList((list) =>
                                  list.filter((l) => l !== item)
                                );
                              }}
                              className="pointer ml-1"
                            >
                              <IconX size="sm" />
                            </div>
                          </span>
                        }
                      />
                    </td>
                    {compareFls.map((f) => (
                      <td className="p-2 border-b">
                        <span className="text-dark font-weight-semibold">
                          {getSearchResult(f, item)}
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}

                {searchTagList.map((item) => (
                  <tr>
                    <td className="p-2 border-b">Tag</td>

                    <td className="p-2 border-b">
                      <Chip
                        className="compare-search-tag-chip"
                        label={
                          <span className="flex justify-center items-center text-dark font-weight-semibold">
                            {item}
                            <div
                              onClick={() => {
                                setSearchTagList((list) =>
                                  list.filter((l) => l !== item)
                                );
                              }}
                              className="pointer ml-1"
                            >
                              <IconX size="sm" />
                            </div>
                          </span>
                        }
                      />
                    </td>
                    {compareFls.map((f) => (
                      <td className="p-2 border-b">
                        <span className="text-dark font-weight-semibold">
                          {getSearchTagResult(f, item)}
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}

                <tr>
                  <td className="p-2 border-b">
                    <span className="text-dark font-weight-semibold">
                      Global/Tag Search
                    </span>
                  </td>
                  <td className="p-2 border-b longer-td">
                    <span className="text-dark font-weight-semibold">
                      <form onSubmit={handleSubmit2(onSubmitSearchTag)}>
                        <Controller
                          name="searchTag"
                          render={({ field }) => (
                            <SelectField
                              {...field}
                              isGrouped
                              creatable
                              resetField
                              groupBy={(option) => option.type}
                              placeholder="Enter Search Tag"
                              options={[
                                ...softwareOptions.map((s) => ({
                                  title: s,
                                  type: "Software",
                                })),
                                ...skillOptions.map((s) => ({
                                  title: s,
                                  type: "Skills",
                                })),
                                ...industryOptions.map((s) => ({
                                  title: s,
                                  type: "Industries",
                                })),
                                ...languageOptions.map((s) => ({
                                  title: s,
                                  type: "Languages",
                                })),
                                ...certificationsOptions.map((s) => ({
                                  title: s,
                                  type: "Certifications",
                                })),
                                ...serviceLinesPlusOptions.map((s) => ({
                                  title: s.value,
                                  type: "SL Plus",
                                })),
                              ]}
                            />
                          )}
                          control={control}
                        />
                        {/* <input
                              className="border-2 p-4 rounded"
                              type="text"
                              defaultValue=""
                              placeholder="Enter Search Term"
                              {...register("searchString", { required: true })}
                            /> */}
                      </form>
                    </span>
                  </td>
                  {compareFls.slice(4).map((f) => (
                    <td className="p-2 border-b">&nbsp;</td>
                  ))}
                </tr>

                <tr tabIndex={0} onBlur={handleBlur}>
                  <td className="p-2 h-20">
                    <div className="w-full">
                      <div className="relative inline-block text-left">
                        <>
                          <div className="menu-dropdown">
                            <div className="inline-flex py-2 font-medium  text-white rounded-md bg-opacity-20 hover:bg-opacity-30">
                              <BaseUiButton
                                onClick={() => {
                                  setIsOpenManageFieldsDropdown(
                                    !isOpenManageFieldsDropdown
                                  );
                                  setTimeout(() => {
                                    const $containerNode =
                                      document.getElementById(
                                        "compare-container"
                                      );
                                    $containerNode?.scrollTo({
                                      behavior: "smooth",
                                      top: $containerNode.scrollHeight,
                                    });
                                  }, 100);
                                }}
                                label="Manage Fields"
                                iconLeft={<IconCog size="sm" />}
                              />
                            </div>
                          </div>
                          {isOpenManageFieldsDropdown && (
                            <div
                              id="manage-fields-list"
                              className="origin-top-right absolute w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <div className="px-1 py-1">
                                {fields.map((i) => (
                                  <div
                                    className="flex justify-between pointer"
                                    onClick={(e) => {
                                      toggleField(i.name, i.shown);
                                    }}
                                    key={i.name}
                                  >
                                    <span>
                                      <button
                                        className={
                                          "text-gray-700  px-4 py-2 text-md whitespace-nowrap"
                                        }
                                      >
                                        {i.name}
                                      </button>
                                    </span>
                                    <span
                                      className={
                                        "text-gray-700 block px-4 py-2 text-sm"
                                      }
                                    >
                                      {i.shown ? <IconEye /> : <IconEyeOff />}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  </td>
                  <td className="p-2 h-20">&nbsp;</td>
                  {compareFls.map((f) => (
                    <td className="p-2 h-20">
                      <BaseUiButton
                        onClick={() => {
                          setCompareFlsFromContext(
                            compareFlsFromContext.filter((i) => i.id !== f.flID)
                          );
                          setCompareAllFls((currentState) =>
                            currentState.filter((c) => c.flID !== f.flID)
                          );
                        }}
                        className="remove-compare-btn"
                        label="Remove"
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <Heading size="h5">No Freelancers added to Compare</Heading>
        )}
      </div>
    </>
  );
}
