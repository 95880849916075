import React from 'react'
import omit from 'lodash/omit'
import styled from 'styled-components'

import colors from '../../../shared/constants/colors'

export const SectionBody = styled((props) => {
  return <div {...omit(props, SectionBody.OmitProps)} />
})`
  background-color: ${colors.white};
  border-radius: 2px;
  ${({ state }) => {
    return `
      border: ${state === 'danger' ? 3 : 1}px Solid ${colors.lightGray};
    `
  }}
  color: ${colors.paroBlack};
  font-family: Roboto;
  padding: 12px 10px 12px 10px;
  width: 100%;
  ${({ state }) => {
    return state === 'danger' && `
      border-color: #EF5360 !important;
    `
  }}
  `

SectionBody.OmitProps = [
  'state'
]