import { useState, useEffect } from "react";
import {
  getProposalEfforts,
  getProposalTags,
  getServiceLinesPlus,
} from "../services/apiService";

export const useTags = () => {
  const [effortOptions, setEffortOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [softwareOptions, setSoftwareOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [serviceLinesPlusOptions, setServiceLinesPlusOptions] = useState([
    {
      id: 1,
      value: "Tax Preparation (Public Accounting Experience)",
      paragraph:
        "Experience preparing tax returns working for / on behalf of a public accounting (CPA) firm",
    },
    {
      id: 2,
      value: "Tax Preparation",
      paragraph:
        "Experience preparing tax returns for direct end client (e.g. preparing Joe's Pizza's Federal Tax Filing working directly with the owners of Joe's Pizza",
    },
    {
      id: 3,
      value: "Tax Review (Public Accounting Experience)",
      paragraph:
        "Experience reviewing tax returns working for / on behalf of a public accounting (CPA) firm",
    },
    {
      id: 4,
      value: "External Audit Preparation (Public Accounting Experience)",
      paragraph:
        "Experience preparing audit workpapers and/or other audit related tasks working for / on behalf of a public accounting (CPA) firm",
    },
    {
      id: 5,
      value: "External Audit Review (Public Accounting Experience)",
      paragraph:
        "Experience reviewing or managing audits and/or other audit related tasks working for / on behalf of a public accounting (CPA) firm",
    },
    {
      id: 6,
      value: "Client Accounting Services (Public Accounting Experience)",
      paragraph:
        "Experience performing accounting related services (bookkeeping/accounting/controller) working for / on behalf of a public accounting (CPA) firm",
    },
    {
      id: 7,
      value: "Transactional Advisory (Public Accounting Experience)",
      paragraph:
        "Experience helping organizations evaluate and navigate corporate transactions, with services that include business modeling, M&A, and valuations, working for / on behalf of a public accounting (CPA) firm",
    },
    {
      id: 100,
      value: "Other",
      paragraph: "Expert Free Text selection on Formstack",
    },
  ]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [certificationsOptions, setCertificationsOptions] = useState([]);
  const [teamsOptions, setTeamsOptions] = useState([]);

  useEffect(() => {
    const promiseOptions = async () => {
      const responses = await Promise.all([
        getProposalEfforts(),
        getProposalTags(),
        getServiceLinesPlus(),
      ]);
      setServiceOptions([...responses[0].data.data.getServiceLines]);
      setEffortOptions([...responses[0].data.data.getProjectSizes]);
      setSoftwareOptions([
        ...new Set([...responses[1].data.data.getTags.software.values]),
      ]);
      setIndustryOptions([
        ...new Set([...responses[1].data.data.getTags.industries.values]),
      ]);
      setSkillOptions([
        ...new Set([...responses[1].data.data.getTags.skills.values]),
      ]);
      setLanguageOptions([
        ...new Set([...responses[1].data.data.getTags.languages.values]),
      ]);
      setCertificationsOptions([
        ...new Set([...responses[1].data.data.getTags.certificates.values]),
      ]);
      setTeamsOptions([
        ...new Set([...responses[1].data.data.getTags.haveATeam.values]),
      ]);
      setServiceLinesPlusOptions([
        ...new Set([...responses[2].data.data.getServiceLinesPlus]),
      ]);
    };
    promiseOptions();
    // reset(defaultValues);
  }, []);

  return {
    effortOptions,
    serviceOptions,
    softwareOptions,
    industryOptions,
    skillOptions,
    serviceLinesPlusOptions,
    languageOptions,
    certificationsOptions,
    teamsOptions,
  };
};
