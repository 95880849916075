import React from 'react'
import { Grid } from '@material-ui/core'

type SectionContents = {
  children: React.ReactElement[]
}

const FieldAndValue = ({ children }: SectionContents): JSX.Element => {
  return (
    <>
      {children.map((childElement, idx) => {
        return (
          <Grid item xs={6} key={idx}>
            {childElement}
          </Grid>
        )
      })}
    </>
  )
}

export default FieldAndValue
