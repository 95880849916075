import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Tag } from "@paro.io/base-ui";
import {
  IconChevronRight,
  IconHome,
  IconDocumentPencil,
} from "@paro.io/base-icons";
import { Modal } from "react-bootstrap";
import UpdateProposalForm from "./UpdateProposalForm";
import { transformProposalData } from "../utils/freelancerSearchService";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    fontWeight: "bold",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  Breadcrumbs: {
    background: "transparent",
    padding: theme.spacing(2, 5, 2, 5),
  },
  backdrop: {
    position: "absolute",
    backgroundColor: "#ffffffbd",
  },
  modal: {
    "& .modal-dialog": {
      minWidth: "80vw",
    },
  },
}));

export default function BreadCrumbsBarNew(props) {
  const { proposalId, freelancerId, checkout, isLoading } = props;
  const [showDetails, setShowDetails] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        spacing={1}
        alignItems="center"
        className="bg-white border p-2 rounded-md z-10 relative"
      >
        <Grid item md={4} xs={12}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<IconChevronRight size="xs" />}
          >
            {proposalId && (
              <Link
                color="inherit"
                onClick={() => history.push(`/`)}
                className={classes.link}
              >
                <IconHome size="sm" />
              </Link>
            )}
            {proposalId && !freelancerId && !checkout && !isLoading && (
              <div className="flex gap-4 items-center  ">
                <Typography color="textPrimary" className={classes.link}>
                  Proposal: {proposalId}
                </Typography>
                <Tag
                  label={props?.proposalDetails?.opportunityTypeMM}
                  size="small"
                />
              </div>
            )}
          </Breadcrumbs>
        </Grid>
        <Grid item md={4} xs={12}>
          <div
            className="max-md:text-center m-0 font-bold !text-ellipsis overflow-hidden whitespace-nowrap text-md"
            size="sm"
            style={{ textOverflow: "ellipsis", textAlign: "center" }}
          >
            {props?.proposalDetails?.name}
          </div>
        </Grid>
        {!isLoading && (
          <Grid item md={4} xs={12}>
            <div className="text-right flex justify-end w-fit">
              <button
                className="flex gap-2 items-center font-bold text-right !text-uppercase border py-1 px-2 text-sm rounded-md"
                onClick={() => setShowDetails(true)}
              >
                <IconDocumentPencil /> EDIT PROPOSAL
              </button>
            </div>
          </Grid>
        )}
      </Grid>
      {!!showDetails && (
        <Modal
          show={!!showDetails}
          onHide={() => {
            setShowDetails(false);
          }}
          className={classes.modal}
          centered
        >
          <UpdateProposalForm
            defaultValues={transformProposalData(props?.proposalDetails) ?? {}}
            updateProposalFn={() => {
              props.refetchProposal();
              setShowDetails(false);
            }}
          />
        </Modal>
      )}
    </>
  );
}
