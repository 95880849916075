import React from "react";
import { useInstantSearch } from "react-instantsearch";
import Loading from "../../_global/Loader";

export default function LoadingIndicator() {
  const { status } = useInstantSearch();

  if (status === "loading" || status === "stalled") {
    return <Loading size="14px" />;
  }
  return null;
}
