import { FAF_GROUPS, getKeyByValue, OPPORTUNITY_TYPES, ORIGINAL_OPPORTUNITY_TYPES, StageOptionsMap } from "../data/appConstants";

export const formatProposalDetails = (data, curPf) => {
    return {
        ...data,
        proposalFreelancers: curPf.map((f) => ({
            freelancerId: f.freelancerId,
            stageUpdatedAt: f.proposedProjectStageUpdatedAt,
            stage: parseInt(StageOptionsMap.get(f.proposedProjectStage)),
        })),
        status: "Available",
        search: [
            {
                title: data.serviceLine,
                inputValue: data.serviceLine,
                groupName: "Service Lines",
                required: true,
            },
            ...data.matchmakingOptions.tags.reduce((acc, t) => {
                if (
                    (t.field === "industries" && t.value === "All") ||
                    (t.field === "softwares" && t.value === "None")
                ) {
                    return acc;
                } else {
                    return [
                        ...acc,
                        {
                            title: t.value,
                            inputValue: t.value,
                            groupName: getKeyByValue(FAF_GROUPS, t.field),
                            required: t.required,
                        },
                    ];
                }
            }, []),
        ],
        // minHours: "" + data.matchmakingOptions.availabilityRange.min,
        // maxHours: "" + data.matchmakingOptions.availabilityRange.max,
        minRate: "" + data.matchmakingOptions.rateRange.min,
        maxRate: "" + data.matchmakingOptions.rateRange.max,
        opportunityTypeMM: data.opportunityTypeMM,
    };
}

export const transformProposalData = (updatedProposal) => {
    if (!updatedProposal) {
        return {};
    }
    let matchFormData = updatedProposal;
    matchFormData = {
        ...matchFormData,
        opportunityTypeMM: !!matchFormData.opportunityTypeMM
            ? matchFormData.opportunityTypeMM
            : Object.values(ORIGINAL_OPPORTUNITY_TYPES).includes(
                matchFormData.opportunityType
            )
                ? matchFormData.opportunityType
                : OPPORTUNITY_TYPES.NewBusiness,
        clientRate: matchFormData.matchmakingOptions.rateRange.max,
        industries: matchFormData.matchmakingOptions.tags.filter(
            (tag) => tag.field === "industries"
        ),
        softwares: matchFormData.matchmakingOptions.tags.filter(
            (tag) => tag.field === "softwares"
        ),
        skills: matchFormData.matchmakingOptions.tags.filter(
            (tag) => tag.field === "skills"
        ),
        language: matchFormData.matchmakingOptions.tags.filter(
            (tag) => tag.field === "language"
        ),
        certificate: matchFormData.matchmakingOptions.tags.filter(
            (tag) => tag.field === "certificate"
        ),
        ...(process.env.REACT_APP_MATCH_TEAMS_ENABLED === "true" && {
            teams: matchFormData.matchmakingOptions.tags.filter(
                (tag) => tag.field === "teams"
            ),
        }),
        ...(process.env.REACT_APP_PARTNERSHIP_ENABLED === "true" && {
            partnerTags: matchFormData.matchmakingOptions.tags.filter(
              (tag) => tag.field === "partnerTags"
            )
          }),
        serviceLinesPlusTags: Array.isArray(matchFormData?.serviceLinesPlusTags) && matchFormData?.serviceLinesPlusTags?.length ? matchFormData?.serviceLinesPlusTags : [
            ...(matchFormData.serviceLinesPlus || "")
                ?.split(",")
                .filter((v) => v !== "")
                .map((i) => ({
                    field: "serviceLinesPlusTags",
                    value: i,
                    required: false,
                })),
            ...(matchFormData.requiredServiceLinesPlus || "")
                ?.split(",")
                .filter((v) => v !== "")
                .map((i) => ({
                    field: "serviceLinesPlusTags",
                    value: i,
                    required: true,
                })),
        ],
        estimatedStartDate: matchFormData.estimatedStartDate?.split("T")[0],
        estimatedEndDate: matchFormData.estimatedEndDate?.split("T")[0],
    };
    return matchFormData;
};

export const formatSoftTags = (data = []) => {
    return (
        "" +
        data.reduce((acc, i) => {
            if (!i.required) {
                return [...acc, i.value || i.inputValue];
            }
            return acc;
        }, [])
    );
};

export const formatHardTags = (data = []) => {
    return (
        "" +
        data.reduce((acc, i) => {
            if (i.required) {
                return [...acc, i.value || i.inputValue];
            }
            return acc;
        }, [])
    );
};

export const buildRecommendationsRequest = (defaultValues, serviceLinesPlusOptions, user) => {
    const data = transformProposalData(defaultValues) ?? {};
    const requestData = {
        id: defaultValues.id,
        projectSize: data.projectSize,
        algoliaFreelancers: data?.algoliaFreelancers,
        clientRate: parseInt(data.maxRate),
        industry: formatSoftTags(data.industries),
        requiredIndustry: formatHardTags(data.industries),
        software: formatSoftTags(data.softwares),
        requiredSoftware: formatHardTags(data.softwares),
        skill: formatSoftTags(data.skills),
        requiredSkill: formatHardTags(data.skills),
        languages: formatSoftTags(data.language),
        requiredLanguages: formatHardTags(data.language),
        certificates: formatSoftTags(data.certificate),
        requiredCertificates: formatHardTags(data.certificate),
        ...(process.env.REACT_APP_MATCH_TEAMS_ENABLED === "true" && {
            teams: formatSoftTags(data.teams),
            requiredTeams: formatHardTags(data.teams),
        }),
        description: defaultValues.description,
        clientName: defaultValues.client.name,
        notes: defaultValues.notes,
        serviceLine: data.serviceLine,
        secondaryServiceLine: data.secondaryServiceLine,
        timezones: data?.timezones ?? "",
        firmsOnly: data.firmsOnly,
        engagedFreelancers: defaultValues.proposalFreelancers,
        userId: user.auth0UserId,
        billRateType: data.billRateType,
        frequency: data.frequency,
        // serviceLinesPlus: formatSoftTags(data.serviceLinesPlusTags),
        // requiredServiceLinesPlus: formatHardTags(data.serviceLinesPlusTags),
        category: data.category,
        // TODO - if string of IDs instead of values
        serviceLinesPlus:
            "" +
            serviceLinesPlusOptions
                .filter((slpOption) =>
                    data?.serviceLinesPlusTags?.some(
                        (slpTag) =>
                            slpOption?.value === (slpTag.value || slpTag.inputValue) &&
                            !slpTag.required
                    )
                )
                .map(({ id }) => id),
        requiredServiceLinesPlus:
            "" +
            serviceLinesPlusOptions
                .filter((slpOption) =>
                    data?.serviceLinesPlusTags?.some(
                        (slpTag) =>
                            slpOption?.value === (slpTag.value || slpTag.inputValue) &&
                            slpTag.required
                    )
                )
                .map(({ id }) => id),
        estimatedStartDate: data.estimatedStartDate,
        estimatedEndDate: data.estimatedEndDate,
        opportunityTypeMM: defaultValues.opportunityTypeMM,
    };
    return requestData;
};

function getCommonElements(arr1, arr2) {
    return arr1.filter(obj1 =>
        arr2.some(obj2 => obj1.id === obj2.id)
    );
}

export const sortByMatchingArray = (arr1, arr2, sortDirection, sortBy) => {
    const idMap = new Map();

    const commonElements = getCommonElements(arr1, arr2)
    arr2.forEach((item, index) => {
        idMap.set(item.id, index);
    });

    arr1.sort((a, b) => {
        const indexA = idMap.get(a.id);
        const indexB = idMap.get(b.id);

        if (indexA !== undefined && indexB !== undefined) {
            return indexA - indexB;
        }

        if (indexA !== undefined) {
            return -1;
        }
        if (indexB !== undefined) {
            return 1;
        }

        return 0;
    });

    const matchedElements = arr1.slice(0, commonElements.length).sort((a, b) => {
        if (sortDirection === "asc") {
            return a[sortBy] > b[sortBy] ? 1 : -1;
        } else {
            return a[sortBy] < b[sortBy] ? 1 : -1;
        }
    })

    return [...matchedElements, ...arr1.slice(commonElements.length, arr1.length)];
}

export const searchInitialData = {
    "minHours": 0,
    "maxHours": 0,
    "minRate": 0,
    "maxRate": 0,
    "clientRate": 0,
    "overallRatingAvg": 0,
    "industries": [],
    "softwares": [],
    "skills": [],
    "language": [],
    "certificate": [],
    "serviceLinesPlusTags": [],
    "serviceLine": [],
    "teams": []
};

export const fastTrackServicesArray = [
  { label: "Bookkeeping Clean Up", value: "Bookkeeping Clean Up" },
  {
    label: "Basic Monthly Bookkeeping Support",
    value: "Basic Monthly Bookkeeping Support",
  },
  {
    label: "Plus Monthly Bookkeeping Support",
    value: "Plus Monthly Bookkeeping Support",
  },
  {
    label: "Premium Monthly Bookkeeping Support",
    value: "Premium Monthly Bookkeeping Support",
  },
  {
    label: "Monthly Bookkeeping Support",
    value: "Monthly Bookkeeping Support",
  },
  {
    label: "Implementation of Accounting Software",
    value: "Implementation of Accounting Software",
  },
  { label: "Tax Preparation", value: "Tax Preparation" },
  { label: "Tax Filing", value: "Tax Filing" },
  { label: "Accounting Advisory", value: "Accounting Advisory" },
];