import { useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { stopRenewal } from "../services/apiService";
import { Auth0Context } from "../contexts/Auth0Context";
import ConfirmationModal from "./ConfirmationModal";
import { Select, Button as BaseUiButton } from "@paro.io/base-ui";
import { reasonTypeOptions } from "../utils/reasonCodesConstants";
import { useLocation, useHistory } from "react-router-dom";

const ProjectActionButton = ({ project, refetchProposals, showModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(Auth0Context);
  const [showRenewalModal, setShowRenewalModal] = useState(showModal ?? false);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleShowModal = (val) => {
    setShowRenewalModal(val);
    if (showModal) {
      history.replace(location.pathname);
    }
  };

  const handleSubmit = async () => {
    //we need to add mutation for adding the reason to the table.
    setLoading(true);
    try {
      const payload = {
        renewalProposalId: project.id,
        rejectedReason: reason,
        rejectedByUserId: user.auth0UserId,
      };
      await stopRenewal(payload);
      handleShowModal(false);
      setReason("");
      enqueueSnackbar(`Project Renewal Stopped!`, {
        variant: "success",
      });
      refetchProposals();
      setLoading(false);
    } catch (err) {
      enqueueSnackbar(`Errror: ${err}`, {
        variant: "error",
      });
    }
  };
  return (
    <>
      {showRenewalModal && (
        <ConfirmationModal
          show={showRenewalModal}
          setShow={handleShowModal}
          title="Stop Auto Renewal"
          body={
            <div className="relative">
              <div
                className={`${
                  project?.rejectedByUserId ? "pointer-events-none blur-sm" : ""
                }`}
              >
                <div className="flex-auto pb-3">
                  <p className="text-md pb-3">
                    Are you sure you want to stop the auto renewal for this
                    project? You cannot restart the auto-renewal process.
                  </p>
                  <Select
                    options={reasonTypeOptions}
                    value={reason}
                    required={true}
                    onChange={(e) => setReason(e.target.value)}
                    label="Reason Type"
                    className="mb-5"
                  />
                </div>
                <div className="flex-auto pt-3">
                  <BaseUiButton
                    label="yes, stop renewal"
                    onClick={handleSubmit}
                    color="danger"
                    disabled={!reason}
                    className="w-full"
                    isLoading={loading}
                  />
                </div>
              </div>
              {project?.rejectedByUserId && (
                <div className="absolute inset-0 flex items-center justify-center z-10 bg-white bg-opacity-90">
                  <p className="text-lg font-bold text-gray-700">
                    This renewal already has been stopped.
                  </p>
                </div>
              )}
            </div>
          }
        />
      )}
      <BaseUiButton
        label="cancel renewal"
        size="sm"
        color="danger"
        disabled={project.rejectedByUserId}
        onClick={() => setShowRenewalModal(true)}
        className="flex items-center whitespace-nowrap"
      />
    </>
  );
};

export default ProjectActionButton;
