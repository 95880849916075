import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetClosedParoProjectsQueryVariables = Exact<{
  legacyFreelancerId: Scalars['Int'];
}>;


export type GetClosedParoProjectsQuery = { getClosedParoProjects: Array<{ id: number, name: string, billRateType: string, payRate: number, startDate: any, endDate: any, status: string, client: { name: string }, accountManager: { name: string }, salesRep: { name: string } }> };

export type GetFreelancerProfileQueryVariables = Exact<{
  freelancerId: Scalars['Int'];
}>;


export type GetFreelancerProfileQuery = { getFreelancerProfile: { projectWinRate: number, availableHours: number } };

export type GetFreelancerAvailabilityQueryVariables = Exact<{
  freelancerId: Scalars['Int'];
  month: Scalars['String'];
}>;


export type GetFreelancerAvailabilityQuery = { getFreelancerAvailability: { freelancerId: number, freelancerName: string, availability: number, estimatedProjectHours: number, goal: number, booked: number, estimatedProposalHours: number, pending: number, estimatedAvailability: number, monthlyHoursAvailable: number, month: string } };

export type GetParoProjectsForAvailabilityQueryVariables = Exact<{
  legacyFreelancerId: Scalars['Int'];
}>;


export type GetParoProjectsForAvailabilityQuery = { getParoProjects: Array<{ id: number, name: string, billRateType: string, payRate: number, startDate: any, endDate: any, status: string, client: { name: string }, accountManager: { name: string }, salesRep: { name: string } }> };

export type GetProjectEstimatesQueryVariables = Exact<{
  freelancerId: Scalars['Int'];
}>;


export type GetProjectEstimatesQuery = { getProjectEstimates: Array<{ projectId: number, freelancerId: number, projectName: string, clientName: string, endDate: string, estimatedProjectHours: number, startDate: string, trueProjectHours: number, month: string, projectActualHours: number, totalMonthHoursExpected: number }> };

export type GetExpertProjectQueryVariables = Exact<{
  legacyFreelancerId: Scalars['Int'];
}>;


export type GetExpertProjectQuery = { getExpertProject: Array<{ billType: number, clientName: string, startDate: any, endDate: any, projectName: string, payRate: number, frequency: string, status: string, projectId: number }> };

export type GetProposalEstimatesQueryVariables = Exact<{
  freelancerId: Scalars['Int'];
}>;


export type GetProposalEstimatesQuery = { getProposalEstimates: Array<{ proposalId: number, freelancerId: number, estimatedProposalHours: number, trueProposalHours: number, month: string }> };

export type GetProposedProjectsQueryVariables = Exact<{
  proposalId: InputMaybe<Scalars['Int']>;
  freelancerId: InputMaybe<Scalars['Int']>;
}>;


export type GetProposedProjectsQuery = { getProposedProjects: Array<{ id: number, freelancerRate: number, proposedProjectStage: string, proposal: { id: number, name: string, billRateType: BillRateType, estimatedStartDate: any, estimatedEndDate: any, client: { name: string }, salesRep: { name: string }, accountManager: { name: string } } }> };

export type GetSalesRepsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSalesRepsQuery = { getSalesReps: Array<{ __typename: 'SalesRep', id: number, name: string }> };


export const GetClosedParoProjectsDocument = gql`
    query getClosedParoProjects($legacyFreelancerId: Int!) {
  getClosedParoProjects(freelancerId: $legacyFreelancerId) {
    id
    name
    client {
      name
    }
    billRateType
    payRate
    startDate
    endDate
    accountManager {
      name
    }
    salesRep {
      name
    }
    status
  }
}
    `;

/**
 * __useGetClosedParoProjectsQuery__
 *
 * To run a query within a React component, call `useGetClosedParoProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedParoProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedParoProjectsQuery({
 *   variables: {
 *      legacyFreelancerId: // value for 'legacyFreelancerId'
 *   },
 * });
 */
export function useGetClosedParoProjectsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetClosedParoProjectsQuery, GetClosedParoProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetClosedParoProjectsQuery, GetClosedParoProjectsQueryVariables>(GetClosedParoProjectsDocument, options);
      }
export function useGetClosedParoProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClosedParoProjectsQuery, GetClosedParoProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetClosedParoProjectsQuery, GetClosedParoProjectsQueryVariables>(GetClosedParoProjectsDocument, options);
        }
export type GetClosedParoProjectsQueryHookResult = ReturnType<typeof useGetClosedParoProjectsQuery>;
export type GetClosedParoProjectsLazyQueryHookResult = ReturnType<typeof useGetClosedParoProjectsLazyQuery>;
export type GetClosedParoProjectsQueryResult = ApolloReactCommon.QueryResult<GetClosedParoProjectsQuery, GetClosedParoProjectsQueryVariables>;
export const GetFreelancerProfileDocument = gql`
    query getFreelancerProfile($freelancerId: Int!) {
  getFreelancerProfile(freelancerId: $freelancerId) {
    projectWinRate
    availableHours
  }
}
    `;

/**
 * __useGetFreelancerProfileQuery__
 *
 * To run a query within a React component, call `useGetFreelancerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreelancerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreelancerProfileQuery({
 *   variables: {
 *      freelancerId: // value for 'freelancerId'
 *   },
 * });
 */
export function useGetFreelancerProfileQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetFreelancerProfileQuery, GetFreelancerProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetFreelancerProfileQuery, GetFreelancerProfileQueryVariables>(GetFreelancerProfileDocument, options);
      }
export function useGetFreelancerProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFreelancerProfileQuery, GetFreelancerProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetFreelancerProfileQuery, GetFreelancerProfileQueryVariables>(GetFreelancerProfileDocument, options);
        }
export type GetFreelancerProfileQueryHookResult = ReturnType<typeof useGetFreelancerProfileQuery>;
export type GetFreelancerProfileLazyQueryHookResult = ReturnType<typeof useGetFreelancerProfileLazyQuery>;
export type GetFreelancerProfileQueryResult = ApolloReactCommon.QueryResult<GetFreelancerProfileQuery, GetFreelancerProfileQueryVariables>;
export const GetFreelancerAvailabilityDocument = gql`
    query getFreelancerAvailability($freelancerId: Int!, $month: String!) {
  getFreelancerAvailability(freelancerId: $freelancerId, month: $month) {
    freelancerId
    freelancerName
    availability
    estimatedProjectHours
    goal
    booked
    estimatedProposalHours
    pending
    estimatedAvailability
    monthlyHoursAvailable
    month
  }
}
    `;

/**
 * __useGetFreelancerAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetFreelancerAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreelancerAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreelancerAvailabilityQuery({
 *   variables: {
 *      freelancerId: // value for 'freelancerId'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useGetFreelancerAvailabilityQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetFreelancerAvailabilityQuery, GetFreelancerAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetFreelancerAvailabilityQuery, GetFreelancerAvailabilityQueryVariables>(GetFreelancerAvailabilityDocument, options);
      }
export function useGetFreelancerAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFreelancerAvailabilityQuery, GetFreelancerAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetFreelancerAvailabilityQuery, GetFreelancerAvailabilityQueryVariables>(GetFreelancerAvailabilityDocument, options);
        }
export type GetFreelancerAvailabilityQueryHookResult = ReturnType<typeof useGetFreelancerAvailabilityQuery>;
export type GetFreelancerAvailabilityLazyQueryHookResult = ReturnType<typeof useGetFreelancerAvailabilityLazyQuery>;
export type GetFreelancerAvailabilityQueryResult = ApolloReactCommon.QueryResult<GetFreelancerAvailabilityQuery, GetFreelancerAvailabilityQueryVariables>;
export const GetParoProjectsForAvailabilityDocument = gql`
    query getParoProjectsForAvailability($legacyFreelancerId: Int!) {
  getParoProjects(freelancerId: $legacyFreelancerId) {
    id
    name
    client {
      name
    }
    billRateType
    payRate
    startDate
    endDate
    accountManager {
      name
    }
    salesRep {
      name
    }
    status
  }
}
    `;

/**
 * __useGetParoProjectsForAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetParoProjectsForAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParoProjectsForAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParoProjectsForAvailabilityQuery({
 *   variables: {
 *      legacyFreelancerId: // value for 'legacyFreelancerId'
 *   },
 * });
 */
export function useGetParoProjectsForAvailabilityQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetParoProjectsForAvailabilityQuery, GetParoProjectsForAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetParoProjectsForAvailabilityQuery, GetParoProjectsForAvailabilityQueryVariables>(GetParoProjectsForAvailabilityDocument, options);
      }
export function useGetParoProjectsForAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParoProjectsForAvailabilityQuery, GetParoProjectsForAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetParoProjectsForAvailabilityQuery, GetParoProjectsForAvailabilityQueryVariables>(GetParoProjectsForAvailabilityDocument, options);
        }
export type GetParoProjectsForAvailabilityQueryHookResult = ReturnType<typeof useGetParoProjectsForAvailabilityQuery>;
export type GetParoProjectsForAvailabilityLazyQueryHookResult = ReturnType<typeof useGetParoProjectsForAvailabilityLazyQuery>;
export type GetParoProjectsForAvailabilityQueryResult = ApolloReactCommon.QueryResult<GetParoProjectsForAvailabilityQuery, GetParoProjectsForAvailabilityQueryVariables>;
export const GetProjectEstimatesDocument = gql`
    query getProjectEstimates($freelancerId: Int!) {
  getProjectEstimates(freelancerId: $freelancerId) {
    projectId
    freelancerId
    projectName
    clientName
    endDate
    estimatedProjectHours
    startDate
    trueProjectHours
    month
    projectActualHours
    totalMonthHoursExpected
  }
}
    `;

/**
 * __useGetProjectEstimatesQuery__
 *
 * To run a query within a React component, call `useGetProjectEstimatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectEstimatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectEstimatesQuery({
 *   variables: {
 *      freelancerId: // value for 'freelancerId'
 *   },
 * });
 */
export function useGetProjectEstimatesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetProjectEstimatesQuery, GetProjectEstimatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetProjectEstimatesQuery, GetProjectEstimatesQueryVariables>(GetProjectEstimatesDocument, options);
      }
export function useGetProjectEstimatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectEstimatesQuery, GetProjectEstimatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetProjectEstimatesQuery, GetProjectEstimatesQueryVariables>(GetProjectEstimatesDocument, options);
        }
export type GetProjectEstimatesQueryHookResult = ReturnType<typeof useGetProjectEstimatesQuery>;
export type GetProjectEstimatesLazyQueryHookResult = ReturnType<typeof useGetProjectEstimatesLazyQuery>;
export type GetProjectEstimatesQueryResult = ApolloReactCommon.QueryResult<GetProjectEstimatesQuery, GetProjectEstimatesQueryVariables>;
export const GetExpertProjectDocument = gql`
    query getExpertProject($legacyFreelancerId: Int!) {
  getExpertProject(freelancerId: $legacyFreelancerId) {
    billType
    clientName
    startDate
    endDate
    projectName
    payRate
    frequency
    status
    projectId
  }
}
    `;

/**
 * __useGetExpertProjectQuery__
 *
 * To run a query within a React component, call `useGetExpertProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpertProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpertProjectQuery({
 *   variables: {
 *      legacyFreelancerId: // value for 'legacyFreelancerId'
 *   },
 * });
 */
export function useGetExpertProjectQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetExpertProjectQuery, GetExpertProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExpertProjectQuery, GetExpertProjectQueryVariables>(GetExpertProjectDocument, options);
      }
export function useGetExpertProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExpertProjectQuery, GetExpertProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExpertProjectQuery, GetExpertProjectQueryVariables>(GetExpertProjectDocument, options);
        }
export type GetExpertProjectQueryHookResult = ReturnType<typeof useGetExpertProjectQuery>;
export type GetExpertProjectLazyQueryHookResult = ReturnType<typeof useGetExpertProjectLazyQuery>;
export type GetExpertProjectQueryResult = ApolloReactCommon.QueryResult<GetExpertProjectQuery, GetExpertProjectQueryVariables>;
export const GetProposalEstimatesDocument = gql`
    query getProposalEstimates($freelancerId: Int!) {
  getProposalEstimates(freelancerId: $freelancerId) {
    proposalId
    freelancerId
    estimatedProposalHours
    trueProposalHours
    month
  }
}
    `;

/**
 * __useGetProposalEstimatesQuery__
 *
 * To run a query within a React component, call `useGetProposalEstimatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalEstimatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalEstimatesQuery({
 *   variables: {
 *      freelancerId: // value for 'freelancerId'
 *   },
 * });
 */
export function useGetProposalEstimatesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetProposalEstimatesQuery, GetProposalEstimatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetProposalEstimatesQuery, GetProposalEstimatesQueryVariables>(GetProposalEstimatesDocument, options);
      }
export function useGetProposalEstimatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProposalEstimatesQuery, GetProposalEstimatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetProposalEstimatesQuery, GetProposalEstimatesQueryVariables>(GetProposalEstimatesDocument, options);
        }
export type GetProposalEstimatesQueryHookResult = ReturnType<typeof useGetProposalEstimatesQuery>;
export type GetProposalEstimatesLazyQueryHookResult = ReturnType<typeof useGetProposalEstimatesLazyQuery>;
export type GetProposalEstimatesQueryResult = ApolloReactCommon.QueryResult<GetProposalEstimatesQuery, GetProposalEstimatesQueryVariables>;
export const GetProposedProjectsDocument = gql`
    query getProposedProjects($proposalId: Int, $freelancerId: Int) {
  getProposedProjects(proposalId: $proposalId, freelancerId: $freelancerId) {
    id
    proposal {
      id
      name
      client {
        name
      }
      billRateType
      estimatedStartDate
      estimatedEndDate
      salesRep {
        name
      }
      accountManager {
        name
      }
    }
    freelancerRate
    proposedProjectStage
  }
}
    `;

/**
 * __useGetProposedProjectsQuery__
 *
 * To run a query within a React component, call `useGetProposedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposedProjectsQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *      freelancerId: // value for 'freelancerId'
 *   },
 * });
 */
export function useGetProposedProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProposedProjectsQuery, GetProposedProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetProposedProjectsQuery, GetProposedProjectsQueryVariables>(GetProposedProjectsDocument, options);
      }
export function useGetProposedProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProposedProjectsQuery, GetProposedProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetProposedProjectsQuery, GetProposedProjectsQueryVariables>(GetProposedProjectsDocument, options);
        }
export type GetProposedProjectsQueryHookResult = ReturnType<typeof useGetProposedProjectsQuery>;
export type GetProposedProjectsLazyQueryHookResult = ReturnType<typeof useGetProposedProjectsLazyQuery>;
export type GetProposedProjectsQueryResult = ApolloReactCommon.QueryResult<GetProposedProjectsQuery, GetProposedProjectsQueryVariables>;
export const GetSalesRepsDocument = gql`
    query getSalesReps {
  getSalesReps {
    id
    name
    __typename
  }
}
    `;

/**
 * __useGetSalesRepsQuery__
 *
 * To run a query within a React component, call `useGetSalesRepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesRepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesRepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSalesRepsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesRepsQuery, GetSalesRepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSalesRepsQuery, GetSalesRepsQueryVariables>(GetSalesRepsDocument, options);
      }
export function useGetSalesRepsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesRepsQuery, GetSalesRepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSalesRepsQuery, GetSalesRepsQueryVariables>(GetSalesRepsDocument, options);
        }
export type GetSalesRepsQueryHookResult = ReturnType<typeof useGetSalesRepsQuery>;
export type GetSalesRepsLazyQueryHookResult = ReturnType<typeof useGetSalesRepsLazyQuery>;
export type GetSalesRepsQueryResult = ApolloReactCommon.QueryResult<GetSalesRepsQuery, GetSalesRepsQueryVariables>;