import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import GrainIcon from "@material-ui/icons/Grain";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Button as BaseUiButton } from "@paro.io/base-ui";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    // color: "white",
    // "&:hover": { color: "white" },
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  Breadcrumbs: {
    background: "transparent",
    // padding: theme.spacing(2),
    padding: theme.spacing(2, 5, 2, 5),
  },
}));

export default function BreadCrumbsBar(props) {
  const history = useHistory();
  const { proposalId, freelancerId, checkout, showFullProfile, goBack } = props;
  const classes = useStyles();

  return (
    <Grid container justify="flex-start" className={classes.Breadcrumbs}>
      <Grid item xs={6}>
        <Breadcrumbs aria-label="breadcrumb">
          {proposalId && (
            <Link
              color="inherit"
              onClick={() => history.push(`/`)}
              className={classes.link}
            >
              <HomeIcon className={classes.icon} />
              Home
            </Link>
          )}
          {proposalId && !freelancerId && !checkout && (
            <Typography color="textPrimary" className={classes.link}>
              <GrainIcon className={classes.icon} />
              Proposal: {proposalId}
            </Typography>
          )}
          {/* TODO freelancer page  */}
          {proposalId && (freelancerId || checkout) && (
            <Link
              color="inherit"
              onClick={() =>
                // history.push(
                //   `/${faf ? `find-a-freelancer` : `proposal`}/${proposalId}`
                // )
                history.goBack()
              }
              className={classes.link}
            >
              <GrainIcon className={classes.icon} />
              Proposal: {proposalId}
            </Link>
          )}
          {freelancerId && (
            <Typography color="textPrimary" className={classes.link}>
              <WhatshotIcon className={classes.icon} />
              Freelancer: {freelancerId}
            </Typography>
          )}
          {checkout && !showFullProfile && (
            <Typography color="textPrimary" className={classes.link}>
              Checkout
            </Typography>
          )}
          {/*   {scorecard && (
            <Typography color="textPrimary" className={classes.link}>
              ScoreCard
            </Typography>
          )} */}
          {showFullProfile && (
            <div>
              <BaseUiButton label="Back" onClick={goBack} color="primary" />
            </div>
          )}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}
