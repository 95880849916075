import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import FreelancerTable from "./Table";
import FreelancerSearchForm from "./SearchForm";

/**-----------algolia config----------*/
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
);
const Freelancers_list =
  process.env.REACT_APP_ALGOLIA_FREELANCERS_LIST_INDEX_NAME ??
  "freelancer-list-dev";
const index = searchClient.initIndex(Freelancers_list);
/**------------------------------------- */

const FAF_HEADINGS = {
  state: "States",
  industryTags: "Industries",
  serviceLine: "Service Lines",
  servicelinePlus: "Service Lines Plus",
  financialTags: "Financial Tasks",
};

const REQUIRED_FACETS = [
  "serviceLine",
  "servicelinePlus",
  "softwareTags",
  "financialTags",
  "industryTags",
  "state",
];

const FreelancerInstantSearch = ({ proposalId, proposalTags }) => {
  const [facets, setFacets] = useState([]);

  const getFaFTagHeadings = (name) => {
    return FAF_HEADINGS[name];
  };

  useEffect(() => {
    index
      .search("", {
        facets: REQUIRED_FACETS,
      })
      .then((res) => {
        const allFacets = [];
        Object.entries(res.facets).forEach(([key, value]) => {
          const keys = Object.keys(value);
          keys.forEach((k) => {
            allFacets.push({ groupName: getFaFTagHeadings(key), title: k });
          });
        });
        setFacets(allFacets);
      })
      .catch(console.log);
  }, []);

  return (
    <InstantSearch searchClient={searchClient} indexName={Freelancers_list}>
      <div className=" bg-white md:px-12 px-4 pt-8 flex flex-col justify-center w-full">
        <FreelancerSearchForm
          proposalId={proposalId}
          facets={facets}
          proposalTags={proposalTags}
        />
        <FreelancerTable proposalId={proposalId} />
      </div>
    </InstantSearch>
  );
};

export default FreelancerInstantSearch;
