import React from 'react'
import omit from 'lodash/omit'
import styled from 'styled-components'

import colors from '../shared/constants/colors'
export const StyledIcon = styled((props) => {
  return <span {...omit(props, StyledIcon.OmitProps)} />
})`
  align-items: center;
  color: ${colors.paroDeepblue};
  display: flex;
  font-size: 19px;
  margin-right: 8px;

`
