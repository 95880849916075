import React, { useContext, useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { Route } from "react-router-dom"

import { Auth0Context } from "../contexts/Auth0Context"

export const AuthenticatedRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, user, logout =()=>{} } =
    useContext(Auth0Context)

  useEffect(() => {
    if (user?.userTypeId && user?.userTypeId !== 2) {
      logout()
    }
    if (loading || isAuthenticated) {
      return
    }
    const login = async () => {
      return await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      })
    }
    login()
  }, [isAuthenticated, loading, loginWithRedirect, logout, user?.userTypeId])

  const render = (props) => {
    return isAuthenticated ? (
      <Component {...props} />
    ) : (
      <Spinner animation="border" />
    )
  }

  return <Route path={path} render={render} {...rest} />
}
