import React from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core/'

interface LoadingProps {
  inGrid?: boolean
  size?: string
  text?: string
}

export const Loading: React.FC<LoadingProps> = ({ inGrid, size = '26px', text }: LoadingProps) => {
  if (inGrid) {
    return <CircularProgress size={size} />
  }

  return (
    <Grid container spacing={1} alignItems='center' justify='center' direction='column'>
      <Grid container item justify='center'>
        <CircularProgress size={size} />
      </Grid>
      {text && (
        <Grid item>
          <Typography variant='h6'>{text}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default Loading
