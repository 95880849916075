import { useContext, useState } from "react";
import { usePagination, useHits } from "react-instantsearch";
import { CartContext } from "../CartContext";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Button as BaseUiButton } from "@paro.io/base-ui";
import { Spinner } from "react-bootstrap";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Auth0Context } from "../../contexts/Auth0Context";
import {
  addFindFreelancersToCartFn,
  removeFindFlFromCartFn,
} from "../../services/apiService";
import FreelancerPagination from "./Pagination";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

const Freelancer = ({ hit, user, proposalId }) => {
  const [loader, setLoader] = useState(false);
  const [cart, setCart] = useContext(CartContext).cartContext;
  const { enqueueSnackbar } = useSnackbar();

  const onChangeHandler = async (e, freelancer) => {
    e.stopPropagation();
    setLoader(true);
    if (!cart.some((c) => c.id === freelancer.freelancerId)) {
      await addFindFreelancersToCartFn({
        proposalId: +proposalId,
        userId: user.auth0UserId,
        findFreelancerIds: [freelancer.freelancerId],
      });
      setCart((cart) => [
        ...cart,
        {
          ...freelancer,
          flID: freelancer.freelancerId,
          id: freelancer.freelancerId,
        },
      ]);
      enqueueSnackbar(`Added ${freelancer.firstName} to Cart!`, {
        variant: "success",
      });
    } else {
      await removeFindFlFromCartFn({
        proposalId: +proposalId,
        userId: user.auth0UserId,
        freelancerId: freelancer.freelancerId,
      });
      setCart((currentState) =>
        currentState.filter((c) => c.flID !== freelancer.freelancerId)
      );
      enqueueSnackbar(`Removed ${freelancer.firstName} from Cart!`, {
        variant: "warning",
      });
    }
    setLoader(false);
  };

  return (
    <tr key={hit.freelancerId}>
      <td className="border px-4 py-2 text-sm text-center">
        {loader ? (
          <Spinner animation="grow" size="sm" className="text-info" />
        ) : (
          <input
            id="mm-faf-selectFreelancer"
            type="checkbox"
            className="form-checkbox h-6 w-5 bg-gray-200"
            onChange={(e) => onChangeHandler(e, hit)}
            checked={cart.some((c) => c?.id === hit.freelancerId)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </td>
      <td
        className="border px-4 py-2 text-sm hover:text-indigo-900 cursor-pointer"
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_PARO_APP_URL}/internal/expert-profile/${hit.freelancerId}`,
            "_blank"
          );
        }}
      >
        {hit.firstName} {hit?.lastName}
      </td>
      <td className="border px-4 py-2 text-sm">{hit.serviceLine}</td>
      <td className="border px-4 py-2 text-sm">-</td>
      <td className="border px-4 py-2 text-sm">00 | 00 | 00</td>
      <td className="border px-4 py-2 text-sm">{hit.availableHours} hrs</td>
      <td className="border px-4 py-2 text-sm">{hit.state}</td>
      <td className="border px-4 py-2 text-sm">${hit.billRate}</td>
      <td className="border px-4 py-2 text-sm">
        {" "}
        <div className="text-center">
          <div
            className="bg-gray-200 w-10 p-2 hover:text-indigo-900 cursor-pointer"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_PARO_APP_URL}/internal/am/findfreelancers/jsonview/${hit.freelancerId}?navigation=false`,
                "_blank"
              );
            }}
          >
            <OpenInNewIcon />
          </div>
        </div>
      </td>
    </tr>
  );
};

const FreelancerTable = (props) => {
  const { hits } = useHits(props);
  const { nbHits } = usePagination(props);
  const [cart] = useContext(CartContext).cartContext;
  const [sortBy, setSortBy] = useState("firstName");
  const [sortDirection, setSortDirection] = useState("asc");
  const history = useHistory();
  const { user } = useContext(Auth0Context);

  const onClickSubmit = () => {
    if (
      hits.some(
        (f) =>
          f.freelancerStatus === "Not Available for Hire" &&
          cart.some((c) => c.flID === f.id)
      )
    ) {
      //   setUnavailableModal(true);
    } else {
      history.push(`/proposal/${props.proposalId}/checkout`);
    }
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortDirection("asc");
    }
  };

  const sortedData = sortBy
    ? [...hits].sort((a, b) => {
        if (sortDirection === "asc") {
          return a[sortBy] > b[sortBy] ? 1 : -1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      })
    : hits;

  const sortArrows =
    sortDirection === "asc" ? <ArrowUpward /> : <ArrowDownward />;

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex justify-between w-full py-2 items-center">
        <p className="font-bold text-xl">Search Results ({nbHits})</p>
        <div className="flex flex-col justify-center w-auto">
          <div className="flex flex-row justify-end w-full content-end">
            <div className="py-2 font-medium px-4 mx-2">
              {`${cart.length} Freelancer${
                cart.length !== 1 ? `s` : ``
              } Selected`}
            </div>
            <div>
              <span id="mm-faf-checkout">
                {cart?.length < 1 ? (
                  <BaseUiButton
                    color="default"
                    label={"Select at least 1 freelancer"}
                    disabled={cart?.length < 1}
                  />
                ) : (
                  <BaseUiButton
                    color="primary"
                    label={"Cart"}
                    onClick={onClickSubmit}
                  />
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <table className="border border-gray-400 rounded-md w-full">
        <thead>
          <tr className="p-2 text-left text-xs font-medium text-gray-500 tracking-wider">
            <th className="px-4 py-2 border bg-gray-100 text-sm">Select</th>
            <th
              className="px-4 py-2 border bg-gray-100 text-sm"
              onClick={() => handleSort("firstName")}
            >
              Name {sortBy === "firstName" && sortArrows}
            </th>
            <th
              className="px-4 py-2 border bg-gray-100 text-sm"
              onClick={() => handleSort("serviceLine")}
            >
              Service Line {sortBy === "serviceLine" && sortArrows}
            </th>
            <th className="px-4 py-2 border bg-gray-100 text-sm">
              Goal Workload
            </th>
            <th className="px-4 py-2 border bg-gray-100 text-sm">
              Clients (L | C | P)
            </th>
            <th
              className="px-4 py-2 border bg-gray-100 text-sm"
              onClick={() => handleSort("availableHours")}
            >
              Avail. Hrs. {sortBy === "availableHours" && sortArrows}
            </th>
            <th
              className="px-4 py-2 border bg-gray-100 text-sm"
              onClick={() => handleSort("state")}
            >
              State Name {sortBy === "state" && sortArrows}
            </th>
            <th
              className="px-4 py-2 border bg-gray-100 text-sm"
              onClick={() => handleSort("billRate")}
            >
              Client Rate {sortBy === "billRate" && sortArrows}
            </th>
            <th className="px-4 py-2 border bg-gray-100 text-sm">JSON</th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.length > 0 &&
            sortedData.map((hit) => (
              <Freelancer
                hit={hit}
                proposalId={props.proposalId}
                user={user}
                key={hit.freelancerId}
              />
            ))}
        </tbody>
      </table>
      {hits.length > 0 && <FreelancerPagination />}
    </div>
  );
};

export default FreelancerTable;
