import React, { useState } from "react";
import { Button, Text } from "@paro.io/base-ui";
import Select from "react-select";
import { useTags } from "../../contexts/useTags";
import { fastTrackServicesArray } from "../../utils/freelancerSearchService";

const colourStyles = (errors) => ({
  control: (styles) => ({
    ...styles,
    backgroundColor: "#f1f5f9",
    border: errors ? "1px solid #f17175 !important" : "1px solid #ddd",
    ...(errors && { boxShadow: "none" }),
  }),
  multiValue: (styles) => {
    const color = "#fff";
    return {
      ...styles,
      backgroundColor: color,
      color: "black",
      border: "1px solid #ddd",
      borderRadius: "14px",
      textWrap: "wrap",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "black",
    borderRadius: "16px",
    textWrap: "wrap",
  }),
});

const Sidebar = ({
  proposalDetails,
  onRunMatch,
  isMatchLoading,
  setActiveStage,
}) => {
  const { softwareOptions, skillOptions } = useTags();

  const proposal = { ...proposalDetails?.generatedSummary };

  const [selectedSolution, setSelectedSolution] = useState(
    proposal["Required Services"]?.map((solution) => ({
      label: solution,
      value: solution,
    }))
  );
  const [selectedSoftware, setSelectedSoftware] = useState(
    proposal["Required Software"] !== "Not Mentioned"
      ? proposal["Required Software"]?.split(",").map((software) => ({
          label: software.trim(),
          value: software.trim(),
        }))
      : []
  );
  const [selectedSkills, setSelectedSkills] = useState(
    proposal["Required Skills"]?.map((skill) => ({
      label: skill,
      value: skill,
    }))
  );

  const getUniqueOptions = (options) => {
    const uniqueValues = [];
    return options.filter((option) => {
      if (
        uniqueValues.find((uniqueOption) => uniqueOption.value === option.value)
      ) {
        return false;
      } else {
        uniqueValues.push(option);
        return true;
      }
    });
  };

  const combinedOptions = getUniqueOptions([
    ...selectedSolution,
    ...fastTrackServicesArray,
  ]);

  const handleChange = (setter) => (selectedOptions) => {
    setter(selectedOptions || []);
  };

  const handleMatch = () => {
    const payload = {
      notes: proposal["Proposal Description"],
      software:
        selectedSoftware?.map((software) => software.value).join(", ") || "",
      fasttrackServices: proposal["Required Services"]?.join(", ") || "",
    };
    onRunMatch(payload);
  };
  return (
    <div>
      <div
        className={`border rounded-md bg-white px-3 `}
        style={{
          maxHeight: "79vh",
          overflowX: "hidden",
        }}
      >
        <div>
          <Text>Solution</Text>
          <Select
            options={combinedOptions}
            isMulti
            styles={colourStyles()}
            isClearable={false}
            value={selectedSolution}
            onChange={handleChange(setSelectedSolution)}
          />
        </div>
        <div className="mt-3">
          <Text>Software</Text>
          <Select
            options={softwareOptions.map((software) => ({
              label: software,
              value: software,
            }))}
            isMulti
            isClearable={false}
            styles={colourStyles()}
            value={selectedSoftware}
            onChange={handleChange(setSelectedSoftware)}
          />
        </div>
        <div className="mt-3 mb-4">
          <Text>Skills</Text>
          <Select
            options={skillOptions.map((skill) => ({
              label: skill,
              value: skill,
            }))}
            isMulti
            styles={colourStyles()}
            isClearable={false}
            value={selectedSkills}
            onChange={handleChange(setSelectedSkills)}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mt-3">
        <Button
          label="Back"
          onClick={() => setActiveStage(0)}
          size="md"
        ></Button>
        <Button
          label="match"
          size="md"
          onClick={handleMatch}
          isLoading={isMatchLoading}
        />
      </div>
    </div>
  );
};

export default Sidebar;
