import { useState } from "react";
import { RangeInput } from "react-instantsearch";
import Close from "@material-ui/icons/Close";

import FreelancerMenu from "./Menu";
import FreelancerSelect from "./SeachableSelect";
import { Chip } from "@material-ui/core";
import { FAF_GROUPS } from "../../data/appConstants";

const FreelancerSearchForm = ({ facets }) => {
  const [tags, setTags] = useState([]);
  const [refItem, setRefItem] = useState({});

  return (
    <>
      <div className="flex items-center mx-auto w-full gap-4">
        <div>
          <p>Status</p>
          <FreelancerMenu />
        </div>
        <div className="ms:flex items-center  mx-auto w-1/2">
          <p>Search</p>
          <FreelancerSelect
            facets={facets}
            setTags={setTags}
            tags={tags}
            refItem={refItem}
          />
        </div>
        <div>
          <p>Available Hours</p>
          <RangeInput
            classNames={{
              root: "border rounded flex items-center",
              input: "border-r border-l p-4",
              submit: "bg-gray-200 p-4",
              separator: "mx-4",
              form: "flex items-center",
            }}
            placeholder="minHours"
            attribute="availableHours"
          />
        </div>
        <div>
          <p>Hourly Rate $</p>
          <RangeInput
            classNames={{
              root: "border rounded flex items-center",
              input: "border-r border-l p-4",
              submit: "bg-gray-200 p-4",
              separator: "mx-4",
              form: "flex items-center",
            }}
            attribute="hourlyRate"
          />
        </div>
      </div>
      <div className="flex items-start mx-auto py-4 justify-start w-full">
        <div className="m-1 flex flex-flow-wrap">
          {tags.length > 0 && <div className="font-bold p-1">Tags:</div>}
          {tags?.map((option, index) => (
            <div key={index} className="p-1">
              <Chip
                variant="outlined"
                label={
                  <p>
                    {!!FAF_GROUPS[option.groupName]
                      ? `${option.groupName}: ${option.title}`
                      : `"${option.title}"`}
                    <span
                      onClick={(e) => {
                        const temp = [...tags];
                        const filteredTemp = temp.filter(
                          (obj) => obj.inputValue !== option.inputValue
                        );
                        setRefItem({ ...option, isDelete: true });
                        setTags(filteredTemp);
                        e.stopPropagation();
                      }}
                      className="ml-1 text-gray-500"
                    >
                      <Close fontSize="small" />
                    </span>
                  </p>
                }
                className={option.required ? `font-bold` : `font-light`}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FreelancerSearchForm;
