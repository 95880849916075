import { useEffect } from "react";
import { useRefinementList, useSearchBox } from "react-instantsearch";
import MultiSelectFaFField from "../MultiSelectFaFField";

const FAF_HEADINGS = {
  state: "States",
  industryTags: "Industries",
  serviceLine: "Service Lines",
  servicelinePlus: "Service Lines Plus",
  financialTags: "Financial Tasks",
};

const FreelancerSelect = ({ setTags, facets, tags, refItem, ...props }) => {
  const { refine, clear } = useSearchBox(props);
  const { refine: refineServiceLine } = useRefinementList({
    attribute: "serviceLine",
  });
  const { refine: refineServiceLinePlus } = useRefinementList({
    attribute: "servicelinePlus",
  });
  const { refine: refineFinancialTasks } = useRefinementList({
    attribute: "financialTags",
  });
  const { refine: refineIndustryTasks } = useRefinementList({
    attribute: "industryTags",
  });
  const { refine: refineState } = useRefinementList({ attribute: "state" });
  const allTagValues = tags?.map((tag) => tag.title);

  const refineList = (listName, value, isDelete) => {
    switch (listName) {
      case "state":
        refineState(value);
        break;
      case "serviceLine":
        refineServiceLine(value);
        break;
      case "servicelinePlus":
        refineServiceLinePlus(value);
        break;
      case "financialTags":
        refineFinancialTasks(value);
        break;
      case "industryTags":
        refineIndustryTasks(value);
        break;
      default:
        if (isDelete) {
          clear();
        } else {
          refine(value);
        }
        break;
    }
  };

  const getObjectKey = (obj, value) => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };

  useEffect(() => {
    if (refItem?.title?.trim()) {
      refineList(
        getObjectKey(FAF_HEADINGS, refItem.groupName),
        refItem.title,
        refItem?.isDelete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refItem]);

  return (
    <MultiSelectFaFField
      options={facets}
      placeholder="Search"
      onChange={(e) => {
        if (
          !allTagValues.includes(e[0].title) &&
          e[0]?.title?.trim()?.length > 0
        ) {
          refineList(
            getObjectKey(FAF_HEADINGS, e[0].groupName),
            e[0].title?.trim()
          );
          setTags([...tags, ...e]);
        }
      }}
    />
  );
};

export default FreelancerSelect;
