import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Sentry from "@sentry/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import history from "./history";
import { config } from "./contexts/service";
import { Auth0Provider } from "./contexts/Auth0Context";
import { CartProvider } from "./components/CartContext";
import { SnackbarProvider } from "notistack";
import { Slide } from "@material-ui/core";
import { datadogRum } from "@datadog/browser-rum";
import AuthorizedApolloClient from "./apolloClient";
import { MatchResultsProvider } from "./contexts/MatchResultsContext";

datadogRum.init({
  applicationId: "ea8e45a1-0ae9-42b2-99fc-adde29b5b5fe",
  clientToken: "pub85a13cab6386c51baee3c3e2abf3bec0",
  site: "datadoghq.com",
  service: "matchmaking",
  env: process.env.REACT_APP_ENVIRONMENT_STAGE,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
});

// Sentry.init({
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  beforeSend(event) {
    const acceptableErrors = ["ResizeObserver loop limit exceeded"];
    if (
      acceptableErrors.some((acceptableError) =>
        event?.exception?.values?.some((value) =>
          value?.value?.includes(acceptableError)
        )
      )
    ) {
      return null;
    }
    return event;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={config.redirectUri}
    >
      <AuthorizedApolloClient>
        <Router history={history}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            TransitionComponent={Slide}
            autoHideDuration={2000}
          >
            <MatchResultsProvider>
              <CartProvider>
                <App />
              </CartProvider>
            </MatchResultsProvider>
          </SnackbarProvider>
        </Router>
      </AuthorizedApolloClient>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
