import { Grid, Typography } from "@material-ui/core";
import { IconExternalLink } from "@paro.io/base-icons";
import { Button } from "@paro.io/base-ui";
import React from "react";
import { SectionBody } from "./tabs/Availability/SectionBody";
import styled from "styled-components";
import { Tags } from "../styles/Tags";

const ProfileTags = styled(Tags)`
  margin: 0 5px 8px 0;
  background-color: white;
  border: 1px solid #19a79e;
`;
type SectionContents = {
  serviceLinesPlus: string[];
  freelancerId: string | number;
};
export default function ServiceLinesPlus({
  serviceLinesPlus,
  freelancerId,
}: SectionContents) {
  return (
    <SectionBody className="my-2">
      <Grid container>
        <Grid item xs={9} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={"h6"}>Service Line Plus</Typography>
          </Grid>
          <Grid item>
            {serviceLinesPlus?.length > 0 ? (
              <>
                {(serviceLinesPlus || [])?.map((tag, index) => {
                  return <ProfileTags key={index} label={tag} size="medium" />;
                })}
              </>
            ) : (
              <>NA</>
            )}
          </Grid>
        </Grid>
        <Grid item xs={3} className="flex justify-center">
          <span id="mm-view-internalPortal">
            <Button
              label="VIEW INTERNAL PROFILE"
              iconLeft={<IconExternalLink />}
              size="sm"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_PARO_APP_URL}/internal/expert-profile/${freelancerId}?tab=1`,
                  "_blank"
                );
              }}
            />
          </span>
        </Grid>
      </Grid>
    </SectionBody>
  );
}
