import React, { createContext, useState } from "react";

export const MatchContext = createContext();

export const MatchProvider = (props) => {
  const [matchInputs, setMatchInputs] = useState([]);

  return (
    <MatchContext.Provider
      value={{
        matchInputsContext: [matchInputs, setMatchInputs],
      }}
    >
      {props.children}
    </MatchContext.Provider>
  );
};
