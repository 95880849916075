import React from "react";
import MultiSelectField from "./MultiSelectField";

export default function SelectSoftware({ options, ...field }) {
  const taxOptions = [
    "CCH Axcess",
    "CCH Prosystem fxATX",
    "TaxWise",
    "TaxSlayer",
    "GoSystem RS",
    "Tax Analysts",
    "ATX",
    "CaseWare",
  ];

  return (
    <MultiSelectField
      {...field}
      options={[`None`, ...[...new Set([...taxOptions, ...options])].sort()]}
    />
  );
}
