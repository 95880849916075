import React, { useEffect, useState } from "react";
import {
  getProposalEfforts,
  getProposalTags,
  getServiceLinesPlus,
} from "../services/apiService";
import MultiSelectFaFField from "./MultiSelectFaFField";

const formatOptions = (label, values) => {
  return [...new Set([...values])].map((s) => ({
    groupName: label,
    title: s,
  }));
};

export default function SelectFaFTags({ opportunityType, ...field }) {
  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    const promiseOptions = async () => {
      const responses = await Promise.all([
        getProposalEfforts(),
        getProposalTags(),
        getServiceLinesPlus(),
      ]);

      const serviceOptions = responses[0].data.data.getServiceLines.map(
        (s) => ({ groupName: "Service Lines", title: s })
      );
      setTagOptions((t) => [...t, ...serviceOptions]);

      if (process.env.REACT_APP_FAF_SLP_ENABLED === "true") {
        const serviceLinePlusOptions =
          responses[2].data.data.getServiceLinesPlus.map((s) => ({
            groupName: "Service Lines Plus",
            title: s.value
          }));

        setTagOptions((t) => [...t, ...serviceLinePlusOptions]);
      }

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "Softwares",
          responses[1].data.data.getTags.software.values
        ),
      ]);

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "Industries",
          responses[1].data.data.getTags.industries.values
        ),
      ]);

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "Skills",
          responses[1].data.data.getTags.skills.values
        ),
      ]);

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "Financial Tasks",
          responses[1].data.data.getTags.financialTasks.values
        ),
      ]);

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "Certificates",
          responses[1].data.data.getTags.certificates.values
        ),
      ]);

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "States",
          responses[1].data.data.getTags.states.values
        ),
      ]);
    };
    promiseOptions();
  }, [opportunityType]);

  return <MultiSelectFaFField {...field} creatable options={tagOptions} />;
}
