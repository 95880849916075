import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  updateProposalMatchCount,
  updateCartCheckout,
} from "../services/apiService";
import { Auth0Context } from "../contexts/Auth0Context";
import { CartContext } from "./CartContext";

const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    "&> button": {
      padding: theme.spacing(0.75),
      minWidth: theme.spacing(5),
    },
  },
}));

export default function ReactivateFeedbackForm({
  proposalId,
  setProposalDisabled,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { cartContext, globalMatchCartContext } = useContext(CartContext);
  const [, setCart] = cartContext;
  const [, setMatchIds] = globalMatchCartContext;

  const { user } = useContext(Auth0Context);

  const onSubmitFn = () => {
    try {
      updateProposalMatchCount(proposalId, user.auth0UserId, "reset");
      updateCartCheckout(proposalId, user.auth0UserId, true);
      setCart([]);
      setMatchIds([]);
      setProposalDisabled(false);
      enqueueSnackbar(`Proposal ${proposalId} has been reactivated!`, {
        variant: "success",
      });
    } catch (_) {
      enqueueSnackbar(`Unable to reactivate Proposal ${proposalId}!`, {
        variant: "error",
      });
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.buttonDiv}>
      <Tooltip title="Reactivate Proposal Card" aria-label="reactivate">
        <Button
          onClick={onSubmitFn}
          id="mm-proposal-reactivate"
          variant="contained"
          color="primary"
          size="small"
        >
          Re-Activate
        </Button>
      </Tooltip>
    </div>
  );
}
