/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloError } from '@apollo/client/errors/ApolloError'
import { ErrorResponse } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import isEqual from 'lodash/isEqual'

import { Auth0User } from '../contexts/Auth0Context'

export const isObjectEmpty = (obj: Record<string, any>): boolean => {
  return JSON.stringify(obj) === '{}'
}

const isNullOrUndefined = (item: string | null | number | undefined | boolean): boolean => {

  if (typeof item === 'boolean') {
    item = true
  }
  if (item === 0) {
    return true
  }
  return !item
}

export const areAllItemsNull = (array: Array<string | null | number | undefined | boolean>): boolean => {
  return array.every(isNullOrUndefined)
}
export const submitForm = async (
  submittedFormInput: Record<string, unknown>,
  originalFormInput: Record<string, unknown>,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mutation,
  expertId: string,
  closeModal = (submittedFormInput?) => {
    return submittedFormInput
  }
): Promise<ApolloError | undefined | void>  =>  {
  const inputChanged = !isEqual(submittedFormInput, originalFormInput)
  if (inputChanged) {
    if (!submittedFormInput.id) {
      delete submittedFormInput.id
    }
    return mutation({
      variables: {
        expertId,
        input: submittedFormInput
      }
    })
      .then(() => {
        const updatedInput = submittedFormInput?.certificates ? submittedFormInput.certificates :
          submittedFormInput?.languages ? submittedFormInput.languages : submittedFormInput
        return closeModal(updatedInput)
      })
      .catch((error) => {
        return error
      })
  } else {
    return closeModal()
  }
}

export const manuallyCaptureSentryError = (type: string, message: string, context: Record<string, unknown>, user: Auth0User): void => {
  Sentry.withScope(function(scope) {
    scope.setUser(user)
    Sentry.captureEvent({
      message: message,
      exception: {
        values: [
          { type: type, value: message }
        ]
      },
      contexts: { context }
    })
  })
}
const acceptableErrors = [
  'ProfileReview Not Found',
  'Not Found: ProjectEstimates',
  'Not Found: ProposalEstimates',
  'Not Found: FreelancerAvailability'
]

export const handleErrors = ({ graphQLErrors, networkError, operation }: ErrorResponse, user: Auth0User): void => {
  const context = {
    variables: JSON.stringify(operation?.variables),
    operationName: operation?.operationName
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (!acceptableErrors.some(acceptableError => message?.includes(acceptableError))) {
        manuallyCaptureSentryError('GraphqlError', message, context, user)
      }
    })
  }
  if (networkError) {
    manuallyCaptureSentryError('NetworkError', JSON.stringify(networkError), context, user)
  }
}

export const WHITE_LABEL_TOGGLE_ACCESS = [
  6,
  29633,
  32908
]

export const isAtLeastOneValueNull = (array: Array<string | null | number | undefined | boolean>): boolean => {
  return array.some(isNullOrUndefined)
}

export const filterByDuplicateIds = (workExperiences: Partial<WorkExperience>[]) => {
  return workExperiences.filter(
    (workExperience, index, iteratedWorkExperiences) => {
      return iteratedWorkExperiences.findIndex(
        (comparisonWorkExperience) => {
          return comparisonWorkExperience.id === workExperience.id
        }
      ) === index
    }
  )
}

export const isOnClickEvent = (potentialEvent): boolean => {
  return potentialEvent?.type === 'mouseup' || potentialEvent?.type === 'keydown' || potentialEvent?.type === 'click'
}

export const milesToMetres = (val) => {
  return Math.round(val * 1.60934 * 1000);
};

export const metresToMiles = ( val) => {
  return Math.round(val * 0.000621371)
}