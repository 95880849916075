import React from 'react'
import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import currency from 'currency.js'
import dayjs from 'dayjs'

import Loader from '../../../_global/Loader'
import { useGetExpertProjectQuery } from '../../../apolloClient/hooks/queries'
import { ProjectMenu } from '../../ProjectMenu'

const useStyles = makeStyles({
  tableCell: {
    whiteSpace: 'nowrap'
  },
  tableHead: {
    maxWidth: 125,
    minWidth: 125,
    padding: 0,
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    textAlign: 'center'
  },
  tableRow: {
    height: 40
  },
  container: {
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 50,
    padding: 10,
    margin: 10
  }
})

export const ProjectsTable = (props) => {
  const { legacyFreelancerId } = props
  const classes = useStyles()
  const { data, loading, error } = useGetExpertProjectQuery({
    variables: {
      legacyFreelancerId
    }
  })

  if (loading || !data) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  if (error) {
    return <div>{error}</div>
  }

  const { getExpertProject: projects } = data
  if (projects.length <= 0) {
    return <div>No projects available</div>
  }
  return (
    <Paper className={classes.container}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <b>Client</b>
            </TableCell>
            <TableCell>
              <b>Start Date</b>
            </TableCell>
            <TableCell>
              <b>End Date</b>
            </TableCell>
            <TableCell>
              <b>Project Name</b>
            </TableCell>
            <TableCell align='right'>
              <b>Expert Rate</b>
            </TableCell>
            <TableCell>
              <b>Project Frequency</b>
            </TableCell>
            <TableCell>
              <b>Bill Type</b>
            </TableCell>
            <TableCell>
              <b>Status</b>
            </TableCell>
            <TableCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects?.map((row, index) => {
            const { billType, clientName, frequency, payRate, projectName, startDate, endDate, status, projectId } = row
            const billTypeLabel = billType === 1 ? 'Hourly' : 'Fixed'
            return (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>{clientName}</TableCell>
                <TableCell className={classes.tableCell}>{dayjs(startDate).format('MM-DD-YYYY')}</TableCell>
                <TableCell className={classes.tableCell}>{endDate && dayjs(endDate).format('MM-DD-YYYY')}</TableCell>
                <TableCell className={classes.tableCell}>{projectName}</TableCell>
                <TableCell align='right' className={classes.tableCell}>
                  {currency(payRate).format()}
                </TableCell>
                <TableCell className={classes.tableCell}>{frequency}</TableCell>
                <TableCell className={classes.tableCell}>{billTypeLabel}</TableCell>
                <TableCell className={classes.tableCell}>{status}</TableCell>
                <TableCell className={classes.tableCell}><ProjectMenu projectId={projectId}/></TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}
