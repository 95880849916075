import { useRef, useCallback, useEffect } from 'react';

export const useDebounce = () => {
    const ref = useRef();

    // Clean up the timeout if the component unmounts
    useEffect(() => {
        return () => {
            if (ref.current) {
                clearTimeout(ref.current);
            }
        };
    }, []);


    const debounce = useCallback((fn, timeout) => {
        if (ref.current) {
            clearTimeout(ref.current);
        }

        ref.current = setTimeout(() => {
            fn();
        }, timeout);
    }, []);

    return debounce;
};
