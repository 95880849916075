export const reasonTypeOptions = [
  { label: "Select a Reason", value: "" },
  {
    label: "Client Unresponsive",
    value: "Client Unresponsive",
  },
  {
    label: "Active dispute / issue",
    value: "Active dispute / issue",
  },
  {
    label: "Account Paused",
    value: "Account Paused",
  },
  {
    label: "Account Churned",
    value: "Account Churned",
  },
  {
    label: "Project is / became Ad hoc",
    value: "Project is / became Ad hoc",
  },
  {
    label: "Identified Upsell / AE Owned",
    value: "Identified Upsell / AE Owned",
  },
  {
    label: "Expert Not Interested",
    value: "Expert Not Interested",
  },
  {
    label: "Month-to-Month",
    value: "Month-to-Month",
  },
  {
    label: "Duplicated Opportunity",
    value: "Duplicated Opportunity",
  },
];
