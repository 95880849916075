import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CustomSpinner from "../components/CustomSpinner";
import FreelancerCardById from "../components/FreelancerCardById";
import TopSection from "../components/TopSection";
import { getFreelancerDetailsById } from "../utils/platformService";
import SearchKeyword from "../components/SearchKeyword";
import { Heading } from "@paro.io/base-ui";
import ParoError from "../components/ParoError";

export default function FreelancerView() {
  const { freelancerId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const searchWord = query.get("search");
  const searchType = query.get("search-type") || "Global";

  const searchWords = query.get("words") || [];
  const searchTags = query.get("tags") || [];
  const searchIndustryTags = query.get("industry-tags") || [];
  const searchSoftwareTags = query.get("software-tags") || [];
  const searchSkillTags = query.get("skill-tags") || [];
  const searchLanguageTags = query.get("language-tags") || [];
  const searchCertificationTags = query.get("certification-tags") || [];

  const history = useHistory();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [loadingError, setLoadingError] = useState(false);

  // const data = JSON.parse(localStorage.getItem("freelancerFullView"));
  // const proposalId = JSON.parse(localStorage.getItem("proposalId"));
  //   JSON.parse(localStorage.getItem("templates"));

  //   localStorage.removeItem("freelancerFullView");
  //   localStorage.removeItem("proposalId");
  //   localStorage.removeItem("templates");

  useEffect(() => {
    const getFreelancerFn = async () => {
      try {
        const response = await getFreelancerDetailsById(+freelancerId);
        setData(response);
        setLoading(false);
      } catch (err) {
        setLoadingError(true);
      }
    };
    getFreelancerFn();
  }, [freelancerId]);

  if (!freelancerId) {
    return history.pushState("/");
  }

  if (loadingError) {
    return <ParoError />;
  }

  if (loading) {
    return (
      <>
        <TopSection />
        <CustomSpinner>Fetching Freelancer Details</CustomSpinner>
      </>
    );
  }

  if (!data) {
    return (
      <>
        <TopSection />
        No data
      </>
    );
  }

  return (
    <>
      <TopSection />
      {(searchType === "All" || !!searchWord) && (
        <>
          <div className="mx-5 pb-2 p-4 py-5">
            <Heading size="h3">Freelancer Profile Comparison</Heading>
          </div>
          {searchType === "All" ? (
            <div className=" mx-5 my-4 p-4 py-5 justify-content-center items-center w-auto shadow bg-white">
              <SearchKeyword
                searchWords={JSON.parse(searchWords)}
                searchTags={JSON.parse(searchTags)}
                searchIndustryTags={JSON.parse(searchIndustryTags)}
                searchSoftwareTags={JSON.parse(searchSoftwareTags)}
                searchSkillTags={JSON.parse(searchSkillTags)}
                searchLanguageTags={JSON.parse(searchLanguageTags)}
                searchCertificationTags={JSON.parse(searchCertificationTags)}
                searchType={searchType}
              />
            </div>
          ) : (
            <div className=" mx-5 my-4 p-4 py-5 justify-content-center items-center w-auto shadow bg-white">
              <SearchKeyword searchWord={searchWord} searchType={searchType} />
            </div>
          )}
        </>
      )}
      <FreelancerCardById data={data} />
    </>
  );
}
