import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { CartContext } from "./CartContext";
import { useHistory } from "react-router-dom";
import { IconCheck } from "@paro.io/base-icons"
import { Chip } from "@material-ui/core";

export default function CartDrawer({ proposalId }) {
  const [cart] = useContext(CartContext).cartContext;

  const history = useHistory();

  return (
    <Button
      id="mm-cart-open"
      variant="contained"
      style={{ background: "#248384", color: "#ffffff" }}
      onClick={() => history.push(`/proposal/${proposalId}/checkout`)}
      startIcon={<IconCheck />}
    >
      Selected <Chip label={cart.length} size="small" className="ml-2 font-bold" color={"default"} />
    </Button>
  );
}
