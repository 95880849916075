import { Box } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { CartContext } from "../components/CartContext";
import TopSection from "../components/TopSection";
import {
    getFindFreelancersCartFn,
    getFreelancerProfilesByIdsFn,
    getProposal,
    getProposalFreelancers,
} from "../services/apiService";
import { Auth0Context } from "../contexts/Auth0Context";
import { useSnackbar } from "notistack";
import ParoError from "../components/ParoError";
import FindFreelancersUnifiedMatching from "../components/FindFreelancersUnifiedMatching/index";
import { formatProposalDetails } from "../utils/freelancerSearchService";
import { MatchProvider } from "../components/MatchContext";
import Loading from "../_global/Loader";
import { isMM } from "../data/appConstants";
import { useHistory } from "react-router";

export default function FindFreelancersUM() {
    const [proposalDetails, setProposalDetails] = useState(null);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const [cart, setCart] = useContext(CartContext).cartContext;
    const { user } = useContext(Auth0Context);
    const { enqueueSnackbar } = useSnackbar();
    const { proposalId } = useParams();
    const history = useHistory();

    const getCartFreelancers = useCallback(async () => {
        try {
            if (!cart.length) {
                const res = await getFindFreelancersCartFn(
                    parseInt(proposalId),
                    user.auth0UserId
                );
                const cartResponse = res.data.data.getFindFreelancersCart?.map(
                    (f) => +f.freelancerId
                );
                if (cartResponse?.length > 0) {
                    const response = await getFreelancerProfilesByIdsFn([
                        ...new Set(cartResponse),
                    ]);
                    setCart(response.data.data.getFreelancerProfilesByIds || []);
                }
            }
        } catch (err) {
            setLoadingError(true);
            enqueueSnackbar(`Unable to get cart details!`, {
                variant: "error",
            });
        }
    }, [cart.length, enqueueSnackbar, proposalId, setCart, user.auth0UserId]);

    const getProposalFn = async () => {
        setIsLoading(true)
        try {
            const {
                data: {
                    data: { getProposal: data },
                },
            } = await getProposal(parseInt(proposalId));
            if(!isMM(data.opportunityTypeMM)) {
                history.push("/")
            }
            const proposalFreelancers = await getProposalFreelancers(data.id);
            const curPf = proposalFreelancers.data.data.getProposalFreelancers;
            const formInputData = formatProposalDetails(data, curPf)
            setProposalDetails(formInputData);
        } catch (err) {
            setLoadingError(true);
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        getProposalFn();
        getCartFreelancers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loadingError) {
        return <ParoError />;
    }

    const onRefetchProposal = () => {
        getProposalFn()
    }

    return (
        <>
            {
                isLoading ? <Loading /> : <>
                    <TopSection proposalId={proposalId} proposalDetails={proposalDetails} refetchProposal={onRefetchProposal} showCart={cart?.length} title={"Find Experts"} new={true} />
                    <Box className="mx-4 px-4">
                        <MatchProvider>
                            <FindFreelancersUnifiedMatching proposalDetails={proposalDetails} />
                        </MatchProvider>
                    </Box>
                </>
            }
        </>
    );
}
