import React, { createContext, useState } from "react";

export const MatchResultsContext = createContext();

export const MatchResultsProvider = (props) => {
  const [matchResults, setMatchResults] = useState([]);
  const [matchQuery, setMatchQuery] = useState({});

  return (
    <MatchResultsContext.Provider
      value={{
        matchResultsContext: [matchResults, setMatchResults],
        matchQueryContext: [matchQuery, setMatchQuery],
      }}
    >
      {props.children}
    </MatchResultsContext.Provider>
  );
};
