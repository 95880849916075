import { Heading, Input } from "@paro.io/base-ui";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectField from "./SelectField";
import SelectFaFTags from "./SelectFaFTags";
import { FAF_GROUPS, FL_STATUSES } from "../data/appConstants";
import { Chip } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

export default function FindAFreelancerForm({
  onSubmit,
  proposalTags,
  opportunity,
}) {
  const { control, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });

  const statusOptions = Object.keys(FL_STATUSES);
  const [tags, setTags] = useState([]);
  const [importTags, setImportTags] = useState(false);

  useEffect(() => {
    setTags((tags) => {
      const tagsToImport = importTags
        ? [...tags, ...proposalTags]
        : tags.filter(
            (t) =>
              !proposalTags.some(
                (pt) => JSON.stringify(pt) === JSON.stringify(t)
              )
          );
      setValue("search", tagsToImport);
      return tagsToImport;
    });
  }, [importTags, proposalTags, setValue]);
  return (
    <>
      <div className="bg-white flex justify-center items-center py-6 pt-2">
        <div className="container mx-auto rounded-lg px-6">
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="flex w-full">
              <div className="w-min-100 w-full flex justify-between items-center">
                <Heading size="h3">Find Freelancers</Heading>
              </div>
            </div>
            <div className="items-center justify-between w-full">
              <div className="flex items-center mx-auto w-full">
                <div className="flex items-center mx-auto w-2/3">
                  <div className="ms:flex items-center mx-auto w-1/3">
                    Status
                    <Controller
                      name="status"
                      defaultValue={statusOptions[0]}
                      render={({ field }) => (
                        <SelectField
                          {...field}
                          label=""
                          options={statusOptions}
                        />
                      )}
                      control={control}
                    />
                  </div>

                  <div className="ms:flex items-center px-2 mx-auto w-2/3">
                    Search
                    <Controller
                      name="search"
                      id="search-faf-input"
                      label="Search"
                      render={({ field, field: { onChange } }) => (
                        <span id="mm-faf-textField">
                          <SelectFaFTags
                            {...field}
                            onChange={(newValue) => {
                              setTags(newValue);
                              onChange(newValue);
                            }}
                            opportunityType={opportunity}
                          />
                        </span>
                      )}
                      control={control}
                    />
                  </div>
                </div>

                <div className="flex items-center px-4 mx-auto w-1/3">
                  <div className="flex w-32 space-x-4 rounded-lg">
                    <Controller
                      name="minHours"
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="minHours"
                          type="number"
                          placeholder=""
                          label="Min Avail h"
                          isInvalid={false}
                        />
                      )}
                      control={control}
                    />
                  </div>
                  <div className="flex p-2 w-32 space-x-4 rounded-lg">
                    <Controller
                      name="maxHours"
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="maxHours"
                          type="number"
                          placeholder=""
                          label="Max Avail h"
                          isInvalid={false}
                        />
                      )}
                      control={control}
                    />
                  </div>
                  <div className="flex p-2 w-32 space-x-4 rounded-lg">
                    <Controller
                      name="minRate"
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="minRate"
                          type="number"
                          placeholder=""
                          label="Min Rate $"
                          isInvalid={false}
                        />
                      )}
                      control={control}
                    />
                  </div>
                  <div className="flex p-2 w-32 space-x-4 rounded-lg">
                    <Controller
                      name="maxRate"
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="maxRate"
                          type="number"
                          placeholder=""
                          label="Max Rate $"
                          isInvalid={false}
                        />
                      )}
                      control={control}
                    />
                  </div>
                </div>
              </div>

              <div className="flex mx-auto w-full mt-4">
                <div className="flex items-start mx-auto w-4/5">
                  <div
                    id={`mm-faf-${
                      !importTags ? "Import" : "Remove"
                    }-proposalTags`}
                  >
                    <button
                      type="button"
                      onClick={() => setImportTags(!importTags)}
                      className="p-2 border w-48 rounded-md bg-info"
                    >
                      {`${!importTags ? `Import` : `Remove`} Proposal Tags`}
                    </button>
                  </div>
                  <div className="m-1 flex flex-flow-wrap">
                    <div className="font-bold p-1">Tags:</div>
                    {tags?.map((option, index) => (
                      <div key={index} className="p-1">
                        <Chip
                          variant="outlined"
                          label={
                            <p>
                              {!!FAF_GROUPS[option.groupName]
                                ? `${option.groupName}: ${option.title}`
                                : `"${option.title}"`}
                              <span
                                onClick={(e) => {
                                  const temp = [...tags];
                                  const filteredTemp = temp.filter(
                                    (obj) =>
                                      obj.inputValue !== option.inputValue
                                  );
                                  setTags(filteredTemp);
                                  setValue("search", filteredTemp);
                                  e.stopPropagation();
                                }}
                                className="ml-1 text-gray-500"
                              >
                                <Close fontSize="small" />
                              </span>
                            </p>
                          }
                          // {...getTagProps({ index })}
                          className={
                            option.required ? `font-bold` : `font-light`
                          }
                          onClick={() => {
                            const temp = [...tags];
                            const index = temp.findIndex(
                              (obj) => obj.inputValue === option.inputValue
                            );
                            temp[index].required = !option.required;
                            setTags(temp);
                            setValue("search", temp);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex items-center justify-end px-4 mx-auto">
                  <button
                    id="mm-faf-search"
                    className="p-2 border w-48 rounded-md bg-tertiary text-black"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
