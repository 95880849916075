import { Tag, Text } from "@paro.io/base-ui";
import React, { useCallback, useEffect, useState } from "react";

export const SearchKeyword = ({
  searchWord = "",
  searchWords = [],
  searchTags = [],
  searchIndustryTags = [],
  searchSoftwareTags = [],
  searchSkillTags = [],
  searchLanguageTags = [],
  searchCertificationTags = [],
  searchType = "Global",
}) => {
  const [searchValue, setSearchValue] = useState(searchWord);
  // useEffect(() => {

  const doSearch = useCallback((text, color) => {
    // if (!!document.getElementById("hid_search") && color !== "transparent") {
    //   doSearch(document.getElementById("hid_search")?.value, "transparent");
    //   document.getElementById("hid_search").value = text;
    // }
    if (window.find && window.getSelection) {
      document.designMode = "on";
      var sel = window.getSelection();
      sel.collapse(document.body, 0);

      while (window.find(text)) {
        document.execCommand("HiliteColor", false, color);
        sel.collapseToEnd();
      }
      document.designMode = "off";
    } else if (document.body.createTextRange) {
      var textRange = document.body.createTextRange();
      while (textRange.findText(text)) {
        textRange.execCommand("BackColor", false, color);
        textRange.collapse(false);
      }
    }
  }, []);

  let color = "";
  switch (searchType) {
    case "Proposal Tag":
      color = "rgb(25, 167, 158, .3)";
      break;
    case "Global":
      color = "rgba(246, 169, 4, .8)";
      break;
    case "Tag":
      color = "rgb(24, 120, 189, .6)";
      break;
    default:
      color = "rgba(246, 169, 4, .8)";
      break;
  }

  useEffect(() => {
    if (searchType === "All") {
      searchIndustryTags.forEach((searchIndustryTag) =>
        doSearch(searchIndustryTag, "rgb(25, 167, 158, .3)")
      );
      searchSoftwareTags.forEach((searchSoftwareTag) =>
        doSearch(searchSoftwareTag, "rgb(25, 167, 158, .3)")
      );
      searchSkillTags.forEach((searchSkillTag) =>
        doSearch(searchSkillTag, "rgb(25, 167, 158, .3)")
      );
      searchLanguageTags.forEach((searchLanguageTag) =>
        doSearch(searchLanguageTag, "rgb(25, 167, 158, .3)")
      );
      searchCertificationTags.forEach((searchCertificationTag) =>
        doSearch(searchCertificationTag, "rgb(25, 167, 158, .3)")
      );
      searchWords.forEach((searchWord) =>
        doSearch(searchWord, "rgba(246, 169, 4, .8)")
      );
      searchTags.forEach((searchTag) =>
        doSearch(searchTag, "rgb(24, 120, 189, .3)")
      );
    } else {
      doSearch(searchWord, color);
    }
  }, [
    searchWord,
    searchWords,
    searchTags,
    searchIndustryTags,
    searchSoftwareTags,
    searchSkillTags,
    searchLanguageTags,
    searchCertificationTags,
    doSearch,
    color,
    searchType,
  ]);

  return (
    <>
      {searchType === "All" ? (
        <>
          <div className="w-full text-lg text-grey">Proposal Tag Search</div>
          <div class="grid grid-cols-3 gap-4">
            <div>
              <Text size="md" className="text-grey">
                Industry
              </Text>

              {searchIndustryTags.length > 0
                ? searchIndustryTags.map((c) => (
                    <span className="mr-1">
                      <Tag color="success" label={c} variant="solid" />
                    </span>
                  ))
                : `No Industry Tags to search`}
            </div>
            <div>
              <Text size="md" className="text-grey">
                Software
              </Text>
              {searchSoftwareTags.length > 0
                ? searchSoftwareTags.map((c) => (
                    <span className="mr-1">
                      <Tag label={c} color="success" variant="solid" />
                    </span>
                  ))
                : `No Software Tags to search`}
            </div>
            <div>
              <Text size="md" className="text-grey">
                Skills
              </Text>
              {searchSkillTags.length > 0
                ? searchSkillTags.map((c) => (
                  <span className="mr-1">
                    <Tag label={c} color="success" variant="solid" />
                  </span>
                ))
                : `No Skill Tags to search`}
            </div>
            <div>
              <Text size="md" className="text-grey">
                Languages
              </Text>
              {searchLanguageTags.length > 0
                ? searchLanguageTags.map((c) => (
                  <span className="mr-1">
                    <Tag label={c} color="success" variant="solid" />
                  </span>
                ))
                : `No Language Tags to search`}
            </div>
            <div>
              <Text size="md" className="text-grey">
                Certifications
              </Text>
              {searchCertificationTags.length > 0
                ? searchCertificationTags.map((c) => (
                  <span className="mr-1">
                    <Tag label={c} color="success" variant="solid" />
                  </span>
                ))
                : `No Certification Tags to search`}
            </div>
          </div>
          <div className="py-2">
            <div className="w-full text-lg text-grey">Global Search</div>
            <div className="flex">
              {searchWords.length > 0
                ? searchWords.map((c) => (
                    <span className="mr-1">
                      <Tag label={c} color="warning" />
                    </span>
                  ))
                : `No Terms to search`}
            </div>
          </div>
          <div className="py-2">
            <div className="w-full text-lg text-grey">
              Additional Tag Search
            </div>
            <div className="flex">
              {searchTags.length > 0
                ? searchTags.map((c) => (
                    <span className="mr-1">
                      <Tag label={c} color="info" variant="solid" />
                    </span>
                  ))
                : `No Tags to search`}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-full text-lg">{searchType} Search</div>
          <div className="flex">
            <input
              className={`w-4/5 p-2 bg-gray-200 h-10 rounded`}
              style={{ background: color }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              disabled
              // disabled={["Proposal Tag", "Tag"].includes(searchType)}
            />
            <button
              id="mm-compare-search"
              className="w-1/5 ml-2 p-2 border rounded-md bg-primary text-white"
              type="button"
              onClick={() => doSearch(searchValue, color)}
            >
              Find Keywords
            </button>
          </div>
          <div className="flex justify-end">
            <div className="w-2/3 text-right py-2 text-sm text-gray">
              If you do not see the search term on the page, it might be under
              More information in Projects or Notes Section.
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SearchKeyword;
