import { Box } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CartContext } from "../components/CartContext";
import CustomSpinner from "../components/CustomSpinner";
import FindAFreelancerForm from "../components/FindAFreelancerForm";
import FindFreelancerResults from "../components/FindFreelancerResults";
import TopSection from "../components/TopSection";
import {
    BUYOUT_FLID,
    FAF_GROUPS,
    FL_STATUSES,
    getKeyByValue,
    isMM,
    OPPORTUNITY_TYPES,
} from "../data/appConstants";
import {
    getFindFreelancersCartFn,
    // getAvailability,
    getFreelancerProfiles,
    getFreelancerProfilesByIdsFn,
    getParo4cpaFlIds,
    getProposal,
} from "../services/apiService";
import { Auth0Context } from "../contexts/Auth0Context";
import { useSnackbar } from "notistack";
import ParoError from "../components/ParoError";

const BUYOUT = OPPORTUNITY_TYPES.BuyOut;
const Paro4CPA = OPPORTUNITY_TYPES.Paro4CPA;

export default function FindFreelancersLegacy() {
    const { proposalId } = useParams();
    const { user } = useContext(Auth0Context);
    const [cart, setCart] = useContext(CartContext).cartContext;
    const { enqueueSnackbar } = useSnackbar();
    const [showCart, setShowCart] = useState(false);

    const [freelancers, setFreelancers] = useState([]);
    const [loadingResults, setLoadingResults] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    const [proposalDetails, setProposalDetails] = useState(null);
    const [externalIds, setExternalIds] = useState([]);
    const [anchoredFls, setAnchoredFls] = useState([]);

    const [loadingError, setLoadingError] = useState(false);

    const history = useHistory();

    const getCartFreelancers = useCallback(async () => {
        try {
            if (!cart.length) {
                const res = await getFindFreelancersCartFn(
                    parseInt(proposalId),
                    user.auth0UserId
                );
                const cartResponse = res.data.data.getFindFreelancersCart?.map(
                    (f) => +f.freelancerId
                );
                if (cartResponse?.length > 0) {
                    const response = await getFreelancerProfilesByIdsFn([
                        ...new Set(cartResponse),
                    ]);
                    setCart(response.data.data.getFreelancerProfilesByIds || []);
                    setShowCart(true);
                }
            } else {
                setShowCart(true);
            }
        } catch (err) {
            setLoadingError(true);
            enqueueSnackbar(`Unable to get cart details!`, {
                variant: "error",
            });
        }
    }, [cart.length, enqueueSnackbar, proposalId, setCart, user.auth0UserId]);

    useEffect(() => {
        const getProposalFn = async () => {
            try {
                const {
                    data: {
                        data: { getProposal: data },
                    },
                } = await getProposal(parseInt(proposalId));

                if (isMM(data?.opportunityTypeMM)) {
                    return history.push("/");
                }

                const formInputData = {
                    status: "Available",
                    search: [
                        {
                            title: data.serviceLine,
                            inputValue: data.serviceLine,
                            groupName: "Service Lines",
                            required: true,
                        },
                        /*   ...data.matchmakingOptions.tags.map((t) => ({
                        title: t.value,
                        inputValue: t.value,
                        groupName: getKeyByValue(FAF_GROUPS, t.field),
                        required: t.required,
                      })), */
                        ...data.matchmakingOptions.tags.reduce((acc, t) => {
                            if (
                                (t.field === "industries" && t.value === "All") ||
                                (t.field === "softwares" && t.value === "None")
                            ) {
                                return acc;
                            } else {
                                return [
                                    ...acc,
                                    {
                                        title: t.value,
                                        inputValue: t.value,
                                        groupName: getKeyByValue(FAF_GROUPS, t.field),
                                        required: t.required,
                                    },
                                ];
                            }
                        }, []),
                    ],
                    minHours: "" + data.matchmakingOptions.availabilityRange.min,
                    maxHours: "" + data.matchmakingOptions.availabilityRange.max,
                    minRate: "" + data.matchmakingOptions.rateRange.min,
                    maxRate: "" + data.matchmakingOptions.rateRange.max,
                    opportunityTypeMM: data.opportunityTypeMM,
                };

                if (data.opportunityTypeMM === Paro4CPA) {
                    const paro4cpaFlIdsRes = await getParo4cpaFlIds();
                    const paro4cpaFlIds = paro4cpaFlIdsRes.data.data.getParo4cpaFls.map(
                        (f) => f.freelancer_id
                    );

                    setExternalIds(paro4cpaFlIds);
                }
                if (data.opportunityTypeMM === BUYOUT) {
                    // const buyoutId = BUYOUT_FLID();
                    // const buyoutRes = await getAvailability(buyoutId);
                    // const buyoutFlProfile = buyoutRes.data.data.getFreelancerProfile;
                    const buyoutFlProfile = {
                        estimatedPotentialHours: null,
                        currentMonthClientCount: null,
                        currentMonthHoursCount: null,
                        currentMonthProjectCount: null,
                        previousMonthClientCount: null,
                        previousMonthHoursCount: null,
                        previousMonthProjectCount: null,
                        totalProposals: null,
                        totalProposalClients: null,
                        projectWinRate: null,
                        availableHours: 0,
                        id: BUYOUT_FLID(),
                        name: "System Buyout",
                        currentProjectedHours: null,
                        freelancerHours: null,
                        serviceLine: null,
                        state: null,
                        clientBillRate: null,
                        __typename: "FreelancerProfile",
                    };
                    setFreelancers([buyoutFlProfile]);
                }
                setProposalDetails(formInputData);
            } catch (err) {
                setLoadingError(true);
            }
        };
        getProposalFn();
        getCartFreelancers();
    }, [getCartFreelancers, history, proposalId]);

    const getFreelancers = async (data) => {
        const queryOptions = {
            applicationStatus: FL_STATUSES[data.status],
            tags:
                !!data.search && data.search?.length !== 0
                    ? data.search?.map((s) => ({
                        field: FAF_GROUPS[s.groupName] || "*",
                        value: s.title || s.inputValue,
                        required: s.required,
                    }))
                    : [
                        {
                            field: "*",
                            value: "",
                            required: true,
                        },
                    ],
            ...((!!data.minHours || !!data.maxHours) && {
                availabilityRange: {
                    min: parseInt(data.minHours),
                    max: parseInt(data.maxHours),
                },
            }),
            ...((!!data.minRate || !!data.maxRate) && {
                rateRange: {
                    min: parseInt(data.minRate),
                    max: parseInt(data.maxRate),
                },
            }),
        };
        setInitialLoad(false);
        setLoadingResults(true);

        const res = await getFreelancerProfiles({
            queryOptions: { ...queryOptions, isMM: true },
        });
        const respFreelancer = res.data.data.getFreelancerProfiles;
        const nonP4cpaFls = [];
        const paro4cpaFls = [];
        for (let f of respFreelancer) {
            if (externalIds.indexOf(f.id) !== -1) {
                paro4cpaFls.push(f);
            } else {
                nonP4cpaFls.push(f);
            }
        }
        setAnchoredFls(paro4cpaFls);
        setFreelancers(nonP4cpaFls);
        setLoadingResults(false);
    };

    if (loadingError) {
        return <ParoError />;
    }

    return (
        <>
            <TopSection proposalId={proposalId} showCart={showCart} />

            {!!proposalDetails?.opportunityTypeMM && (
                <div className="bg-white flex justify-end px-5 py-3">
                    <span className="container text-right mx-auto rounded-lg font-medium bg-white">
                        Opportunity-Type: {proposalDetails?.opportunityTypeMM}
                    </span>
                </div>
            )}
            {proposalDetails?.opportunityTypeMM === BUYOUT && (
                <FindFreelancerResults
                    proposalId={proposalId}
                    freelancers={freelancers}
                    anchoredFls={[]}
                    opportunityTypeMM={proposalDetails?.opportunityTypeMM}
                    buyout
                />
            )}
            {!!proposalDetails ? (
                proposalDetails?.opportunityTypeMM !== BUYOUT && (
                    <FindAFreelancerForm
                        opportunity={proposalDetails?.opportunityTypeMM}
                        proposalTags={proposalDetails.search}
                        onSubmit={getFreelancers}
                    />
                )
            ) : (
                <CustomSpinner>Loading Proposal Details</CustomSpinner>
            )}
            {proposalDetails?.opportunityTypeMM !== BUYOUT && (
                <Box className="mx-4 px-4">
                    {!initialLoad &&
                        (loadingResults ? (
                            <CustomSpinner>Finding Freelancers</CustomSpinner>
                        ) : (
                            <FindFreelancerResults
                                proposalId={proposalId}
                                freelancers={freelancers}
                                anchoredFls={anchoredFls}
                                opportunityTypeMM={proposalDetails?.opportunityTypeMM}
                            />
                        ))}
                </Box>
            )}
        </>
    );
}
