import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Text,
  Button as BaseUiButton,
  Modal,
} from "@paro.io/base-ui";
import TablePagination from "./TablePagination";
import {
  IconExternalLink,
  IconCog,
  IconEye,
  IconEyeOff,
  IconScale,
} from "@paro.io/base-icons";
import {
  Configure,
  useHits,
  usePagination,
  useSortBy,
} from "react-instantsearch";
import {
  Avatar,
  Checkbox,
  IconButton,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import { CartContext } from "../CartContext";
import {
  addFindFreelancersToCartFn,
  removeFindFlFromCartFn,
} from "../../services/apiService";
import { Auth0Context } from "../../contexts/Auth0Context";
import { useSnackbar } from "notistack";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import {
  Block,
  ErrorOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PermIdentityOutlined,
} from "@material-ui/icons";
import LoadingIndicator from "./LoadingIndicator";
import { metresToMiles } from "../../shared/utils";
import { blacklistFls } from "./constants";
import { MatchResultsContext } from "../../contexts/MatchResultsContext";
import { Rating } from "@material-ui/lab";
import ReviewsModal from "./ReviewsModal";

const isRatingsEnabled = process.env.REACT_APP_RATINGS_ENABLED === "true";

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: "#808080",
    maxWidth: "none",
  },
});

const tableHeads = [
  {
    key: "Expert",
    sortable: true,
    sortKey: "firstName",
    sortDirection: "asc",
    isVisible: true,
    shouldHideInManageFields: true,
  },
  {
    key: "Service",
    sortable: true,
    sortKey: "serviceLine",
    sortDirection: "asc",
    isVisible: true,
    shouldHideInManageFields: false,
  },
  {
    key: "Availability",
    sortable: true,
    sortKey: "availableHours",
    sortDirection: "asc",
    isVisible: true,
    shouldHideInManageFields: false,
  },
  {
    key: "Location",
    sortable: true,
    sortKey: "state",
    sortDirection: "asc",
    isVisible: false,
    shouldHideInManageFields: false,
  },
  {
    key: "Client Rate",
    sortable: true,
    sortKey: "hourlyRate",
    sortDirection: "asc",
    isVisible: true,
    shouldHideInManageFields: false,
  },
  {
    key: "Onboarded Date",
    sortable: true,
    sortKey: "currentProfileEnteredStageDate",
    sortDirection: "asc",
    isVisible: false,
    shouldHideInManageFields: false,
  },
  {
    key: "Distance (miles)",
    sortable: true,
    sortKey: "distance",
    sortDirection: "asc",
    isVisible: false,
    shouldHideInManageFields: false,
  },
  ...(isRatingsEnabled
    ? [
        {
          key: "Rating",
          sortable: false,
          sortKey: "overallRatingAvg",
          sortDirection: "desc",
          isVisible: false,
          shouldHideInManageFields: false,
        },
      ]
    : []),
];

const Freelancers_list =
  process.env.REACT_APP_ALGOLIA_FREELANCERS_LIST_INDEX_NAME ??
  "freelancer-list-dev";

const Freelancer = ({
  hit,
  proposalId,
  user,
  freelancers,
  tableFields,
  serviceLinesPlusOptions,
  onViewReview,
}) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [cart, setCart] = useContext(CartContext).cartContext;
  const { enqueueSnackbar } = useSnackbar();
  const [compareFls, setCompareFls] = useContext(CartContext).compareFlsContext;
  const [matchQuery] = useContext(MatchResultsContext)?.matchQueryContext;

  const onChangeHandler = async (freelancer) => {
    setLoader(true);
    if (!cart.some((c) => c.id === freelancer.id)) {
      await addFindFreelancersToCartFn({
        proposalId: +proposalId,
        userId: user.auth0UserId,
        findFreelancerIds: [freelancer.id],
      });
      setCart((cart) => [...cart, { ...freelancer, flID: freelancer.id }]);
      enqueueSnackbar(`Added ${freelancer.name}  to Cart!`, {
        variant: "success",
      });
    } else {
      await removeFindFlFromCartFn({
        proposalId: +proposalId,
        userId: user.auth0UserId,
        freelancerId: freelancer.id,
      });
      setCart((currentState) =>
        currentState.filter((c) => c.flID !== freelancer.id)
      );
      enqueueSnackbar(`Removed ${freelancer.name} from Cart!`, {
        variant: "warning",
      });
    }
    setLoader(false);
  };

  // const isFreelancerDisabled = !hit?.hasValidEAnd0Insurance || hit?.disabled;
  // const disabledFreelancerTooltipText = !hit?.hasValidEAnd0Insurance
  //   ? "The expert does not have valid insurance."
  //   : hit?.disabled
  //   ? "Expert has been proposed already."
  //   : "";

  const isFreelancerDisabled = hit?.disabled;

  const isFieldVisible = useCallback(
    (field) => {
      return tableFields.some((i) => i.sortKey === field && i.isVisible);
    },
    [tableFields]
  );

  const isFreelancerInCart = useMemo(
    () => cart.some((c) => c.id === hit.freelancerId),
    [cart, hit.freelancerId]
  );
  const showFlError =
    hit.freelancerStatusId !== 1 ||
    hit.suspensionStatus ||
    hit.needsParoFoundationTraining ||
    blacklistFls.includes(hit.freelancerId) ||
    !hit.hasValidEAnd0Insurance ||
    hit.disabled;

  const getFlStatus = (fl) => {
    const reasons = [];
    if (fl.freelancerStatusId !== 1) {
      reasons.push("This Expert is Unavailable for Hire");
    }
    if (fl.suspensionStatus) {
      reasons.push("This Expert is Suspended");
    }
    if (fl.needsParoFoundationTraining) {
      reasons.push("This Expert hasn’t completed Paro Foundations");
    }
    if (blacklistFls.includes(fl.freelancerId)) {
      reasons.push("This Expert should not be added to any new business");
    }
    if (!fl.hasValidEAnd0Insurance) {
      reasons.push("This Expert does not have active E&O insurance");
    }
    if (fl?.disabled) {
      reasons.push("This Expert has been proposed already.");
    }
    return reasons.length > 0 ? (
      <div>
        {reasons.map((reason, index) => (
          <div key={index} className="flex items-center whitespace-nowrap">
            <Block
              color="error"
              style={{ marginRight: 4, transform: "scaleX(-1)" }}
              fontSize="small"
            />
            <span className="py-2" style={{ fontSize: "1.2em" }}>
              {reason}
            </span>
          </div>
        ))}
      </div>
    ) : null;
  };

  const getCategoryValue = (tags) => {
    if (!tags.length) {
      return;
    }

    const avlTagsLen = tags.filter((i) => i.value === 1).length;
    return avlTagsLen === tags.length ? 1 : avlTagsLen === 0 ? -1 : 0;
  };

  const onAddToCompare = (item) => {
    let fl = freelancers?.find((f) => f.flID === hit.freelancerId);
    if (!fl) {
      const industries = matchQuery.industries.map((i) => ({
        data: i.value,
        value: item.industryTags.includes(i.value) ? 1 : -1,
        type: "Industry",
      }));

      const softwares = matchQuery.softwares.map((i) => ({
        data: i.value,
        value: item.softwareTags.includes(i.value) ? 1 : -1,
        type: "Software",
      }));

      const skills = matchQuery.skills.map((i) => ({
        data: i.value,
        value: item.financialTags.includes(i.value) ? 1 : -1,
        type: "Skills",
      }));

      const languages = matchQuery.language.map((i) => ({
        data: i.value,
        value: item.languages.includes(i.value) ? 1 : -1,
        type: "Languages",
      }));

      const certificates = matchQuery.certificate.map((i) => ({
        data: i.value,
        value: item.certificates.includes(i.value) ? 1 : -1,
        type: "Certificates",
      }));

      const slPlusTags = matchQuery.serviceLinesPlusTags.map((i) => ({
        data:
          serviceLinesPlusOptions.find(({ value }) => value === i.value)?.id +
          "",
        value: item.servicelinePlus.includes(i.value) ? 1 : -1,
        type: "SL_Plus",
      }));

      fl = {
        ...item,
        id: item?.freelancerId,
        FL_Name: item.firstName + " " + item.lastName,
        flID: item.freelancerId,
        legacyMetadata: {
          hourlyRate: item.hourlyRate,
          primaryServiceLine: item.serviceLine,
        },
        relevantFields: [
          {
            type: "Effort",
            value: 1,
          },
          {
            type: "Category",
            data: "Teams",
            value: 1,
          },
          {
            type: "Category",
            data: "Industry",
            value: getCategoryValue(industries),
          },
          {
            type: "Category",
            data: "Software",
            value: getCategoryValue(softwares),
          },
          {
            type: "Category",
            data: "Skills",
            value: getCategoryValue(skills),
          },
          {
            type: "Category",
            data: "SL_Plus",
            value: getCategoryValue(slPlusTags),
          },
          {
            type: "Category",
            data: "Languages",
            value: getCategoryValue(languages),
          },
          {
            type: "Category",
            data: "Certificates",
            value: getCategoryValue(certificates),
          },
          ...industries,
          ...softwares,
          ...skills,
          ...languages,
          ...certificates,
          ...slPlusTags,
        ],
      };
    }

    setCompareFls([...compareFls, fl]);
  };

  const onRemoveCompare = (id) => {
    setCompareFls(compareFls.filter((i) => i.id !== id));
  };

  return (
    <TableRow
      key={hit.freelancerId}
      className={isFreelancerDisabled ? "opacity-50 bg-gray-200" : ""}
    >
      <TableCell>
        {loader ? (
          <Spinner
            animation="grow"
            size="sm"
            className="text-success mr-3 ml-3"
          />
        ) : (
          <Checkbox
            checked={isFreelancerInCart}
            disabled={isFreelancerDisabled}
            onChange={(e) => {
              onChangeHandler({
                id: hit.freelancerId,
                name: `${hit.firstName} ${hit.lastName}`,
                serviceLine: hit.serviceLine,
              });
            }}
          />
        )}
      </TableCell>
      <TableCell>
        <div className="flex items-center gap-2">
          <Avatar
            src="/broken-image.jpg"
            style={{
              backgroundColor: "#f1f5f9",
              color: "#000000ab",
            }}
          >
            <PermIdentityOutlined />
          </Avatar>
          <Text
            className={`font-semibold ${
              !isFreelancerDisabled ? "cursor-pointer" : ""
            }`}
          >
            <div
              onClick={() => {
                if (!isFreelancerDisabled) {
                  window.open(
                    `${process.env.REACT_APP_PARO_APP_URL}/internal/expert-profile/${hit.freelancerId}`,
                    "_blank"
                  );
                }
              }}
              className={!isFreelancerDisabled ? "hover:underline" : ""}
            >
              {`${hit.firstName} ${hit.lastName}`}
            </div>
          </Text>
          {freelancers?.some((i) => i.id === hit.freelancerId) && (
            <WbSunnyIcon className="font-lg text-success font-bold" />
          )}
          {showFlError && (
            <Tooltip
              title={getFlStatus(hit)}
              classes={{ tooltip: classes.tooltip }}
            >
              <ErrorOutline className="text-sm text-danger" color="error" />
            </Tooltip>
          )}
        </div>
      </TableCell>
      {isFieldVisible("serviceLine") && (
        <TableCell>{hit.serviceLine}</TableCell>
      )}
      {isFieldVisible("availableHours") && (
        <TableCell>Avail: {hit.availableHours} hrs</TableCell>
      )}
      {isFieldVisible("state") && <TableCell>{hit.state}</TableCell>}
      {isFieldVisible("hourlyRate") && (
        <TableCell>CR: ${hit.hourlyRate}</TableCell>
      )}

      {isFieldVisible("currentProfileEnteredStageDate") && (
        <TableCell>{hit.currentProfileEnteredStageDate}</TableCell>
      )}
      {isFieldVisible("distance") && (
        <TableCell>
          {hit?._rankingInfo?.matchedGeoLocation
            ? metresToMiles(hit?._rankingInfo?.matchedGeoLocation?.distance)
            : ""}
        </TableCell>
      )}
      {isRatingsEnabled && isFieldVisible("overallRatingAvg") && (
        <TableCell>
          <div
            className={`flex items-center gap-2 ${
              hit.overallRatingAvg > 0 ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={() => {
              if (hit.overallRatingAvg > 0) {
                onViewReview(hit);
              }
            }}
          >
            <Rating
              name="overallRatingAvg"
              defaultValue={hit.overallRatingAvg ?? 0}
              readOnly
              precision={0.1}
            />
            <Text>
              {hit.overallRatingAvg ?? 0}
              {hit.overallRatingAvg > 0 && `(${hit.ratings.length})`}
            </Text>
          </div>
        </TableCell>
      )}
      <TableCell className="flex items-center justify-around">
        <IconButton
          onClick={() => {
            if (!isFreelancerDisabled) {
              window.open(
                `${process.env.REACT_APP_PARO_APP_URL}/internal/am/findfreelancers/jsonview/${hit.freelancerId}?navigation=false`,
                "_blank"
              );
            }
          }}
        >
          <IconExternalLink className="text-grey" />
        </IconButton>

        <div className="flex items-center justify-center">
          <div
            className={`p-1 rounded-sm cursor-pointer ${
              compareFls.some((c) => c.id === hit.freelancerId)
                ? "bg-green-400"
                : ""
            }`}
            onClick={() => {
              if (compareFls.some((i) => i.id === hit.freelancerId)) {
                onRemoveCompare(hit.freelancerId);
              } else {
                onAddToCompare(hit);
              }
            }}
          >
            <IconScale />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

const FreelancerResultsTable = (props) => {
  const { hits } = useHits();
  const {
    nbHits,
    currentRefinement,
    nbPages,
    refine: refinePage,
  } = usePagination();
  const { refine: refineSortBy } = useSortBy({
    items: [
      { value: `${Freelancers_list}_firstName_asc` },
      { value: `${Freelancers_list}_firstName_desc` },
      { value: `${Freelancers_list}_lastName_asc` },
      { value: `${Freelancers_list}_lastName_desc` },
      { value: `${Freelancers_list}_serviceLine_asc` },
      { value: `${Freelancers_list}_serviceLine_desc` },
      { value: `${Freelancers_list}_availableHours_asc` },
      { value: `${Freelancers_list}_availableHours_desc` },
      { value: `${Freelancers_list}_state_asc` },
      { value: `${Freelancers_list}_state_desc` },
      { value: `${Freelancers_list}_hourlyRate_asc` },
      { value: `${Freelancers_list}_hourlyRate_desc` },
    ],
  });
  const [sortItems, setSortItems] = useState(tableHeads);
  const [sortBy, setSortBy] = useState("firstName");
  const [hitsPerPage, setHitsPerPage] = useState(100);
  const [tableFields, setTableFields] = useState(tableHeads);
  const [isOpenManageFieldsDropdown, setIsOpenManageFieldsDropdown] =
    useState(false);
  const [viewRating, setViewRating] = useState(false);
  const [ratingData, setRatingData] = useState({});
  const { user } = useContext(Auth0Context);

  const proposalFreelancers = props.proposalDetails?.proposalFreelancers?.map(
    (fl) => fl.freelancerId
  );

  const handleSort = (column) => {
    const columnSortItem = sortItems.find((col) => col.sortKey === column);
    const direction = columnSortItem.sortDirection === "asc" ? "desc" : "asc";
    setSortItems((items) =>
      items.map((item) => {
        if (item.sortKey === column) {
          item.sortDirection = direction;
        }
        return item;
      })
    );
    refineSortBy(`${Freelancers_list}_${column}_${direction}`);
    setSortBy(column);
  };

  let sortedData = [];

  sortedData = hits?.map((fl) => ({
    ...fl,
    disabled: proposalFreelancers?.includes(fl.freelancerId),
  }));

  const getSortArray = (direction) => {
    return direction === "asc" ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
  };

  const handleBlur = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    setIsOpenManageFieldsDropdown(false);
  };

  const onToggleManageField = (currentField) => {
    setTableFields(
      tableFields.map((field) => {
        if (field.key === currentField.key) {
          field.isVisible = !field.isVisible;
        }
        return field;
      })
    );
  };

  return (
    <>
      <div className="my-4">
        <div className="flex gap-2 items-center  flex-wrap justify-between w-full">
          <div>
            <h3 className="font-2xl text-black font-bold">
              {nbHits} {nbHits === 1 ? "Expert" : "Experts"} meet all criteria
            </h3>{" "}
            <p className="text-grey text-sm">
              Based only on criteria selected, it does not consider expert
              responsiveness or ability to win deals
            </p>
          </div>
          <div tabIndex={0} onBlur={handleBlur} className="relative">
            <BaseUiButton
              onClick={() => {
                setIsOpenManageFieldsDropdown(!isOpenManageFieldsDropdown);
              }}
              label="Manage Fields"
              iconLeft={<IconCog size="sm" />}
            />
            {isOpenManageFieldsDropdown && (
              <div
                id="manage-fields-list"
                className="absolute right-0 rounded-md shadow-lg bg-white z-10 ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="px-1 py-1">
                  {tableFields
                    .filter((i) => !i.shouldHideInManageFields)
                    .map((field) => (
                      <div
                        className="flex justify-between pointer"
                        onClick={() => onToggleManageField(field)}
                        key={field.key}
                      >
                        <span>
                          <button
                            className={
                              "text-gray-700  px-4 py-2 text-md whitespace-nowrap"
                            }
                          >
                            {field.key}
                          </button>
                        </span>
                        <span
                          className={"text-gray-700 block px-4 py-2 text-sm"}
                        >
                          {field.isVisible ? <IconEye /> : <IconEyeOff />}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="border bg-white rounded-md overflow-auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="p-0">
                <LoadingIndicator />
              </TableCell>
              {tableFields
                .filter((i) => i.isVisible)
                .map((item) => (
                  <TableCell
                    className="py-2 cursor-pointer text-nowrap"
                    key={item.key}
                  >
                    <div
                      onClick={() => item.sortable && handleSort(item.sortKey)}
                      onMouseOver={() => {}}
                    >
                      {item.key}{" "}
                      {item.sortable &&
                        sortBy === item.sortKey &&
                        getSortArray(item.sortDirection)}
                    </div>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.length > 0 &&
              sortedData.map((hit) => (
                <Freelancer
                  hit={hit}
                  user={user}
                  proposalId={props.proposalId}
                  freelancers={props.freelancers}
                  key={hit.freelancerId}
                  tableFields={tableFields}
                  serviceLinesPlusOptions={props.serviceLinesPlusOptions}
                  onViewReview={(data) => {
                    setViewRating(!viewRating);
                    setRatingData(data);
                  }}
                />
              ))}
          </TableBody>
        </Table>
      </div>
      <Configure hitsPerPage={hitsPerPage} />
      <div style={{ overflow: "auto" }}>
        <TablePagination
          totalPages={nbPages}
          total={nbHits}
          currentPage={currentRefinement}
          setCurrentPage={(page) => {
            if (page >= 0) {
              refinePage(page);
            }
          }}
          hitsPerPage={hitsPerPage}
          setHitsPerPage={(e) => {
            setHitsPerPage(Number(e));
          }}
        />
      </div>
      {/* Reviews Modal */}
      <Modal
        label=""
        variant={"passive"}
        color="primary"
        open={viewRating}
        onClose={() => setViewRating(false)}
        icon="danger"
        size="md"
      >
        <ReviewsModal freelancer={ratingData} />
      </Modal>
    </>
  );
};

export default FreelancerResultsTable;
