import { FormHelperText } from "@material-ui/core";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function ReactQuillEditor({ value, onChange, error, placeholder = "" }) {
  const modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "direction",
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    // "video",
  ];

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        style={{ direction: "ltr" }}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
}

export default ReactQuillEditor;
