import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import {
  Button,
  Heading,
  Link,
  Text,
  Modal as BaseUiModal,
} from "@paro.io/base-ui";
import { green, grey, yellow } from "@material-ui/core/colors";
import { CheckCircleOutline, ErrorOutline } from "@material-ui/icons";
import { Modal, Spinner } from "react-bootstrap";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { CartContext } from "../components/CartContext";
import FeedbackForm from "../components/FeedbackForm";
import TopSection from "../components/TopSection";
import algoliasearch from "algoliasearch/lite";
import { Auth0Context } from "../contexts/Auth0Context";
import {
  getCartDetails,
  getFindFreelancersCartFn,
  getFLFullProfileViews,
  getFreelancerProfilesByIdsFn,
  getProposal,
  getProposalMmData,
  removeFindFlFromCartFn,
  removeFreelancer,
  removeFromCartFn,
  saveFaFResult,
  submitRecommendations,
  toggleJobBoard,

  updateCartCheckout,
  updateProposedProjectStageCommunicationServiceFn,
} from "../services/apiService";
import {
  getFreelancerDetailsFn,
  addToCommunicationHubFn,
} from "../utils/platformService";
import CustomSpinner from "../components/CustomSpinner";
import { useSnackbar } from "notistack";
import {
  isMM as isMMFn,
  jobBoardOptionChecked,

} from "../data/appConstants";
import FreelancerVote from "../components/FreelancerVote";
import FreelancerCards from "../components/FreelancerCards";
import { getFlsFeedback } from "../utils/mmService";
import Scorecard from "../components/Scorecard";
import ParoError from "../components/ParoError";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
);
const PARTNER_LIST = process.env.REACT_APP_ALGOLIA_PARTNERS_LIST_INDEX_NAME;
const partnerIndex = searchClient.initIndex(PARTNER_LIST);
const IS_PARTNERSHIP_ENABLED = process.env.REACT_APP_PARTNERSHIP_ENABLED === "true";

const useStyles = makeStyles((theme) => ({
  checkout: {
    padding: theme.spacing(2, 5, 2, 5),
  },
  checkedout: {
    padding: theme.spacing(2, 5, 2, 5),
    width: "83.33%",
  },
  warning: {
    margin: theme.spacing(2, 0, 1, 0),
    padding: theme.spacing(1),
    backgroundColor: yellow[300],
  },
  errors: {
    margin: theme.spacing(2, 0, 1, 0),
    padding: theme.spacing(1),
    backgroundColor: yellow[50],
  },
  submitted: {
    margin: theme.spacing(2, 0, 1, 0),
    padding: theme.spacing(1),
    backgroundColor: green[100],
  },
  list: {
    paddingTop: "12px",
  },
  listItem: {
    margin: theme.spacing(0, 0, 0.5, 0),
  },
  button: {
    width: "220px",
    margin: theme.spacing(0, 0, 2, 0),
  },
  modal: {
    "& .modal-dialog": {
      minWidth: "900px",
    },
  },
  cartIcon: {
    height: "50px",
    width: "50px",
  },
  percentage: {
    color: "#059669",
  },
  scoreDiv: {
    fontSize: 14,
    fontWeight: "bold",
    padding: theme.spacing(0),
    cursor: "pointer",
  },
  primaryText: {
    display: "inline-block",
  },
  buttonSize: {
    fontSize: "14px",
    fontWeight: "500",
    height: "34px",
    textTransform: "uppercase",
    padding: theme.spacing(0.5, 1, 0.5, 1),
    margin: theme.spacing(0, 0.25),
    backgroundColor: grey[300],
  },
}));
export default function Checkout(props) {
  const classes = useStyles();
  const { proposalId } = useParams();
  const {
    cartContext,
    globalMatchCartContext,
    proposalContext,
    flViewContext,
    freelancerContext,
    compareFlsContext
  } = useContext(CartContext);
  const [cart, setCart] = cartContext;
  const [, setCompareFls] = compareFlsContext
  const [matchIds, setMatchIds] = globalMatchCartContext;
  const [, setFreelancers] = freelancerContext;
  const [proposalData, setProposalData] = proposalContext;
  const [, setTemplates] = flViewContext;

  const [loadingData, setLoadingData] = useState(true);
  const { user } = useContext(Auth0Context);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [showScorecardModal, setShowScorecardModal] = useState(null);
  const [showFullProfile, setShowFullProfile] = useState(null);
  const [proposal, setProposal] = useState(
    proposalData.filter((p) => p.id === proposalId)
  );
  const { enqueueSnackbar } = useSnackbar();
  const [currentOpportunityType, setCurrentOpportunityType] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [failedFls, setFailedFls] = useState([]);
  const [loadingError, setLoadingError] = useState(false);
  const [partners, setPartners] = useState([]);

  const { firstName, lastName } =
    user[`${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`];
  const userName = firstName + " " + lastName;

  useEffect(() => {
    if (IS_PARTNERSHIP_ENABLED) {
      const partnerTags = proposal?.matchmakingOptions?.tags?.filter(tag => tag.field === "skills")?.map(tag => tag.value) || [];

      if (partnerTags.length > 0 && partners.length === 0) {
        const facetFilters = [partnerTags.map(tag => `partnerTags:${tag}`)] // Or condition
        partnerIndex
          .search("", {
            facetFilters
          })
          .then((res) => {
            setPartners(res.hits);
          })
          .catch(console.error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal]);

  const captureErrors = (res) => {
    const failedDetails = res.reduce((acc, t) => {
      if (t.data.errors) {
        const errorDetails = t.data.errors[0]?.extensions;
        const { freelancerId } = errorDetails;
        const f = cart.find((c) => c.id === freelancerId);
        const reasons = [];

        if (
          errorDetails?.exception?.message ===
          `Unable to mutate Communication is already in progress`
        ) {
          reasons.push("Communication is already in progress");
        } else {
          if (!errorDetails?.isAvailable) {
            reasons.push("Freelancer is Not Available");
          }
          if (!errorDetails?.isNotSuspended) {
            reasons.push("Freelancer is Suspended");
          }
          if (!errorDetails?.statusIsAcceptedOrActive) {
            reasons.push("Freelancer is not in Accepted/Active Status");
          }
        }

        return [
          ...acc,
          {
            freelancerId,
            name: isMM ? f.FL_Name : f.name,
            reasons: reasons.join(" OR "),
          },
        ];
      } else {
        return [...acc];
      }
    }, []);
    setFailedFls(failedDetails);
    return failedDetails;
  };

  const sendDefaultEmailFn = async () => {
    setLoadingButton(true);
    let selectedFreelancersId = [];
    let failedRes = [];

    if (isMM) {
      for (let mId of matchIds.filter((m) => !!m)) {
        const flIDList = cart
          .filter((c) => c.matchId === mId)
          .map((f) => f.flID);
        const requestData = {
          matchId: parseInt(mId),
          proposalId: parseInt(proposalId),
          freelancerId: flIDList,
          reason: "Sending Default Email",
          experienceFitRating: parseInt(0),
          comments: "Skipping Feedback Form",
        };

        console.log("submitRecommendations", "requestData:", requestData);
        await submitRecommendations(requestData);
        selectedFreelancersId = [...selectedFreelancersId, ...flIDList];
      }
      const res = await addToCommunicationHubFn(
        proposalId,
        selectedFreelancersId,
        false,
        "Matching Application",
        true
      );
      failedRes = captureErrors(res);
      setMatchIds([]);
      setFreelancers([]);
    } else {
      selectedFreelancersId = cart.map((c) => c.id);
      const res = await addToCommunicationHubFn(
        proposalId,
        selectedFreelancersId,
        true,
        "Find a Freelancer",
        true
      );
      failedRes = captureErrors(res);
      await saveFaFResult({
        proposalId: parseInt(proposalId),
        freelancerIds: "" + selectedFreelancersId,
        userId: user.auth0UserId,
        opportunityTypeMM: currentOpportunityType,
      });
    }
    await updateCartCheckout(parseInt(proposalId), user.auth0UserId, true);

    selectedFreelancersId = selectedFreelancersId.filter(
      (id) => !failedRes.map((f) => f.freelancerId).includes(id)
    );

    await updateProposedProjectStageCommunicationServiceFn({
      proposalId: +proposalId, // value for 'proposalId'
      freelancerIds: selectedFreelancersId, // value for 'freelancerIds'
      stage: "Proposed",
    });

    enqueueSnackbar("Default Emails sent successfully!", {
      variant: "success",
    });
    if (jobBoardOptionChecked(currentOpportunityType)) {
      await toggleJobBoard(proposalId, true);
      enqueueSnackbar(
        `Proposal ${proposalId} has been posted to the Find Opportunity Page!`,
        {
          variant: "success",
        }
      );
    }

    setLoadingButton(false);
    setCart([]);
    setCompareFls([])
    setSubmitted(true);
    window.open(
      `${process.env.REACT_APP_INTERNAL_PORTAL}/communication-hub/` +
      proposalId,
      "_blank"
    );
  };

  const editbeforeSending = async () => {
    setLoadingButton(true);
    let selectedFreelancersId = [];

    if (isMM) {
      for (let mId of matchIds.filter((m) => !!m)) {
        const flIDList = cart
          .filter((c) => c.matchId === mId)
          .map((f) => f.flID);
        const requestData = {
          matchId: parseInt(mId),
          proposalId: parseInt(proposalId),
          freelancerId: flIDList,
          reason: "Editing Email Template before Sending",
          experienceFitRating: parseInt(0),
          comments: "Skipping Feedback Form",
        };

        console.log("submitRecommendations", "requestData:", requestData);
        await submitRecommendations(requestData);
        selectedFreelancersId = [...selectedFreelancersId, ...flIDList];
      }
      const res = await addToCommunicationHubFn(
        proposalId,
        selectedFreelancersId,
        false,
        "Matching Application"
      );
      captureErrors(res);
      setMatchIds([]);
      setFreelancers([]);
    } else {
      selectedFreelancersId = cart.map((c) => c.id);
      const res = await addToCommunicationHubFn(
        proposalId,
        selectedFreelancersId,
        true,
        "Find a Freelancer"
      );
      captureErrors(res);
      await saveFaFResult({
        proposalId: parseInt(proposalId),
        freelancerIds: "" + selectedFreelancersId,
        userId: user.auth0UserId,
        opportunityTypeMM: currentOpportunityType,
      });
    }
    await updateCartCheckout(parseInt(proposalId), user.auth0UserId, true);

    setLoadingButton(false);
    setCart([]);
    setCompareFls([])
    setSubmitted(true);
    window.open(
      `${process.env.REACT_APP_INTERNAL_PORTAL}/communication-hub/${proposalId}/email-templates`,
      "_blank"
    );
  };

  const removeFromCart = async (freelancer) => {
    const data = {
      proposalId: parseInt(proposalId),
      userId: user.auth0UserId,
      matchId: freelancer.matchId,
      freelancerId: freelancer.flID,
    };
    await removeFromCartFn(data);
    setCart((currentState) => currentState.filter((c) => c.flID !== freelancer.flID));
    enqueueSnackbar(`Removed ${freelancer.FL_Name} from cart!`, {
      variant: "warning",
    });
  };

  const onConfirmRemoveFromCartFn = (data, other = false) => {
    removeFreelancer({
      userId: user.auth0UserId,
      userName,
      freelancerId: data.flID,
      matchId: data.matchId,
      proposalId: parseInt(proposalId),
      reason:
        "" +
        (other
          ? [...data.predefinedReasons, data.otherReason]
          : data.predefinedReasons),
      comments: data.comments,
    })
      .then(() => {
        removeFromCart(data);
      })
      .catch(() => {
        enqueueSnackbar(`Unable to remove Freelancer ${data.flID}!`, {
          variant: "error",
        });

      });
  };

  const handleCloseFeedbackForm = () => {
    setShowFeedbackForm(false);
  };

  const getCartFreelancers = useCallback(
    async (opportunityType) => {
      if (!isMMFn(opportunityType) || process.env.REACT_APP_UNIFIED_MATCHING_ENABLED === 'true') {
        const res1 = await getFindFreelancersCartFn(
          parseInt(proposalId),
          user.auth0UserId
        );
        const cartResponse = res1.data.data.getFindFreelancersCart;
        const cartDetails1 = cartResponse?.map((f) => +f.freelancerId);
        if (cartDetails1?.length > 0) {
          const tempCart1 = [...new Set(cartDetails1)];
          const response = await getFreelancerProfilesByIdsFn(tempCart1);
          setCart(
            response.data.data.getFreelancerProfilesByIds.map((f) => ({
              ...f,
              flID: f.id,
              scores: cartResponse.find((c) => c.freelancerId === f.id)
                .qualityScorecard,
              matchPercent: cartResponse.find((c) => c.freelancerId === f.id)
                .matchPercent,
            }))
          );
          console.log(cartDetails1);
          console.log(response.data.data.getFreelancerProfilesByIds);
        }
      } else {
        const res2 = await getCartDetails(
          parseInt(proposalId),
          user.auth0UserId
        );
        const cartDetails2 = res2.data.data.getCartDetails;
        console.log(cartDetails2);

        if (!cartDetails2) {
          setLoadingData(false);
          return setSubmitted(true);
        }
        const mIds = cartDetails2.map((c) => c.matchId);
        setMatchIds(mIds);
        const propFlData = await Promise.all(
          cartDetails2.map((d) => getFreelancerDetailsFn(d))
        );

        const tempCart2 = await getFlsFeedback(propFlData, mIds);
        setCart(tempCart2);
      }

      const proposalRes = await getProposal(parseInt(proposalId));
      let proposal = proposalRes.data.data.getProposal;
      setProposalData((p) => {
        p.push(proposal);
        return p;
      });
      setProposal(proposal);
      const templates = await getFLFullProfileViews(user.auth0UserId);
      let templatesData = templates.data.data.getFLProfileSettings;
      templatesData.map((t) => (t.fields = JSON.parse(t.fields)));
      setTemplates(templatesData);

      setLoadingData(false);
    },
    [
      proposalId,
      setCart,
      setMatchIds,
      setProposalData,
      setTemplates,
      user.auth0UserId,
    ]
  );

  const getProposalMmDataFn = useCallback(async () => {
    try {
      const {
        data: {
          data: { getProposal: data },
        },
      } = await getProposalMmData(parseInt(proposalId));
      setCurrentOpportunityType(data?.opportunityTypeMM);

      if (!submitted) {
        getCartFreelancers(data?.opportunityTypeMM);
      } else {
        setLoadingData(false);
      }
    } catch (err) {
      setLoadingError(true);
    }
  }, [getCartFreelancers, proposalId, submitted]);

  const removeFindFl = async (freelancer) => {
    await removeFindFlFromCartFn({
      proposalId: +proposalId,
      userId: user.auth0UserId,
      freelancerId: freelancer.id,
    });
    setCart((currentState) =>
      currentState.filter((c) => c.flID !== freelancer.id)
    );
    enqueueSnackbar(`Removed ${freelancer.name} from Cart!`, {
      variant: "warning",
    });
  };
  useEffect(() => {
    getProposalMmDataFn();
  }, [getProposalMmDataFn]);

  const navigateToCommunicationHub = () => {
    window.location.href =
      process.env.REACT_APP_INTERNAL_PORTAL +
      "/communication-hub/" +
      proposalId;
  };

  const isMM =
    process.env.REACT_APP_UNIFIED_MATCHING_ENABLED === "true"
      ? false
      : isMMFn(currentOpportunityType);

  const getTotalScore = (scores) =>
    Math.round(scores?.find((s) => s.label === "Total")?.score);

  if (loadingError) {
    return <ParoError />;
  }

  // If Full Profile is clicked
  if (!loadingData && !submitted && showFullProfile) {
    return (
      <>
        <TopSection
          proposalId={proposalId}
          checkout
          faf={!isMM}
          showFullProfile={showFullProfile}
          goBack={() => {
            setShowFullProfile(null);
          }}
          showCart={true}
        />
        <FreelancerCards
          data={showFullProfile}
          proposalNotes={proposalData.notes}
          proposalId={parseInt(proposalId)}
          tabIndex={0}
        />
      </>
    );
  }

  const handleGenerateEmail = (partner) => {
    const emailLink = `mailto:${"admin@paro.io"}?subject=${encodeURIComponent(partner?.partnerEmail?.email)}&body=${encodeURIComponent(partner?.partnerEmail?.body)}`;
    window.location.href = emailLink;
  };

  return (
    <>
      <TopSection
        proposalId={proposalId}
        checkout
        faf={!isMM}
        showFullProfile={showFullProfile}
        goBack={() => {
          setShowFullProfile(null);
        }}
      />
      {loadingData && <CustomSpinner>Fetching Cart</CustomSpinner>}
      {!loadingData && !submitted && !showFullProfile && (
        <Box className={classes.checkout}>
          <h4>
            You have selected {cart.length} of 8 freelancers for proposal:{" "}
            {proposalId}
          </h4>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              {cart.length > 8 && (
                <Paper className={classes.warning} variant="outlined">
                  <ErrorOutline />
                  <span className="ml-2">
                    Please submit only 8 freelancers at the time
                  </span>
                </Paper>
              )}
              <List className={classes.list}>
                {cart.map((f) => (
                  <Paper className={classes.listItem}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <img src={f.imageUrl} alt="" width="100%" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div className={classes.primaryText}>
                            {isMM ? f.FL_Name : f.name}
                          </div>
                        }
                        secondary={
                          <div>
                            {isMM ? f.FL_Service : f.serviceLine}
                            <Text className={classes.scoreDiv}>
                              <div
                                onClick={() =>
                                  setShowScorecardModal({
                                    scores: f.scores,
                                    source: isMM
                                      ? "Matching Application"
                                      : "Find a Freelancer",
                                    name: isMM ? f.FL_Name : f.name,
                                  })
                                }
                              >
                                {f.matchPercent ? (
                                  <div className={classes.percentage}>
                                    {Math.round(f.matchPercent)}% Match
                                  </div>
                                ) : (
                                  `NA`
                                )}
                              </div>
                            </Text>
                          </div>
                        }
                      />
                      {isMM && (
                        <>
                          <span id="mm-cartFreelancer-fullProfile">
                            <Button
                              label="Full Profile"
                              onClick={() => setShowFullProfile(f)}
                              className={`${classes.buttonSize}`}
                              size="medium"
                            />
                          </span>
                          <FreelancerVote
                            proposalId={parseInt(proposalId)}
                            freelancerId={f.flID}
                          />
                        </>
                      )}
                      <span id="mm-cartFreelancer-remove">
                        <Button
                          label="Remove"
                          onClick={() => {
                            isMM ?
                              onConfirmRemoveFromCartFn(f) : removeFindFl(f);
                          }}
                          className={classes.buttonSize}
                        />
                      </span>
                    </ListItem>
                  </Paper>
                ))}
              </List>
              {IS_PARTNERSHIP_ENABLED && cart.length > 0 && partners.length > 0 && (
                <Box>
                  <Heading size="h4">Service Add-Ons</Heading>
                  <Grid container spacing={3}>
                    {partners.map((partner) => (
                      <Grid item xs={12} md={4} key={partner.objectID}>
                        <div className="p-4 flex flex-col justify-between bg-white shadow-md border rounded" style={{
                          height: "400px", overflowX: "hidden",
                        }}>
                          <div>
                            <img
                              src={partner.partnerLogo}
                              alt={partner.partnerName}
                              style={{ height: "60px", objectFit: "contain" }}
                            />
                            <h4 className="font-bold mt-2 text-sm">
                              {partner.partnerName}
                            </h4>
                            <p className="mt-4">{partner.partnerDescription} </p>
                          </div>
                          <div className="flex w-full justify-center">
                            <Button
                              label="Generate Email"
                              className="mt-4"
                              onClick={() =>
                                handleGenerateEmail(partner)
                              }
                            />
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Grid>
            <Grid item xs={2} justifyContent="left" align="left">
              {loadingButton ? (
                <div className="flex justify-center">
                  <Spinner animation="border" size="sm" />
                </div>
              ) : (
                <>
                  <Heading size="h5">Send Email</Heading>

                  <div className="my-2">
                    <Button
                      onClick={sendDefaultEmailFn}
                      color="success"
                      className="cursor-pointer"
                      label="Send Default Email"
                      disabled={cart.length > 8 || (!isMM && cart.length < 1)}
                      size="sm"
                    />
                  </div>
                  <div className="bg-green p-2">
                    Allow immediate notification to experts with default email
                    template
                  </div>
                  <div className="my-2">
                    <Button
                      onClick={editbeforeSending}
                      color="success"
                      className="cursor-pointer"
                      label="Edit Before Sending"
                      disabled={cart.length > 8 || (!isMM && cart.length < 1)}
                      size="sm"
                    />
                  </div>
                  <div className="bg-green p-2">
                    Allow edits / personalization of email template prior to
                    sending to experts
                  </div>
                </>
              )}
            </Grid>
          </Grid>
          <Modal
            show={showFeedbackForm}
            onHide={handleCloseFeedbackForm}
            // centered
            className={classes.modal}
          >
            <Modal.Header closeButton />
            <FeedbackForm
              proposalId={proposalId}
              closeFeedbackForm={handleCloseFeedbackForm}
              cartSubmitted={setSubmitted}
              // onJobBoard={onJobBoard}
              captureErrors={captureErrors}
            />
          </Modal>
          <BaseUiModal
            open={!!showScorecardModal}
            onClose={() => setShowScorecardModal(null)}
          >
            {getTotalScore(showScorecardModal?.scores) ? (
              <Scorecard freelancer={showScorecardModal} />
            ) : (
              <div className="px-5 py-5">
                <Heading size="h5">{showScorecardModal?.name}</Heading>
                <div className=" bg-white flex flex-row">
                  <div className="w-full py-5">{`Scorecard not available`}</div>
                </div>
              </div>
            )}
          </BaseUiModal>
        </Box>
      )}
      {!loadingData && submitted && !showFullProfile && (
        <>
          <Box className={classes.checkedout}>
            <span>
              Thank you for submitting freelancers for proposal: {proposalId}
            </span>
            <Paper className={classes.submitted} variant="outlined">
              <CheckCircleOutline />
              <span className="ml-2">
                The Experts selected have been added. Continue onto{" "}
                <Link
                  href={
                    process.env.REACT_APP_INTERNAL_PORTAL +
                    "/communication-hub/" +
                    proposalId
                  }
                >
                  Communication Hub
                </Link>{" "}
                to configure and send communications about this proposal to
                selected experts.
              </span>
            </Paper>
            {failedFls.length > 0 && (
              <Paper className={classes.errors} variant="outlined">
                <ErrorOutline />
                <span className="ml-2">
                  Error in adding following Freelancers to Communication Hub
                </span>
                <table className="error-table mx-4 my-4">
                  <thead>
                    <th className="font-bold">ID</th>
                    <th className="font-bold">Name</th>
                    <th className="font-bold">Reason</th>
                  </thead>
                  <tbody>
                    {failedFls.map((failedFl) => (
                      <tr key={failedFl.freelancerId} className="p-2">
                        <td
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_PARO_APP_URL}/internal/expert-profile/${failedFl.freelancerId}`,
                              "_blank"
                            )
                          }
                          className="text-info underline cursor-pointer"
                        >
                          {failedFl.freelancerId}
                        </td>
                        <td>{failedFl.name}</td>
                        <td>{failedFl.reasons}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Paper>
            )}
            {/* // TODO COMM HUB CHANGES */}
            <div id="mm-checkout-communication-hub">
              <Button
                onClick={navigateToCommunicationHub}
                className="mr-2 my-3"
                color="primary"
                label="GO TO COMMUNICATION HUB"
              />
            </div>
            <div id="mm-checkout-home">
              <Link href="/">Back To Home</Link>
            </div>
            <div id="mm-checkout-continue">
              <Link
                href={
                  process.env.REACT_APP_PARO_APP_URL + "/internal/am/proposals"
                }
              >
                Continue with Proposal on Platform
              </Link>
            </div>
          </Box>
        </>
      )}
    </>
  );
}
