// eslint-disable-next-line
export default {
  // default
  default: "#b0b6c2",
  primary: "#53657d",
  secondary: "#d33b29",
  disabled: "#e4e5e4",

  // product
  paroRed: "#d33b29",
  paroSlategray: "#b0b6c2",
  paroDeepblue: "#53657d",
  paroRosegold: "#ebbfb0",
  paroEggshell: "#f5efee",
  paroGreen: "#6DA454",
  textColor: "#333333",
  darkGray: "#9b9b9b",
  lightGray: "#e4e5e4",
  backgroundGray: "#f8f9fa",
  borderGray: "#e9ecef",
  paroBlack: "#333333",
  white: "#ffffff",
  buttonText: "#5D7088",
  altButtonText: "#3A3A3A",
  notice: "#A5A5A5",
  deleteButtonRed: "#A10A28",
  //RGB
  paroRedRBG: "rgb(211, 59, 41)",
  paroGreenRBG: "rgb(109, 164, 84)",
  noticeRBG: "rgb(161, 10, 40)",
  disabledRBG: "rgb(165, 165, 165)",
  alertSuccess: "rgba(0, 0, 0, 0.87)",
  alertError: "rgba(244, 67, 54)",
};
