import React, { useState } from "react";
import { Button } from "@paro.io/base-ui";
import { IconChevronDown, IconChevronUp } from "@paro.io/base-icons";

const MMSelect = ({
  options = [],
  icon,
  handleChange,
  handleIconClick,
  value,
  label,
  disabled = false,
  showConfirmation,
  showConfirmationMsg,
  onYes,
  onNo,
  isLoading
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    setIsOpen(!isOpen);
  };

  const onSelectOption = (e, i, idx) => {
    setIsOpen(!isOpen);
    handleChange && handleChange(e, i, idx);
  };

  const selectLabel =
    Array.isArray(options) &&
    options.length !== 0 &&
    options.find((option) => option.value === value)?.label;

  const onBlurContainer = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  return (
    <div
      className="relative"
      style={{ minWidth: "180px" }}
      onBlur={onBlurContainer}
    >
      <Button
        className="w-full "
        disabled={disabled}
        label={selectLabel ? selectLabel : label}
        type="button"
        onClick={handleClick}
        iconRight={isOpen ? <IconChevronUp /> : <IconChevronDown />}
      ></Button>
      {showConfirmation ? (
        <div className="absolute mt-2 border w-full bg-white rounded-md shadow-lg p-4 !z-10 max-h-80 overflow-x-hidden w-fit flex flex-col gap-2  ">
          <p>{showConfirmationMsg ?? "Are you sure"}</p>
          <div className="flex gap-4">
            <Button label={"Yes"} type="button" isLoading={isLoading} disabled={isLoading} color="danger" onClick={onYes} />
            <Button label={"No"} disabled={isLoading} type="button" onClick={onNo} />
          </div>
        </div>
      ) : (
        <>
          {isOpen && (
            <ul
              className="absolute mt-2 border w-full bg-white rounded-md shadow-lg p-2  !z-10 max-h-80 overflow-x-hidden w-fit"
              style={{ zIndex: 10 }}
            >
              {options?.length === 0 ? (
                <li className="text-center text-gray-400 text-md ">
                  No options available
                </li>
              ) : (
                options.map((i, idx) => (
                  <li
                    key={i.label}
                    onKeyDown={() => { }}
                    className={`text-sm md:text-md cursor-pointer p-2 hover:bg-gray-100 rounded-md flex justify-between items-center `}
                    onClick={(e) => onSelectOption(e, i, idx)}
                  >
                    {i.label}{" "}
                    {icon && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleIconClick(e, i, idx);
                        }}
                        onKeyDown={() => { }}
                      >
                        {icon}
                      </div>
                    )}
                  </li>
                ))
              )}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default MMSelect;
