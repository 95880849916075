import * as React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
const options = [
  'Edit Project'

]

const ITEM_HEIGHT = 48

interface ProjectMenuProps {
    projectId: number
}

export const ProjectMenu = ({ projectId }: ProjectMenuProps) => {
  const [ 
    anchorEl,
    setAnchorEl 
  ] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    window.open(`${process.env.REACT_APP_INTERNAL_PORTAL_URL}/projects/${projectId}/edit`)
  }

  return (
    <div>
        
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls='long-menu'
        style={{ padding: '4px' }}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        < MoreVertIcon style={{ color: 'black' }}/>
      </IconButton>
      
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {options.map((option) => {
return (
  <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
    {option}
  </MenuItem>
        )
})}
      </Menu>
    </div>
  )
}


