import { Heading } from '@paro.io/base-ui'
import React from 'react'

export default function Scorecard({ freelancer }: any) {
    const { scores, source, name } = freelancer

    return (
        <div className="px-5 py-5">
            <Heading size="h5">{name}</Heading>
            <div className=" bg-white flex flex-row">
                <div className="w-full py-5">
                    <>
                        <Heading size="h4">{source === 'Matching Application' ? 'Match Scorecard' : 'Quality Scorecard'}</Heading>
                        <div className="table w-full border-t border-l border-r border-solid rounded-t-lg text-gray-900 border-gray-300">
                            <div className="table-header-group bg-gray-200">
                                <div className="table-row">
                                    <div className="table-cell w-1/2 p-2 rounded-tl-lg text-gray-900 font-bold border-r border-gray-50">
                                        <div className="flex justify-between">
                                            <span className="font-bold">Match Item</span>
                                        </div>
                                    </div>
                                    <div className="table-cell w-1/2 p-2 rounded-tr-lg text-gray-900 font-bold">
                                        <span className="font-bold">Points</span>
                                    </div>
                                </div>
                            </div>
                            {scores
                                .slice(0)
                                .reverse()
                                .map((s: any) => (
                                    <div className="table-row" key={s.label}>
                                        <div className="table-cell border-b border-r border-slate-100 dark:border-slate-700 p-2 text-gray-900 dark:text-gray-400">
                                            <span className={s.label === `Total` ? `font-bold` : ``}>{s.label}</span>
                                        </div>
                                        <div className="table-cell border-b border-slate-100 dark:border-slate-700 p-2 text-gray-900 dark:text-gray-400">
                                            <span className={s.label === `Total` ? `font-bold` : ``}>
                                                {`${Math.round(s.score)}/${s.maxScore}`}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                </div>
            </div>
        </div>
    )
}
