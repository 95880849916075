import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Sidebar from "./Sidebar";
import AiSearchResults from "./AiSearchResults";
import FreelancerResultsTable from "./FreelancerResultsTable";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import { getFreelancerDetailsFn } from "../../utils/platformService";
import {
  buildRecommendationsRequest,
  formatHardTags,
  transformProposalData,
} from "../../utils/freelancerSearchService";
import { runMatch, updateProposalMatchCount } from "../../services/apiService";
import { Auth0Context } from "../../contexts/Auth0Context";
import { useTags } from "../../contexts/useTags";
import { useSnackbar } from "notistack";
import { CartContext } from "../CartContext";
import { MatchContext } from "../MatchContext";
import ErrorBoundary from "./ErrorBoundary";
import FreelancerComparePage from "../../pages/FreelancerCompare";
import { MatchResultsContext } from "../../contexts/MatchResultsContext";

/**-----------algolia config----------*/

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
);
const Freelancers_list =
  process.env.REACT_APP_ALGOLIA_FREELANCERS_LIST_INDEX_NAME ??
  "freelancer-list-dev";

/**------------------------------------- */

const FreelancerSearch = ({ proposalDetails }) => {
  const [isMatchLoading, setIsMatchLoading] = useState(false);
  const { user } = useContext(Auth0Context);
  const {
    iterationsContext,
    globalMatchCartContext,
    matchContext,
    compareFlsContext,
  } = useContext(CartContext);
  const [, setIterations] = iterationsContext;
  const [, setMatchIds] = globalMatchCartContext;
  const [, setMatchId] = matchContext;
  const [compareFls] = compareFlsContext;
  const [, setMatchInputs] = useContext(MatchContext)?.matchInputsContext;
  const [matchQuery] = useContext(MatchResultsContext)?.matchQueryContext;
  const [matchResults, setMatchResults] =
    useContext(MatchResultsContext)?.matchResultsContext;
  const [showCompareAll, setShowCompareAll] = useState(false);
  const [compareType, setCompareType] = useState(null);

  const { serviceLinesPlusOptions } = useTags();
  const { enqueueSnackbar } = useSnackbar();
  const { proposalId } = useParams();

  // useEffect(() => {
  //   if (proposalDetails?.id) {
  //     setMatchQuery({
  //       ...(transformProposalData(proposalDetails) ?? {}),
  //       ...matchQuery,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [proposalDetails?.id]);
  const setFreelancerDetailsFn = async (data) => {
    try {
      setMatchId(data.matchId);
      setMatchIds((currentState) => [...currentState, data.matchId]);
      const propFlData = await getFreelancerDetailsFn(data);
      setMatchResults(propFlData);

      if (propFlData.length > 0) {
        await updateProposalMatchCount(
          parseInt(proposalId),
          user.auth0UserId,
          "increment"
        );
        setIterations((iterations) => ++iterations);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const refreshCart = () => {
    setMatchId(null);
    setMatchResults([]);
  };

  const onRunMatch = async (data) => {
    try {
      refreshCart();

      const requestData = buildRecommendationsRequest(
        {
          ...data,
          serviceLine:
            typeof data.serviceLine === "string"
              ? data.serviceLine
              : formatHardTags(data.serviceLine),
          activeStatus:
            typeof data.activeStatus === "string"
              ? data.activeStatus
              : formatHardTags(data.activeStatus),
        },
        serviceLinesPlusOptions,
        user
      );
      setMatchInputs(requestData);
      setIsMatchLoading(true);

      const { data: responseData } = await runMatch(requestData);

      if (
        responseData?.errors &&
        responseData?.errors[0]?.extensions?.code === "400"
      ) {
        setIsMatchLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
        setFreelancerDetailsFn({ matchId: null, recommendedFreeLancers: [] });
        return;
      }

      // TODO: Update proposal

      setMatchId(responseData?.data?.updateRecommendations.matchId);
      setMatchIds((currentState) => [
        ...currentState,
        responseData?.data?.updateRecommendations?.matchId,
      ]);

      await setFreelancerDetailsFn(responseData?.data?.updateRecommendations);

      setIsMatchLoading(false);

      window.sessionStorage.setItem(
        "matchContext",
        JSON.stringify({
          mId: responseData.data.updateRecommendations.matchId,
          pId: proposalDetails.id,
        })
      );
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      setFreelancerDetailsFn({ matchId: null, recommendedFreeLancers: [] });
      return;
    }
  };

  if (showCompareAll)
    return (
      <>
        <FreelancerComparePage
          freelancers={compareType === "selected" ? compareFls : matchResults}
          matchFormData={
            Object.keys(matchQuery).length
              ? matchQuery
              : transformProposalData(proposalDetails) ?? {}
          }
          compareAll
          setShowCompareAll={setShowCompareAll}
          um
        />
      </>
    );

  return (
    <ErrorBoundary>
      <InstantSearch searchClient={searchClient} indexName={Freelancers_list}>
        <Grid
          container
          spacing={4}
          className="w-full overflow-auto !flex-nowrap "
        >
          <Sidebar
            onMatch={onRunMatch}
            isMatchLoading={isMatchLoading}
            proposalDetails={
              Object.keys(matchQuery).length
                ? matchQuery
                : transformProposalData(proposalDetails) ?? {}
            }
          />
          <Grid
            item
            xs={12}
            md={9}
            style={{ maxHeight: "81vh" }}
            className="overflow-x-hidden"
            id="instant-search-container"
          >
            <AiSearchResults
              freelancers={matchResults}
              isMatchLoading={isMatchLoading}
              proposalId={proposalDetails?.id}
              setShowCompareAll={setShowCompareAll}
              setCompareType={setCompareType}
            />
            <FreelancerResultsTable
              proposalId={proposalDetails?.id}
              freelancers={matchResults}
              proposalDetails={proposalDetails}
              serviceLinesPlusOptions={serviceLinesPlusOptions}
            />
          </Grid>
        </Grid>
      </InstantSearch>
    </ErrorBoundary>
  );
};

export default FreelancerSearch;
