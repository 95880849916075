import {
  getAvailability,
  getExpertsByLegacyFreelancerIds,
  getFreelancersOtherDetails,
  getFreelancerDetails,
  getFreelancerUserInfo,
  getParoProjects,
  getVettingNotes,
  addToCommunicationHub,
} from "../services/apiService";

export const getFreelancerDetailsFn = async (data) => {
  const { matchId, recommendedFreeLancers = [] } = data;
  const flIDList = recommendedFreeLancers.map((d) => d.flID);
  const response = await Promise.all([
    getExpertsByLegacyFreelancerIds(flIDList),
    getFreelancersOtherDetails(flIDList),
  ]);

  const expertsArray = flIDList.map((id) =>
    response[0].data.data.getExpertsByLegacyFreelancerIds.find(
      (ele) => id === ele.legacyFreelancerId
    )
  );
  
  const responseArray = expertsArray.map(function (e, i) {
    return [e, response[1].data.data.getFreelancersOtherDetails[i]];
  });

  const flData = responseArray.reduce((acc, cur) => {
    if (
      cur[0] &&
      cur[1].freelancerCandidateNotes &&
      cur[1].freelancerProfile &&
      cur[1].projects &&
      cur[1].userInfo
    ) {
      return [
        ...acc,
        {
          FL_Name: cur[1].userInfo.firstName + " " + cur[1].userInfo.lastName,
          FL_Service: cur[0].legacyMetadata?.primaryServiceLine,
          FL_Tagline: cur[0].personalSummary?.whyFreelanceWork,
          Recent_Experience: cur[0].workExperiences[0]?.description,
          Short_Bio: cur[0].personalSummary?.aboutYourself,
          Vetting_Notes: cur[1].freelancerCandidateNotes,
          paroProjects: cur[1].projects,
          availability: cur[1].freelancerProfile,
          ...cur[0],
          ...cur[1].userInfo,
          feedback: 0,
          matchId: matchId,
        },
      ];
    } else {
      return [...acc];
    }
  }, []);

  const propFlData = flData.map((f) => {
    const rf = recommendedFreeLancers.find((d) => d.flID === f.id);
    return {
      ...rf,
      ...f,
    };
  });
  // console.log(propFlData);
  return propFlData;
};

export const getFreelancerDetailsById = async (flID) => {
  const response = await Promise.all([
    getFreelancerDetails(flID),
    getFreelancerUserInfo(flID),
    getVettingNotes(flID),
    getParoProjects(flID),
    getAvailability(flID),
  ]);

  return {
    FL_Name:
      response[1].data.data.getUserInfo.firstName +
      " " +
      response[1].data.data.getUserInfo.lastName,
    FL_Service:
      response[0].data.data.getExpertByLegacyFreelancerId.legacyMetadata
        ?.primaryServiceLine,
    FL_Tagline:
      response[0].data.data.getExpertByLegacyFreelancerId.personalSummary
        ?.whyFreelanceWork,
    Recent_Experience:
      response[0].data.data.getExpertByLegacyFreelancerId.workExperiences[0]
        ?.description,
    Short_Bio:
      response[0].data.data.getExpertByLegacyFreelancerId.personalSummary
        ?.aboutYourself,
    Vetting_Notes: response[2].data.data.getFreelancerCandidateNotes,
    paroProjects: response[3].data.data.getParoProjects,
    availability: response[4].data.data.getFreelancerProfile,
    ...response[0].data.data.getExpertByLegacyFreelancerId,
    ...response[1].data.data.getUserInfo,
    feedback: 0,
    matchId: null,
  };
};

export const addToCommunicationHubFn = async (
  proposalId,
  freelancerIds,
  overrideFromMM,
  source,
  sendDefaultEmail
) => {
  let emailRequest = {
    proposalId: parseInt(proposalId),
    stage: "New",
    sendFlInvite: true,
    overrideFromMM,
    source,
    sendDefaultEmail: sendDefaultEmail,
  };

  return await Promise.all(
    freelancerIds.map((flID) => {
      emailRequest = { ...emailRequest, freelancerId: parseInt(flID) };
      return addToCommunicationHub(emailRequest);
    })
  );
};

export const convertLetterUrlToAuthUrl = (letterUrl) => {
  const encodedOrgName = encodeURIComponent("Paro AI");
  const encodedReturnUrl = Buffer.from(letterUrl).toString('base64');
  const finalUrl = `https://auth.letter.ai/api/fe/v3/login/saml?org_name=${encodedOrgName}&rt=${encodedReturnUrl}`;
  return finalUrl
}