// eslint-disable-next-line
export default {
  New: -1,
  Proposed: 0,
  Interested: 1,
  ClientProposal: 2,
  IntroEmail: 3,
  SowSubmitted: 4,
  SowAccepted: 5,
  ClosedWon: 91,
  ClientRejected: 92,
  FlRejected: 93,
  ProposalArchived: 94,
  Expired: 95,
};
