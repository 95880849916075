import React from "react";
import {
  Box,
  Button,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";

import colors from "../shared/constants/colors";
import TopSection from "./TopSection";

type SectionContents = {
  handleClose?: (...args) => void;
  formError?: boolean;
};
const ErrorBox: typeof Box = styled(Box)`
  width: 100%;
  padding-bottom: 100;
`;

const ParoTitle: typeof Box = styled(Box)`
  color: ${colors.paroBlack};
  padding: 0px 0px 18px 0px;
`;

const ParoSubtitle = styled.div`
  color: ${colors.paroBlack};
  margin-bottom: 24px;
  font-size: 1rem;
`;
const reloadWindow = () => {
  window.location.reload();
};

const ParoError = ({ handleClose }: SectionContents): JSX.Element => {
  const handleCancel = handleClose ? handleClose : reloadWindow;
  const handleCancelLanguage = handleClose ? "Close" : "Retry";
  const ErrorBoxComponent = () => {
    return (
      <ErrorBox>
        <Box
          display="flex"
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          padding="100px 0px 36px 5px;"
        >
          <Typography variant="h6" gutterBottom>
            <ParoTitle>Oops! We had a misstep.</ParoTitle>
          </Typography>
          <Typography component={"span"} gutterBottom>
            <ParoSubtitle>Contact support if the issue persists</ParoSubtitle>
          </Typography>
          <DialogActions>
            <Button variant="outlined" onClick={handleCancel} color="primary">
              {handleCancelLanguage}
            </Button>
          </DialogActions>
        </Box>
      </ErrorBox>
    );
  };

  return (
    <>
      <TopSection />
      <Grid container item justify="center">
        <ErrorBoxComponent />
      </Grid>
    </>
  );
};

export default ParoError;
