import React from 'react'
import { Grid, makeStyles, TablePagination } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import dayjs from 'dayjs'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown' ;
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp' ;
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles({
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    border: '1px solid #E2E8F0',
    padding: '10px'
  },
  tableHead: {
    maxWidth: 125,
    minWidth: 125,
    padding: 0,
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    textAlign: 'center'
  },
  tableRow: {
    height: 40
  },
  container: {
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 50,
    padding: 10,
    margin: 10,
  },
  container2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableCellBorder: {
    whiteSpace: 'nowrap',
    border: '1px solid #E2E8F0'
  },
  shade: {
    backgroundColor: '#F1F5F9'
  },
  collapseButton: {
    border: '1px solid #000000',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
  },
  pagination: {
    display:'flex',
    justifyContent: 'left',
    width: "100%",
    alignItems: 'left',
    padding:'0px',
    },
})

export const ClosedProjectsTable = (props) => {
  const { closedProjects } = props
  const classes = useStyles()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  
  if (closedProjects.length <= 0) {
    return <div>No Closed projects available</div>
  }
    const closedProjectData: any[] = closedProjects.map((project) => {
        return {
        projectId: project.id,
        projectName: project.name,
        clientName: project?.client?.name,
        billType: project.billRateType,
        startDate: project.startDate,
        endDate: project.endDate,
        ae: project?.salesRep?.name,
        am: project?.accountManager?.name,
        projectStatus: project.status
        }
    })
    
    const tableData: any[] = closedProjectData
    return (
        <>
        <Paper className={classes.container}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.tableRow}>
                <TableCell className={[classes.tableCellBorder, classes.shade].join(" ")} colSpan={12}>
                <Grid container item className={classes.container2}>
                <b>Closed Projects</b>
                <Box className={classes.collapseButton} >
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Box>
                </Grid>
                </TableCell>
            </TableRow>
          </TableHead>
            <Collapse in={!open} timeout="auto" unmountOnExit>
              <Table>
                <TableHead className={classes.tableHead}>
                  <TableRow className={[classes.tableCellBorder, classes.shade].join(" ")}>
                    <TableCell className={classes.tableCell}></TableCell>
                    <TableCell className={classes.tableCell}>
                    <b>Project Name</b>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                    <b>Client Name</b>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                    <b>Date</b>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                    <b>Project Status</b>
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}>
                    <b>Reason</b>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
              <TableBody>
              {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const {
                  projectName,
                  clientName,
                  endDate,
                  projectStatus,
                  } = row
                  return (
                  <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>{page*rowsPerPage + index+1}</TableCell>
                      <TableCell className={classes.tableCell}>{projectName}</TableCell>
                      <TableCell className={classes.tableCell}>{clientName}</TableCell>
                      <TableCell className={classes.tableCell}>{endDate ? dayjs(endDate).format('MM-DD-YYYY') : '-'}</TableCell>
                      <TableCell className={classes.tableCell}>{projectStatus}</TableCell>
                      {/* <TableCell className={classes.tableCell}>{reason ? reason : '-'}</TableCell> */}
                  </TableRow>
                  )
              })}
              </TableBody>
              </Table>
              <TablePagination
                className={classes.pagination}
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Collapse>
        </Table>
        </Paper>
        </>
    )
}