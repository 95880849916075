import React, { useState } from "react";
import { Grid, Switch } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TimeManagementSection from "./TimeManagementSection";
import { AvailabilityType } from "../../TabNavigationBar";
import CalculationSection from "./CalculationSection";
import { GanttChart } from "./GanttChart";
import { ActiveProjectsTable } from "./ActiveProjectsTable";
import { ClosedProjectsTable } from "./ClosedProjectsTable";
import {
  useGetFreelancerAvailabilityQuery,
  useGetParoProjectsForAvailabilityQuery,
  useGetProjectEstimatesQuery,
  useGetProposalEstimatesQuery,
  useGetProposedProjectsQuery,
  useGetClosedParoProjectsQuery,
} from "../../../apolloClient/hooks/queries";
import Loader from "../../../_global/Loader";
import { ProposalsTable } from "./ProposalsTable";
import proposedProjectStages from "../../../shared/constants/proposedProjectStages";
import { uniq } from "lodash";
import omit from "lodash/omit";
import styled from "styled-components";
import colors from "../../../shared/constants/colors";
import HelpOutline from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import { styled as styledMUI } from "@material-ui/core/styles";
import ServiceLinesPlus from "../../ServiceLinesPlus";
import { convertLetterUrlToAuthUrl } from "../../../utils/platformService";

type SectionContents = {
  monthlyHoursAvailable: ExpertLegacyMetadata["monthlyHoursAvailable"];
  availabilityCalculations: AvailabilityType;
  legacyFreelancerId: number | undefined;
  serviceLinesPlus?: string[];
};
export const SectionTitle = styled((props) => {
  return <div {...omit(props, SectionTitle.OmitProps)} />;
})`
  font-family: Roboto-Bold;
  font-size: 16px;
  color: ${colors.paroBlack};
  padding: 11.5px 0px 7.75px 2px;
  ${({ noBottomPadding }) => {
    return noBottomPadding && "padding-bottom: 0 !important;";
  }}
`;
const GreenSwitch = styledMUI(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#248384",
    "&:hover": {
      backgroundColor: `rgba(36, 131, 132, ${theme.palette.action.hoverOpacity})`,
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#248384",
  },
}));

const FreelancerAvailability = ({
  legacyFreelancerId,
  availabilityCalculations,
  serviceLinesPlus,
}: SectionContents): JSX.Element => {
  const { projectWinRate } = availabilityCalculations!;
  const freelancerId = legacyFreelancerId as number;
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const currMonth = `${year}-${month}-01`;
  const nxtMonth = ("0" + (date.getMonth() + 2)).slice(-2);
  const nextMonth = `${year}-${nxtMonth}-01`;
  const isLetterLinksEnabled = process.env.REACT_APP_WORKRAMP_TRANSITION_TO_LETTER_ENABLED === 'true';
  const availabilityHelperLink = isLetterLinksEnabled ? convertLetterUrlToAuthUrl('https://app.letter.ai/preview-content?contentId=8d989141-607b-4929-97fb-32e7b3b64b37&scope=public') : 'https://expertacademy.paro.io/resources/237187fa-4005-11ed-a0d4-06c0361096e5'
  const [isTotalHoursOn, setIsTotalHoursOn] = useState(true);
  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTotalHoursOn(event.target.checked);
  };

  const { data: availabilityData, loading: availabilityLoading } =
    useGetFreelancerAvailabilityQuery({
      variables: {
        freelancerId,
        month: currMonth,
      },
    });

  const {
    data: nextMonthAvailabilityData,
    loading: nextMonthAvailabilityLoading,
  } = useGetFreelancerAvailabilityQuery({
    variables: {
      freelancerId,
      month: nextMonth,
    },
  });

  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useGetParoProjectsForAvailabilityQuery({
    variables: {
      legacyFreelancerId: freelancerId,
    },
  });

  const { data: closedProjectsData, loading: closedProjectsLoading } =
    useGetClosedParoProjectsQuery({
      variables: {
        legacyFreelancerId: freelancerId,
      },
    });

  //@ts-ignore
  const {
    data: proposalsData,
    loading: proposalsLoading,
    error: proposalsError,
  } = useGetProposedProjectsQuery({
    variables: {
      freelancerId: freelancerId,
      //@ts-ignore
      proposalId: null,
    },
  });

  const { data: projectEstimatesData, loading: projectEstimatesLoading } =
    useGetProjectEstimatesQuery({
      variables: {
        freelancerId,
      },
    });

  const { data: proposalEstimatesData, loading: proposalEstimatesLoading } =
    useGetProposalEstimatesQuery({
      variables: {
        freelancerId,
      },
    });

  if (projectsError || proposalsError) {
    let error;
    if (projectsError) {
      error = projectsError;
    } else {
      error = proposalsError;
    }
    return <div>{`Error loading freelancer availability: ${error}`}</div>;
  }

  if (
    availabilityLoading ||
    projectsLoading ||
    proposalsLoading ||
    projectEstimatesLoading ||
    proposalEstimatesLoading ||
    nextMonthAvailabilityLoading ||
    closedProjectsLoading
  ) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const availabilityHours: any =
    availabilityData?.getFreelancerAvailability || {};
  const nextMonthAvailabilityHours: any =
    nextMonthAvailabilityData?.getFreelancerAvailability || {};
  const projectEstimates: any = projectEstimatesData?.getProjectEstimates || [];
  const proposalEstimates: any =
    proposalEstimatesData?.getProposalEstimates || [];

  const projectsRaw: any = projectsData?.getParoProjects || [];
  const proposalsRaw: any = proposalsData?.getProposedProjects || [];
  const closedProjects: any = closedProjectsData?.getClosedParoProjects || [];

  const projects = projectsRaw.filter(
    (project) => project?.status === "Active"
  );
  const proposals = proposalsRaw.filter(
    (proposal) =>
      proposedProjectStages[proposal.proposedProjectStage] <= 5 &&
      proposedProjectStages[proposal.proposedProjectStage] !== 0
  );

  const proposalEstimatesMap = {};
  for (let proposalEstimate of proposalEstimates) {
    if (proposalEstimate.month === currMonth) {
      if (isTotalHoursOn) {
        proposalEstimatesMap[proposalEstimate.proposalId] = +(
          (proposalEstimatesMap[proposalEstimate.proposalId] || 0) +
          proposalEstimate.trueProposalHours
        ).toFixed(2);
      } else {
        proposalEstimatesMap[proposalEstimate.proposalId] = +(
          (proposalEstimatesMap[proposalEstimate.proposalId] || 0) +
          proposalEstimate.estimatedProposalHours
        ).toFixed(2);
      }
    }
  }

  const nextMonthProposalEstimatesMap = {};
  for (let proposalEstimate of proposalEstimates) {
    if (proposalEstimate.month === nextMonth) {
      nextMonthProposalEstimatesMap[proposalEstimate.proposalId] = +(
        (nextMonthProposalEstimatesMap[proposalEstimate.proposalId] || 0) +
        proposalEstimate.estimatedProposalHours
      ).toFixed(2);
    }
  }

  const projectEstimatesMap = {};
  for (let projectEstimate of projectEstimates) {
    if (projectEstimate.month === currMonth) {
      if (isTotalHoursOn) {
        projectEstimatesMap[projectEstimate.projectId] = +(
          (projectEstimatesMap[projectEstimate.projectId] || 0) +
          projectEstimate.trueProjectHours
        ).toFixed(2);
      } else {
        projectEstimatesMap[projectEstimate.projectId] = +(
          (projectEstimatesMap[projectEstimate.projectId] || 0) +
          projectEstimate.estimatedProjectHours
        ).toFixed(2);
      }
    }
  }

  const totalHoursWorkedOutOfExpectedMap = {};
  for (let projectEstimate of projectEstimates) {
    if (projectEstimate.month === currMonth) {
      totalHoursWorkedOutOfExpectedMap[
        projectEstimate.projectId
      ] = `${projectEstimate.projectActualHours.toFixed(
        0
      )} / ${projectEstimate.totalMonthHoursExpected.toFixed(0)}`;
    }
  }

  const nextMonthprojectEstimatesMap = {};
  for (let projectEstimate of projectEstimates) {
    if (projectEstimate.month === nextMonth) {
      nextMonthprojectEstimatesMap[projectEstimate.projectId] = +(
        (nextMonthprojectEstimatesMap[projectEstimate.projectId] || 0) +
        projectEstimate.estimatedProjectHours
      ).toFixed(2);
    }
  }

  const bookedHours =
    availabilityHours.booked || availabilityHours.booked === 0
      ? availabilityHours.booked.toFixed(2)
      : "-";
  const estimatedProjectHours =
    availabilityHours.estimatedProjectHours ||
    availabilityHours.estimatedProjectHours === 0
      ? availabilityHours.estimatedProjectHours.toFixed(2)
      : "-";
  const pendingHours =
    availabilityHours.pending || availabilityHours.pending === 0
      ? availabilityHours.pending.toFixed(2)
      : "-";
  const estimatedProposalHours =
    availabilityHours.estimatedProposalHours ||
    availabilityHours.estimatedProposalHours === 0
      ? availabilityHours.estimatedProposalHours.toFixed(2)
      : "-";
  const estimatedAvailability =
    availabilityHours.estimatedAvailability ||
    availabilityHours.estimatedAvailability === 0
      ? +availabilityHours.estimatedAvailability.toFixed(2)
      : "-";
  const availability =
    availabilityHours.availability || availabilityHours.availability === 0
      ? +availabilityHours.availability.toFixed(2)
      : "-";
  const isOverbooked =
    ((isTotalHoursOn
      ? availabilityHours.availability
      : availabilityHours.estimatedAvailability) || 0) < 0;
  const trueAvailabilityLabel = !isOverbooked
    ? "hours/month"
    : "hours overbooked this month";
  const goalHours =
    availabilityHours.goal || availabilityHours.goal === 0
      ? availabilityHours.goal.toFixed(2)
      : "-";
  const monthlyHoursAvailable =
    availabilityHours.monthlyHoursAvailable ||
    availabilityHours.monthlyHoursAvailable === 0
      ? availabilityHours.monthlyHoursAvailable.toFixed(2)
      : "-";
  const totalPreStage4Proposals = proposals.reduce(
    (acc, proposal) =>
      proposedProjectStages[proposal.proposedProjectStage] < 4 ? acc + 1 : acc,
    0
  );
  const totalPostStage4Proposals = proposals.reduce(
    (acc, proposal) =>
      proposedProjectStages[proposal.proposedProjectStage] >= 4 ? acc + 1 : acc,
    0
  );
  const totalProposalHours = proposals
    .reduce(
      (acc, proposal) =>
        acc + (proposalEstimatesMap[proposal?.proposal?.id] || 0),
      0
    )
    .toFixed(2);
  const totalProjectHours = projects
    .reduce((acc, project) => acc + (projectEstimatesMap[project.id] || 0), 0)
    .toFixed(2);
  const totalProjectClients = uniq(
    projects.map((project) => project?.client?.name)
  ).length;
  const nextMonthBookedHours =
    nextMonthAvailabilityHours.estimatedProjectHours ||
    nextMonthAvailabilityHours.estimatedProjectHours === 0
      ? nextMonthAvailabilityHours.estimatedProjectHours.toFixed(2)
      : "-";
  const nextMonthPendingHours =
    nextMonthAvailabilityHours.estimatedProposalHours ||
    nextMonthAvailabilityHours.estimatedProposalHours === 0
      ? nextMonthAvailabilityHours.estimatedProposalHours.toFixed(2)
      : "-";
  const nextMonthTrueAvailability =
    nextMonthAvailabilityHours.estimatedAvailability ||
    nextMonthAvailabilityHours.estimatedAvailability === 0
      ? +nextMonthAvailabilityHours.estimatedAvailability.toFixed(2)
      : "-";
  const nextMonthGoalHours =
    nextMonthAvailabilityHours.monthlyHoursAvailable ||
    nextMonthAvailabilityHours.monthlyHoursAvailable === 0
      ? nextMonthAvailabilityHours.monthlyHoursAvailable.toFixed(2)
      : "-";

  const proposalsFieldsAndValues = [
    {
      field: "Pre-SOW",
      value: totalPreStage4Proposals,
    },
    {
      field: "SOW In-Process",
      value: totalPostStage4Proposals,
    },
    {
      field: "Monthly Hours Estimated",
      value: totalProposalHours,
    },
  ];

  const activeFieldsAndValues = [
    {
      field: "No. Clients",
      value: totalProjectClients,
    },
    {
      field: "No. Projects",
      value: projects.length,
    },
    {
      field: "Monthly Hours Projected",
      value: totalProjectHours,
    },
  ];

  const goalFieldsAndValues = {
    field: "Goal",
    value: isTotalHoursOn ? goalHours : monthlyHoursAvailable,
    nextMonthValue: nextMonthGoalHours,
    currentMonthLabel:
      "Current Month" + (isTotalHoursOn ? " (Total Hours)" : " (Pro-Rated)"),
    nextMonthLabel:
      "Next Month" + (isTotalHoursOn ? " (Total Hours)" : " (Total Hours)"),
    unit: "hours/month",
  };

  const bookedFieldsAndValues = {
    field: "Booked",
    value: isTotalHoursOn ? bookedHours : estimatedProjectHours,
    nextMonthValue: nextMonthBookedHours,
    currentMonthLabel:
      "Current Month" + (isTotalHoursOn ? " (Total Hours)" : " (Pro-Rated)"),
    nextMonthLabel:
      "Next Month" + (isTotalHoursOn ? " (Total Hours)" : " (Total Hours)"),
    unit: "hours/month",
  };
  const pendingFieldsAndValues = {
    field: "Pending",
    value: isTotalHoursOn ? pendingHours : estimatedProposalHours,
    nextMonthValue: nextMonthPendingHours,
    currentMonthLabel:
      "Current Month" + (isTotalHoursOn ? " (Total Hours)" : " (Pro-Rated)"),
    nextMonthLabel:
      "Next Month" + (isTotalHoursOn ? " (Total Hours)" : " (Total Hours)"),
    unit: "hours/month",
  };
  const trueAvailibilityFieldsAndValues = {
    field: "Availability",
    value: isTotalHoursOn ? availability : estimatedAvailability,
    nextMonthValue: nextMonthTrueAvailability,
    currentMonthLabel:
      "Current Month" + (isTotalHoursOn ? " (Total Hours)" : " (Pro-Rated)"),
    nextMonthLabel:
      "Next Month" + (isTotalHoursOn ? " (Total Hours)" : " (Total Hours)"),
    unit: trueAvailabilityLabel,
  };

  return (
    <>
      <Grid container>
        <ServiceLinesPlus
          serviceLinesPlus={serviceLinesPlus}
          freelancerId={legacyFreelancerId}
        />
        <Grid
          container
          alignItems="flex-end"
          justify="flex-start"
          direction="column"
        >
          <></>
          <Box
            style={{
              border: "1px solid #000000",
              backgroundColor: "#ffffff",
              borderRadius: "5px",
            }}
          >
            <IconButton
              size="small"
              onClick={() =>
                window.open(
                  availabilityHelperLink
                )
              }
            >
              {<HelpOutline />}
            </IconButton>
          </Box>
          <div
            style={{
              display: "flex",
              paddingTop: "30px",
              justifyContent: "flex-end",
            }}
          >
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              style={{ cursor: "pointer" }}
            >
              <Grid item>Pro-Rated</Grid>
              <Grid item>
                <GreenSwitch checked={isTotalHoursOn} onChange={handleToggle} />
              </Grid>
              <Grid item>Total Hours</Grid>
            </Grid>
          </div>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="stretch"
            justify="center"
          >
            <Grid item xs={10} md={12}>
              <Grid
                container
                direction="row"
                spacing={1}
                alignItems="stretch"
                justify="center"
              >
                <Grid item xs={12} md={6}>
                  <SectionTitle>Active</SectionTitle>
                  <CalculationSection
                    fieldsAndValues={activeFieldsAndValues}
                    title={""}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SectionTitle>Proposals</SectionTitle>
                  <CalculationSection
                    fieldsAndValues={proposalsFieldsAndValues}
                    title={""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <SectionTitle>Time Management</SectionTitle>
              <Grid
                container
                direction="row"
                spacing={1}
                alignItems="stretch"
                justify="center"
              >
                <Grid item xs={12} md={3}>
                  <TimeManagementSection
                    fieldsAndValues={goalFieldsAndValues}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimeManagementSection
                    fieldsAndValues={bookedFieldsAndValues}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimeManagementSection
                    fieldsAndValues={pendingFieldsAndValues}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimeManagementSection
                    fieldsAndValues={trueAvailibilityFieldsAndValues}
                    state={isOverbooked ? "danger" : undefined}
                  />
                </Grid>
              </Grid>
              <div style={{ paddingTop: "5px" }}>
                Values throughout are monthly
              </div>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <ProposalsTable
                legacyFreelancerId={legacyFreelancerId}
                winRate={projectWinRate}
                proposals={proposals}
                proposalEstimatesMap={proposalEstimatesMap}
                nextMonthProposalEstimatesMap={nextMonthProposalEstimatesMap}
                isTotalHoursOn={isTotalHoursOn}
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <ActiveProjectsTable
                legacyFreelancerId={legacyFreelancerId}
                projects={projects}
                projectEstimatesMap={projectEstimatesMap}
                nextMonthprojectEstimatesMap={nextMonthprojectEstimatesMap}
                isTotalHoursOn={isTotalHoursOn}
                totalHoursWorkedOutOfExpectedMap={
                  totalHoursWorkedOutOfExpectedMap
                }
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <ClosedProjectsTable
                legacyFreelancerId={legacyFreelancerId}
                closedProjects={closedProjects}
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <Box pt={4}>
                <b
                  style={{
                    fontSize: 36,
                    fontWeight: "bold",
                    opacity: 0.6,
                    color: "#a3e3c7",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Schedule at a Glance
                </b>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <b
                  style={{
                    fontSize: 18,
                    fontWeight: "normal",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Gantt chart view of schedule
                </b>
              </Box>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <GanttChart
                legacyFreelancerId={legacyFreelancerId}
                activeProjects={projects}
                proposals={proposals}
                projectEstimatesMap={projectEstimatesMap}
                proposalEstimatesMap={proposalEstimatesMap}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FreelancerAvailability;